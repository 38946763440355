import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {AccountInformationService} from '../account-information.service';
import {AccountInformation, DocumentType, KycDocuments, KycType} from '../account-information.modal';

@Component({
    selector: 'app-ai-documents',
    templateUrl: './documents.component.html',
})
export class DocumentsComponent {
    /** account type form control */
    accountType$ = this.accInfoService.accountType$;

    /** expose KycType enum to html */
    KycType = KycType;

    /** expose DocumentType enum to html  */
    DocumentType = DocumentType;

    /** expose KycType enum to html */
    documents: KycDocuments;

    /** constructor */
    constructor(readonly route: ActivatedRoute, private readonly accInfoService: AccountInformationService) {
        const accInfo = route.snapshot.data.accountInformation as AccountInformation;
        this.documents = accInfo.documents;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Life Cycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /***
     * attach the documents update listener
     */
    ngOnInit() {
        this.attachUpdateDocumentListener();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * this listener updates the documents list whenever a document is update by the user
     */
    private attachUpdateDocumentListener() {
        this.accInfoService.documents$.subscribe((documents) => {
            this.documents = documents;
        });
    }
}
