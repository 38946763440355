<form [formGroup]="form" class="form-v2">
    <ion-grid class="info-grid-v2">
        <ion-row>
            <!-- First Name -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.first_name" required>
                    <ion-item class="form-v2__item form-v2__item--input">
                        <ion-input placeholder="John" formControlName="firstName"></ion-input>
                    </ion-item>
                </app-form-field>
            </ion-col>

            <!-- Last Name -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.last_name" required="">
                    <ion-item class="form-v2__item form-v2__item--input">
                        <ion-input placeholder="Smith" formControlName="lastName"></ion-input>
                    </ion-item>
                </app-form-field>
            </ion-col>
        </ion-row>
    </ion-grid>

    <!-- Address -->
    <app-form-field label="label.address" required>
        <location-search-v2 [addressGroup]="form.controls.address" required></location-search-v2>
    </app-form-field>

    <ion-grid class="info-grid-v2">
        <ion-row>
            <!-- Email -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.email" required>
                    <ion-item class="form-v2__item form-v2__item--input">
                        <ion-input placeholder="john-smith@outlook.com" formControlName="email"></ion-input>
                    </ion-item>
                </app-form-field>
            </ion-col>

            <!-- Phone Number -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.phone_number" required="">
                    <ion-item class="form-v2__item form-v2__item--input">
                        <input
                            class="ion-input-replica"
                            placeholder="800-555-0199"
                            formControlName="phoneNumber"
                            mask="000-000-0000" />
                    </ion-item>
                </app-form-field>
            </ion-col>
        </ion-row>
    </ion-grid>

    <!-- Linked URL -->
    <app-form-field label="label.linkedIn_URL">
        <ion-item class="form-v2__item form-v2__item--input">
            <ion-input
                placeholder="https://www.linkedin.com/in/john-smith-404"
                formControlName="linkedinUrl"></ion-input>
        </ion-item>
    </app-form-field>
</form>
