import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {KycProgressGuard} from './services/kyc-guard.service';
import {AuthPageOptions} from './pages/auth';
import {AuthGuardService as AuthGuard} from './services/auth-guard.service';

const loadAuthModule = () => import('./pages/auth').then((m) => m.AuthPageModule);

const routes: Routes = [
    {path: '', redirectTo: AuthPageOptions.LOGIN, pathMatch: 'full'},
    {
        path: 'folder/:id',
        loadChildren: () => import('./folder/folder.module').then((m) => m.FolderPageModule),
    },
    {
        path: AuthPageOptions.LOGIN,
        data: {page: AuthPageOptions.LOGIN},
        loadChildren: loadAuthModule,
    },

    {
        path: AuthPageOptions.SIGN_UP,
        data: {page: AuthPageOptions.SIGN_UP},
        loadChildren: loadAuthModule,
    },

    {
        path: AuthPageOptions.VERIFY_OTP,
        data: {page: AuthPageOptions.VERIFY_OTP},
        loadChildren: loadAuthModule,
    },

    {
        path: 'tenant-signup/:refId',
        loadChildren: () => import('./pages/signup/tenant-signup/tenant-signup.module').then((m) => m.TenantSignupPageModule),
    },
    {
        path: 'company-signup/:adminHash',
        loadChildren: () => import('./pages/signup/company-signup/company-signup.module').then((m) => m.CompanySignupPageModule),
    },
    {
        path: 'super-login/:superToken/:userHash',
        data: {isSuperLogin: true},
        loadChildren: loadAuthModule,
    },
    {
        path: 'bank-accounts',
        loadChildren: () => import('./pages/bank-accounts/bank-accounts.module').then((m) => m.BankAcountsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'billing',
        loadChildren: () => import('./pages/billing/billing.module').then((m) => m.BillingPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'onboard/company',
        loadChildren: () => import('./pages/onboard/onboard-company/onboard-company.module').then((m) => m.OnboardCompanyPageModule),
    },
    {
        path: 'buildings',
        loadChildren: () => import('./pages/buildings/buildings.module').then((m) => m.BuildingsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'units',
        loadChildren: () => import('./pages/units/units.module').then((m) => m.UnitsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'transactions',
        loadChildren: () => import('./pages/transactions/transactions.module').then((m) => m.TransactionsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'contacts/residents',
        loadChildren: () => import('./pages/contacts/contacts.module').then((m) => m.ContactsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'contacts/managers',
        loadChildren: () => import('./pages/contacts/contacts.module').then((m) => m.ContactsPageModule),
        canActivate: [AuthGuard],
        data: {roles: ['admin']},
    },
    {
        path: 'onboard/resident/:referenceId',
        loadChildren: () => import('./pages/onboard/onboard-resident/onboard-resident.module').then((m) => m.OnboardResidentPageModule),
    },
    {
        path: 'onboard/join-building/:referenceId',
        loadChildren: () => import('./pages/onboard/onboard-resident/onboard-resident.module').then((m) => m.OnboardResidentPageModule),
    },
    {
        path: 'onboard/join-company/:referenceId',
        loadChildren: () => import('./pages/onboard/onboard-resident/onboard-resident.module').then((m) => m.OnboardResidentPageModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },

    {
        path: 'integrations',
        loadChildren: () => import('./pages/integrations').then((m) => m.IntegrationsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'user-profile',
        loadChildren: () => import('./pages/user-profile/user-profile.module').then((m) => m.UserProfilePageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'onboard/bank-account/:referenceId',
        loadChildren: () => import('./pages/onboard/onboard-bank-account/onboard-bank-account.module').then((m) => m.OnboardBankAccountPageModule),
    },
    {
        path: 'pay/:referenceId',
        loadChildren: () => import('./pages/pay/pay.module').then((m) => m.PayPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'kyc-intro',
        loadChildren: () => import('./pages/kyc/kyc-intro/kyc-intro.module').then((m) => m.KYCIntroPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {
            noLogin: true,
        },
    },
    {
        path: 'kyc-portal',
        loadChildren: () => import('./pages/kyc/kyc-portal/kyc-portal.module').then((m) => m.KYCPortalPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'kyc-basic-info/:refId',
        loadChildren: () => import('./pages/kyc/basic-information/basic-information.module').then((m) => m.BasicInformationPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {
            noLogin: true,
        },
    },
    {
        path: 'kyc-contact-aaddress/:refId',
        loadChildren: () => import('./pages/kyc/basic-information/basic-information.module').then((m) => m.BasicInformationPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {
            noLogin: true,
        },
    },
    {
        path: 'kyc-contact-address/:refId',
        loadChildren: () => import('./pages/kyc/basic-information/basic-information.module').then((m) => m.BasicInformationPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {
            noLogin: true,
        },
    },
    {
        path: 'kyc-company-info/:refId',
        loadChildren: () => import('./pages/kyc/company-information/company-information.module').then((m) => m.CompanyInformationPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {
            noLogin: true,
        },
    },
    {
        path: 'kyc-company-address/:refId',
        loadChildren: () => import('./pages/kyc/company-information/company-information.module').then((m) => m.CompanyInformationPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {
            noLogin: true,
        },
    },
    {
        path: 'kyc-identity-doc/:refId/:documentType',
        loadChildren: () => import('./pages/kyc/identity-document/identity-document.module').then((m) => m.IdentityDocumentPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {
            noLogin: true,
        },
    },
    {
        path: 'kyc-complete/:refId',
        loadChildren: () => import('./pages/kyc/kyc-complete/kyc-complete.module').then((m) => m.KYCCompletePageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {
            noLogin: true,
        },
    },
    {
        path: 'pay-link/:referenceId',
        loadChildren: () => import('./pages/pay-link/pay-link.module').then((m) => m.PayLinkPageModule),
    },
    {
        path: 'notice/:refId',
        loadChildren: () => import('./pages/notices/view-notice/view-notice.module').then((m) => m.ViewNoticePageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {
            noLogin: true,
        },
    },
    {
        path: 'notice-list',
        loadChildren: () => import('./pages/notices/notice-list/notice-list.module').then((m) => m.NoticeListPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'notice-details/:refId',
        loadChildren: () => import('./pages/notices/notice-details/notice-details.module').then((m) => m.NoticeDetailsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'work-order-list',
        loadChildren: () => import('./pages/work-orders/work-order-list/work-order-list.module').then((m) => m.WorkOrderListPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'save-work-order/:workOrderId',
        loadChildren: () => import('./pages/work-orders/save-work-order/save-work-order.module').then((m) => m.SaveWorkOrderPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'view-work-order/:workOrderId',
        loadChildren: () => import('./pages/work-orders/view-work-order/view-work-order.module').then((m) => m.ViewWorkOrderPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'yardi-residents',
        loadChildren: () => import('./pages/integrations/yardi/yardi-residents/yardi-residents.module').then((m) => m.YardiResidentsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'yardi-buildings',
        loadChildren: () => import('./pages/integrations/yardi/yardi-buildings/yardi-buildings.module').then((m) => m.YardiBuildingsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'reset-password/:hash',
        loadChildren: () => import('./pages/reset-password/resetPassword.module').then((m) => m.ResetPasswordPageModule),
    },
    {
        path: AuthPageOptions.FORGOT_PASSWORD,
        data: {page: AuthPageOptions.FORGOT_PASSWORD},
        loadChildren: loadAuthModule,
    },
    {
        path: 'yardi-transactions',
        loadChildren: () => import('./pages/integrations/yardi/yardi-transactions/yardi-transactions.module').then((m) => m.YardiTransactionsPageModule),
        canActivate: [AuthGuard, KycProgressGuard],
        data: {},
    },
    {
        path: 'payments',
        loadChildren: () => import('./pages/payments/payments.module').then((m) => m.PaymentsPageModule),
    },
    // path temporary disabled until KYC flow is discussed
    // {path: 'account-information', loadChildren: () => import('./pages/account-information').then((m) => m.AccountInformationModule), canActivate: [AuthGuard]},
    {path: 'profile', loadChildren: () => import('./pages/profile').then((m) => m.ProfileModule), canActivate: [AuthGuard]},
    // path temporary disabled until KYC flow is discussed
    // {path: 'getting-started', loadChildren: () => import('./pages/getting-started').then((m) => m.GettingStartedModule), canActivate: [AuthGuard]},
    {path: 'not-found', loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule)},
    {path: '**', redirectTo: '/not-found'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
