<!-- 
    back button
 -->
<button mat-flat-button class="paginator__button" [disabled]="page <= 1" (click)="handlePageClick(page - 1)">
    <svg class="rotate-90">
        <use xlink:href="assets/icon/sprite.svg#icon-caret"></use>
    </svg>
    <span>{{ 'label.back' | translate }}</span>
</button>

<!-- 
    pages
 -->
<div class="flex items-center gap-1">
    <ng-container *ngFor="let pageNo of pageList" [ngSwitch]="pageNo">
        <!-- render ellipsis (...) -->
        <span *ngSwitchCase="'ellipsis'">...</span>

        <!-- render the page button -->
        <div
            *ngSwitchDefault
            (click)="handlePageClick(pageNo)"
            class="px-2 py-0.5 rounded cursor-pointer text-[#333]"
            [ngClass]="{'bg-[#2151EC] text-white': pageNo === page}">
            {{ pageNo }}
        </div>
    </ng-container>
</div>

<!-- 
    next button
 -->
<button class="paginator__button" [disabled]="page >= pageCount" (click)="handlePageClick(page + 1)">
    <span>{{ 'label.next' | translate }}</span>
    <svg class="-rotate-90">
        <use xlink:href="assets/icon/sprite.svg#icon-caret"></use>
    </svg>
</button>
