import {forkJoin, timer} from 'rxjs';
import {Injectable} from '@angular/core';

import {EventService} from './event.service';
import {ProfileService} from '../pages/profile';
import {GettingStartedService} from '../pages/getting-started';
import {GlobalService} from './global.service';
import {UserIdleTimerService} from './userIdle.service';

import {ContactsPageModule} from '../pages/contacts/contacts.module';

/**
 * this is a temporary service which is not injected anywhere but on appModule.
 * the purpose of this service is to fetch the core data from the backend
 * which is shared among the portal.
 *
 * @todo logic to be moved inside a main/page resolver when the routing module is refactored
 */
@Injectable({providedIn: 'root'})
export class AppInitService {
    /** constructor */
    constructor(
        private readonly eventService: EventService,
        private readonly profileService: ProfileService,
        private readonly gettingStartedService: GettingStartedService,
        private global: GlobalService,
        private userIdle: UserIdleTimerService
    ) {
        this.fetchCoreDetailsOnAppInit();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * get a set of core data when the application initializes
     */
    private fetchCoreDetailsOnAppInit() {
        this.eventService.getEvent('AppInitatedSet').subscribe(() => {
            if (this.global.loggedInUser.companyId || this.global.loggedInUser.superUserId) {
                forkJoin([
                    this.profileService.getProfileDetails(),
                    // this.gettingStartedService.getKycProgress(),
                    // this.gettingStartedService.getKycStatus(),
                ]).subscribe();

                this.global.startDate = new Date();
                this.global.trackTabs();
                //start watching user inactivity
                this.startIdleWatching();
            }
        });
    }
    private startIdleWatching() {
        if (!this.global.isWatching) {
            this.userIdle.startWatching();
        }
    }
}
