import {Component} from '@angular/core';

import {KycType} from './account-information.modal';
import {GettingStartedService} from '../getting-started';
import {KycStatus} from '../getting-started/getting-started.model';
import {AccountInformationService} from './account-information.service';

@Component({
    selector: 'app-account-information',
    templateUrl: './account-information.page.html',
    styles: [
        `
            .btn-header {
                --padding-start: 1rem;
                --padding-end: 1rem;
            }
        `,
    ],
})
export class AccountInformationPage {
    /** account types */
    kycType = KycType;

    /** current selected account type */
    accountType$ = this.accInfoService.accountType$;

    /** kyc status details state */
    kycStatus$ = this.gettingStartedService.kycStatus$;

    /** expose kycStatus enum to html */
    KycStatus = KycStatus;

    /** constructor */
    constructor(
        private readonly accInfoService: AccountInformationService,
        private readonly gettingStartedService: GettingStartedService
    ) {}
}
