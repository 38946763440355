import {Component, OnDestroy} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
// import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
    selector: 'ag-grid-btn',
    template: `
        <ion-button size="small" style="margin-top:-8px;margin-left:8px" (click)="btnClickedHandler()">
            <ion-icon style="font-size:large" name="menu-sharp"></ion-icon>
        </ion-button>
    `,
})
export class AgGridBtn implements ICellRendererAngularComp, OnDestroy {
    refresh(params: ICellRendererParams): boolean {
        throw new Error('Method not implemented.');
    }
    private params: any;

    agInit(params: any): void {
        this.params = params;
    }

    btnClickedHandler() {
        this.params.clicked(this.params.value);
    }

    ngOnDestroy() {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}

/////////////////////////////////////////////////////////////////
//////////////////////// Grid Version 2 ////////////////////////

/** Grid Version 2 template */
const GridV2 = '<svg class="h-6 w-6"><use xlink:href="/assets/icon/sprite.svg#icon-pencil"></use></svg>';

/**
 * A version 2 of the grid button,
 * this will eventually override the V1 after the redesigning
 */
@Component({
    selector: 'ag-grid-btn-v2',
    template: GridV2,
})
export class AgGridBtnV2 extends AgGridBtn {}

/////////////////////////////////////////////////////////////////
//////////////////////// Grid Version 3 ////////////////////////

/**
 * A version 3 of the grid button,
 * This is similar to v2 with additional loading bar facility.
 * As this button is only used to displaying purpose. Here instead of using callBased approach,
 * Function based approach is used which can directly be assigned to cellRenderer property.
 */
export const AgGridBtnV3 = (params: ICellRendererParams) =>
    params.value
        ? // edit pencil icon
          GridV2
        : // loading gif as provided by Ag Grid, Also custom loader gif can be used here.
          '<img src="https://www.ag-grid.com/example-assets/loading.gif">';

/////////////////////////////////////////////////////////////////
//////////////////////// Grid Version 4 ////////////////////////
const GridV4 =
    '<svg class="h-6 w-6"><use xlink:href="/assets/icon/heroicons-outline.svg#information-circle"></use></svg>';
    
    

/**
 * A version 4 of the grid button,
 * this will eventually override the V1 after the redesigning
 * it is for buttons to display and not to edit
 */
@Component({
    selector: 'ag-grid-btn-v4',
    template: GridV4,
})
export class AgGridBtnV4 extends AgGridBtn {}


const viewBtn =
    '<svg class="h-6 w-6"><use xlink:href="/assets/icon/heroicons-outline.svg#eye"></use></svg>';
/**
 * @summary used for displaying view button for ag grid
 */
@Component({
    selector: 'ag-grid-view-btn',
    template: viewBtn,
})
export class AgGridViewBtn extends AgGridBtn {}

const DownloadBtn='<svg class="h-6 w-6"><use xlink:href="/assets/icon/heroicons-outline.svg#arrow-down-tray"></use></svg>'

/**
 * @summary used for displaying download button for ag grid
 */
@Component({
    selector: 'ag-grid-download-btn',
    template: DownloadBtn,
})
export class AgGridDownloadBtn extends AgGridBtn {}

const PrintBtn='<svg class="h-6 w-6"><use xlink:href="/assets/icon/heroicons-outline.svg#printer"></use></svg>'

/**
 * @summary used for displaying print button for ag grid
 */
@Component({
    selector: 'ag-grid-print-btn',
    template: PrintBtn,
   
})
export class AgGridPrintBtn extends AgGridBtn {}

@Component({
    selector: 'app-ag-grid-action-buttons',
    template: `
      <div class="action-buttons">
        <button (click)="downloadAction()">
            <svg class="h-6 w-6">
                <use xlink:href="/assets/icon/heroicons-outline.svg#arrow-down-tray"></use>
            </svg>
        </button>
        <button (click)="printAction()">
            <svg class="h-6 w-6">
                <use xlink:href="/assets/icon/heroicons-outline.svg#printer"></use>
            </svg>
        </button>
      </div>
    `,
    styles: [`
      .action-buttons {
        display: flex;
        justify-content: flex-start;
      }
      .action-buttons button {
        margin-right: 10px; /* Adjust as needed */
      }
    `]
  })
  export class AgGridActionButtonsComponent extends AgGridBtn {
    cellValue:any;
    agInit(props: ICellRendererParams): void {
        this.cellValue = props;
      }
    downloadAction() {
        this.cellValue.download(this.cellValue.data)
    }
  
    printAction() {
        this.cellValue.print(this.cellValue.data)
    }
  }


