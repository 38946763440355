<div *ngIf="!changeEmail">
    <div class="form__heading-container" style="text-align: center">
        <ion-text class="form__heading mb-3"> Verify Email </ion-text>
        <ion-text style="text-transform: none">
            We’ve sent a verification code to
            <a
                href="mailto:{{ userData?.email }}"
                style="color: blue; text-decoration: underline; text-transform: lowercase"
                >{{ userData?.email }}</a
            >. Please check your inbox and spam folder to ensure you receive it. Enter the code below to complete the
            verification process.
        </ion-text>
    </div>
    <div class="form__form-field mb-3">
        <mat-label class="form__input-label asterisk-if-mandatory">{{ 'label.enter_code' | translate }}</mat-label>
        <mat-form-field>
            <input matInput [(ngModel)]="verificationCode" />
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </div>

    <ion-button class="btn__primary form__btn" tappable id="loginBtn" expand="block" (click)="sendOtp()">{{
        'label.verify' | translate
    }}</ion-button>
    <div class="change-email-container">
        <span>Need to change your email? </span>
        <a class="change-email" (click)="changeEmailSection()">Change Here</a>
    </div>
</div>

<div *ngIf="changeEmail">
    <div class="form__heading-container" style="text-align: center">
        <ion-text class="form__heading mb-3">Change Email</ion-text>
        <ion-text style="text-transform: none"> Enter new email address to receive verification code. </ion-text>
    </div>
    <form [formGroup]="changeEmailForm">
        <!-- Username/Email Field -->
        <div class="form__form-field mb-3">
            <mat-label class="form__input-label asterisk-if-mandatory">Username/Email</mat-label>
            <mat-form-field>
                <input
                    matInput
                    formControlName="email"
                    name="email"
                    placeholder="Enter your username or current email"
                    pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9]+\.[A-Za-z]{2,}$" />
                <mat-error appFieldErrors></mat-error>
            </mat-form-field>
        </div>

        <!-- Password Field -->
        <div class="form__form-field mb-3">
            <mat-label class="form__input-label asterisk-if-mandatory">Password</mat-label>
            <mat-form-field>
                <input
                    matInput
                    formControlName="password"
                    [type]="hide ? 'password' : 'text'"
                    placeholder="Enter your password" />
                <ion-icon
                    matSuffix
                    (click)="hide = !hide"
                    name="{{ hide ? 'eye' : 'eye-off' }}"
                    style="font-size: 16px; cursor: pointer"></ion-icon>
                <mat-error appFieldErrors></mat-error>
            </mat-form-field>
        </div>

        <!-- New Email Field -->
        <div class="form__form-field mb-3">
            <mat-label class="form__input-label asterisk-if-mandatory">New Email</mat-label>
            <mat-form-field>
                <input
                    matInput
                    formControlName="newEmail"
                    placeholder="Enter your new email address"
                    name="newEmail"
                    pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9]+\.[A-Za-z]{2,}$" />
                <mat-error appFieldErrors></mat-error>
            </mat-form-field>
        </div>

        <!-- Update Email Button -->
        <ion-button
            class="btn__primary form__btn"
            tappable
            id="loginBtn"
            expand="block"
            (click)="changeEmailRequest()"
            [disabled]="changeEmailForm.invalid">
            Update Email
        </ion-button>
    </form>
</div>

<!-- <div class="flex items-center gap-1.5" style="float: right">
    <span class="form__btn-reset !cursor-default !text-[#99A1B7]">{{
        'label.already_have_an_account' | translate
    }}</span>
    <span class="form__btn-reset" (click)="global.goBackOrPush('/login')">{{ 'label.login' | translate }}</span>
</div> -->
