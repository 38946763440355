<section class="not-found">
    <article class="not-found-wrap">
        <div class="not-found-card">
            <h1 class="not-found-title">Oops!</h1>
            <h2 class="not-found-subtitle">We can't find that page.</h2>
            <img class="not-found-image" src="/assets/img/not-found.png" alt="404" />
            <a class="not-found-button" routerLink="/">Return Home</a>
        </div>
    </article>
</section>
