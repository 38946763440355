import {NgModule} from '@angular/core';

import {ShimmerDirective} from './shimmer.directive';
import {FieldErrorsDirective} from './field-errors.directive';
import {InputEnterClickDirective} from './input-enter-click.directive';
import {NgModelDebounceChangeDirective} from './ng-model-debounce-change.directive';
import {NoWhitespaceValidatorDirective} from './trim.directive';
import {NoLeadingTrailingSpacesDirective} from './no-leading-trailing-space.directive';

const directives = [InputEnterClickDirective, FieldErrorsDirective, NgModelDebounceChangeDirective, ShimmerDirective, NoWhitespaceValidatorDirective, NoLeadingTrailingSpacesDirective];

@NgModule({
    declarations: directives,
    exports: directives,
})
export class DirectivesModule {}
