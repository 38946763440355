import {tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {API_URL} from 'src/app/pathConfig';
import {KycProgress, KycStatusResp} from './getting-started.model';

@Injectable({providedIn: 'root'})
export class GettingStartedService {
    /** kyc progress details state */
    kycProgress$ = new BehaviorSubject<KycProgress>(undefined);

    /** kyc status details state */
    kycStatus$ = new BehaviorSubject<KycStatusResp>(undefined);

    showFixedLoader$ = new BehaviorSubject(false);

    /** base url of APIs */
    private readonly baseUrl = `${API_URL}/dashboard/manager/progress`;

    /** base url of KYC APIs */
    private readonly baseKycUrl = `${API_URL}/kyc`;

    /** constructor */
    constructor(private readonly http: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * get kyc progress details
     */
    getKycProgress() {
        return this.http.get<KycProgress>(this.baseUrl).pipe(
            tap((kycProgress) => {
                this.kycProgress$.next(kycProgress);
            })
        );
    }

    /**
     * get the current KYC status of the logged in user
     */
    getKycStatus() {
        return this.http
            .get<KycStatusResp>(`${this.baseKycUrl}/status`)
            .pipe(tap((kycStatus) => this.kycStatus$.next(kycStatus)));
    }

    /**
     * submit KYC for review
     */
    submitForReview() {
        return this.http.post(`${this.baseKycUrl}/submit`, {}).pipe(tap(() => this.getKycStatus().subscribe()));
    }
}
