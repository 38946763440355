<!-- 
    this container sure the UI is only shown if the user intends to show it
 -->
<ng-container *ngIf="useUi">
    <!-- upload image button -->
    <ng-container
        [ngTemplateOutlet]="iconContainer"
        [ngTemplateOutletContext]="{icon: 'pencil', panelClass: '-translate-y-2/4', callbackFn: uploadImage}">
    </ng-container>

    <!-- main image -->
    <ion-card class="w-32 h-32 m-0 border-[3px] border-white shadow-xl">
        <img class="w-full h-full object-cover rounded" [src]="displayImage" (error)="removeImage()" alt="image" />
    </ion-card>

    <!-- remove image button -->
    <ng-container
        *ngIf="displayImage !== DEFAULT_IMAGE"
        [ngTemplateOutlet]="iconContainer"
        [ngTemplateOutletContext]="{icon: 'x-mark', panelClass: 'bottom-0 translate-y-2/4', callbackFn: removeImage}">
    </ng-container>

    <!-- 
    action button template containing the icon
      - icon: {string} the the icon to be displayed
      - panelClass: {string} any set of extra classes which needs to appended
      - fn: {(...args: any[]) => any} a callback function which will be triggered on the button click
            make sure the callback function is an function if it is internally using this and referring to the class
            otherwise `this` needs to be bind with the normal function
    -->
    <ng-template #iconContainer let-icon="icon" let-panelClass="panelClass" let-fn="callbackFn">
        <div
            class="p-1.5 w-fit rounded-full shadow-lg bg-white absolute z-10 right-0 translate-x-2/4 cursor-pointer hover:text-[--ion-color-primary]"
            [ngClass]="panelClass"
            (click)="fn && fn()">
            <svg class="w-3.5 h-3.5">
                <use [attr.xlink:href]="'/assets/icon/heroicons-outline.svg#' + icon"></use>
            </svg>
        </div>
    </ng-template>
</ng-container>

<!-- this component handles the file uploading part and returns the uploaded file details -->
<file-uploader
    tappable
    #fileUploader
    [(triggerExternalClick)]="triggerExternalClickCount"
    [options]="{externalButton: true}"
    [inputData]="{}"
    (afterUpload)="afterUpload($event)">
</file-uploader>
