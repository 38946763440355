import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule, IonIcon} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from 'src/app/pipes/pipe.module';
import {ComponentsModule} from 'src/app/components/components.module';
import {KYCPortalPage} from './kyc-portal.page';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {KycStakeholdersPage} from './kyc-stakeholders/kyc-stakeholders.page';
import {AgGridModule} from 'ag-grid-angular';
import {AgGridBtn} from 'src/app/components/ag-grid-btn';
import {AddStakeholderModal} from './kyc-stakeholders/add-stakeholder/add-stakeholder.modal';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateModule} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {IdentificationQrCodePage} from './Identification-QR-code/Identification-QR-code.page';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {NgxMaskModule} from 'ngx-mask';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NeedMoreDetailsComponent} from './need-more-details/need-more-details.modal';
import {CompanyTypeSelectStepComponent} from './company-type-select-step/company-type-select-step.component';
import {BusinessInfoStepComponent} from './business-info-step/business-info-step.component';
import {CompanyMetadataStepComponent} from './company-metadata-step/company-metadata-step.component';
import {ApplicationDetailsComponent} from './application-details/application-details.component';
const routes: Routes = [
    {
        path: '',
        component: KYCPortalPage,
        children: [
            {
                path: '',
                redirectTo: 'account-type',
                pathMatch: 'full',
            },
            {
                path: 'account-type',
                component: CompanyTypeSelectStepComponent,
            },
            {
                path: 'business-info',
                component: BusinessInfoStepComponent,
            },

            {
                path: 'company-meta-data',
                component: CompanyMetadataStepComponent,
            },
            {
                path: 'application-details',
                component: ApplicationDetailsComponent,
            },

            {
                path: 'stakeholder-info',
                component: KycStakeholdersPage,
            },

            {
                path: 'identity-verfication',
                component: IdentificationQrCodePage,
            },
        ],
    },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        RouterModule.forChild(routes),
        AgGridModule.withComponents([AgGridBtn]),
        MatInputModule,
        MatSelectModule,
        NativeDateModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatButtonModule,
        MatRadioModule,
        NgxMaskModule,
        MatStepperModule,
        MatIconModule,
        MatTooltipModule,
    ],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'YYYY-MM-DD',
                },
                display: {
                    dateInput: 'YYYY-MM-DD',
                    monthYearLabel: 'MMMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
    declarations: [
        KYCPortalPage,
        IdentificationQrCodePage,
        KycStakeholdersPage,
        AddStakeholderModal,
        NeedMoreDetailsComponent,
        CompanyTypeSelectStepComponent,
        BusinessInfoStepComponent,
        CompanyMetadataStepComponent,
        ApplicationDetailsComponent,
    ],
})
export class KYCPortalPageModule {}
