import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from 'src/app/components/components.module';
import {PipesModule} from 'src/app/pipes/pipe.module';
import {DirectivesModule} from 'src/app/utils/directives';
import {CalculationDetailModal} from './calculation-detail.modal';

@NgModule({
    declarations: [CalculationDetailModal],
    imports: [CommonModule, TranslateModule, IonicModule, ComponentsModule, PipesModule, DirectivesModule],
})
export class CalculationDetailModalModule {}
