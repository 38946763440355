import {Component, Directive, ElementRef, Renderer2} from '@angular/core';

/**
 * This is a wrapper container for rendering a table component with filters fields.
 * Internally it uses the ion-card for layout purposes.
 *
 * Usage:
 *
 * ```html
 * <app-table-with-filters>
 *   <div twf-filters>
 *     <!-- filters -->
 *   </div>
 *
 *   <h2 twf-heading>Demo Heading</h2>
 *
 *   <table>
 *     <!-- table data -->
 *   </table>
 * </app-table-with-filters>
 * ```
 */
@Component({
    selector: 'app-table-with-filters',
    templateUrl: './table-with-filters.component.html',
})
export class TableWithFiltersComponent {}

/**
 * Directive for enhancing tables user interface of AgGrid table.
 * This is generally to be used within `<table-with-filters>` component
 *
 * Usage:
 * ```html
 * <ag-grid-angular twf-table>
 *   <!-- table data -->
 * </ag-grid-angular>
 * ```
 */
@Directive({
    selector: '[twf-table]',
})
export class TableWithFilterTableDirective {
    constructor(readonly elRef: ElementRef, readonly renderer: Renderer2) {
        // adds a CSS class to the selected table
        renderer.addClass(elRef.nativeElement, 'table-v2');
    }
}
