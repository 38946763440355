import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatusDescModal} from './status-desc.modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from 'src/app/pipes/pipe.module';
import {ComponentsModule} from 'src/app/components/components.module';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
    declarations: [StatusDescModal],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
    ],
})
export class StatusDescModalModule {}
