import {Component} from '@angular/core';

import {ProfileService} from 'src/app/pages/profile';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styles: [
        `
            :host {
                @media (min-width: 992px) {
                    display: none !important;
                }
            }
        `,
    ],
})
export class TopbarComponent {
    readonly DEFAULT_PROFILE = '/assets/icon/person.svg';
    userProfileImg = this.DEFAULT_PROFILE;

    /** constructor */
    constructor(protected readonly global: GlobalService, private readonly profileService: ProfileService) {
        this._getUserData();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * get the user's updated data every time there is a change in user profile
     */
    private _getUserData() {
        this.profileService.profileDetails$.subscribe((profileDetails) => {
            if (!profileDetails) {
                return;
            }

            this.userProfileImg = profileDetails.profilePictureUrl || this.DEFAULT_PROFILE;
        });
    }
}
