<form [formGroup]="form" class="form-v2">
    <!-- account type -->
    <app-form-field label="label.account_type" required>
        <ion-radio-group class="form-v2__field-container" [formControl]="accountTypeCtrl">
            <ion-item class="form-v2__item form-v2__item--radio-checkbox" *ngFor="let accountType of accountTypes">
                <ion-radio [value]="accountType"> </ion-radio>
                <ion-label>{{ 'label.' + accountType | translate }}</ion-label>
            </ion-item>
        </ion-radio-group>
    </app-form-field>

    <!-- this part will only render when the account type value is selected -->
    <ng-container *ngIf="accountTypeCtrl.value">
        <!-- company manages -->
        <app-form-field *ngIf="accountTypeCtrl.value === KycType.COMPANY" label="label.my_company_manages" required>
            <ion-radio-group class="form-v2__field-container" formControlName="companyManagementType">
                <ion-item
                    class="form-v2__item form-v2__item--radio-checkbox"
                    *ngFor="let companyManage of companyManages; let i = index">
                    <ion-radio [value]="companyManage[1]"> </ion-radio>
                    <ion-label>{{ 'label.' + companyManage[0].toLowerCase() | translate }}</ion-label>
                </ion-item>
            </ion-radio-group>
        </app-form-field>

        <!-- type of real estate with other option -->
        <app-form-field label="label.type_of_real_estate" required>
            <div class="form-v2__field-container">
                <!-- checkboxes -->
                <ion-item
                    class="form-v2__item form-v2__item--radio-checkbox"
                    formArrayName="realEstateTypes"
                    *ngFor="let realEstate of form.controls.realEstateTypes.controls; let i = index">
                    <ion-checkbox
                        (ionChange)="updateCheckboxValue(i, $event)"
                        [checked]="!!realEstate.value"></ion-checkbox>
                    <ion-label>{{ 'label.' + realEstateTypes[i][0].toLowerCase() | translate }}</ion-label>
                </ion-item>

                <!-- 
                    other option field
                    the condition basically fetches the last control from the form array
                    and renders the other input if it exists
                 -->
                <ion-item *ngIf="isOtherSelected" class="form-v2__item form-v2__item--input">
                    <ion-input
                        type="text"
                        [placeholder]="'label.other_option' | translate"
                        formControlName="otherType"></ion-input>
                </ion-item>
            </div>
        </app-form-field>

        <ion-grid class="info-grid-v2">
            <ion-row>
                <!-- number of buildings -->
                <ion-col size="12" size-sm="4" size-md="3">
                    <app-form-field label="label.number_of_buildings" required>
                        <ion-item class="form-v2__item form-v2__item--input">
                            <ion-input
                                type="number"
                                [placeholder]="'label.number_of_buildings' | translate"
                                formControlName="numberOfBuildings"></ion-input>
                        </ion-item>
                    </app-form-field>
                </ion-col>

                <!-- number of units -->
                <ion-col size="12" size-sm="4" size-md="3">
                    <app-form-field label="label.number_of_units" required>
                        <ion-item class="form-v2__item form-v2__item--input">
                            <ion-select
                                class="w-full"
                                [placeholder]="'label.units' | translate"
                                formControlName="numberOfUnits">
                                <ion-select-option *ngFor="let unit of numberOfUnits" [value]="unit">
                                    {{ unit }}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>
                    </app-form-field>
                </ion-col>

                <!-- number of units -->
                <ion-col *ngIf="getAlertCondition()" class="mt-auto" size="12" size-sm="4" size-md="6" size-xl="5">
                    <div
                        class="px-2.5 py-3 text-[0.8125rem] bg-[#1e60f6] rounded-[0.386rem] border border-[#e5e7eb] leading-none text-white">
                        <p class="mb-2">
                            {{ 'label.cost_per_transaction' | translate : {entity: costPerTransaction} }}
                        </p>
                        <p>{{ 'label.minimum_charge_note' | translate }}</p>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
</form>
