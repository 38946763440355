<ion-card class="mx-4 mb-4 sm:mx-8 sm:mb-8 mt-0 rounded card-shadow-v2">
    <!-- header container -->
    <ng-content select="[twf-filters]"></ng-content>

    <!-- main table -->
    <ion-card-content >
        <div class="py-2.5  text-[#172655] !text-2xl !font-semibold capitalize">
            <!-- table heading will be rendered here -->
            <ng-content select="[twf-heading]"></ng-content>
        </div>

        <!-- table data will be rendered here -->
        <ng-content></ng-content>
    </ion-card-content>
</ion-card>
