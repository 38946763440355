import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {GlobalService} from 'src/app/services/global.service';
import {TourService} from 'src/app/services/app-tour.service';
import Step from 'shepherd.js/src/types/step';
import {UserIdleTimerService} from '../../services/userIdle.service';
import {NavController} from '@ionic/angular';
@Injectable({providedIn: 'root'})
export class AuthService {
    userData;
    /** constructor */
    constructor(
        private readonly http: HttpClient,
        private readonly global: GlobalService,
        private appGuide: TourService,
        private userIdle: UserIdleTimerService,
        private navCtrl: NavController
    ) {}

    /**
     * login and verify the user details
     *
     * @param payload user login details
     */
    login(payload: object) {
        // show loading indicator and send an AJAX request to verify login details.
        this.global.showLoading();
        this.global.sendAjaxRequest('login/verifyLoginDetails', payload).subscribe((loginResponse) => {
            if (loginResponse.message === 'successful') {
                this.global.setLocalStorage('tenantpayToken', loginResponse.token);
                this.userIdle.startWatching();
                this.global.initLoggedInUser(true);
            } else if (loginResponse.message === 'superadmin_not_authorized') {
                this.global.basicAlert('', 'label.superadmin_not_authorized', true);
                this.global.hideLoading();
            } else if (loginResponse.message === 'company_not_found') {
                this.global.basicAlert('', 'Company is Inactive.', true);
                this.global.hideLoading();
            } else if (loginResponse.message === 'user_not_verified') {
                this.userData = payload;
                this.global.hideLoading();
                this.global.pushPage('verify-otp');
            } else {
                this.global.basicAlert('', 'label.enter_valid_credentials', true);
                this.global.hideLoading();
            }
        });
    }

    /**
     * sends the forgot password email
     *
     * @param userName username of the user
     */
    forgotPassword(userName: string) {
        // show loading indicator and send an AJAX request to initiate forgot password request
        this.global.showLoading();
        return this.global
            .sendAjaxRequest('login/forgotPasswordEmail', {userName})
            .pipe(tap(() => this.global.hideLoading()));
    }

    /**
     * create a new user
     *
     * @param payload new user details
     */

    signUp(payload: object) {
        const requestURL = this.global.API_URL + '/user/createManager';
        return this.http.post(requestURL, payload).subscribe((response: any) => {
            if (response.message === 'created') {
                this.userData = response.data;
                this.global.pushPage('verify-otp');
            }
        });
    }

    verifyOtp(email, otp) {
        const requestURL = this.global.API_URL + '/user/verifySignUp';
        const dataToSend = {
            request: {
                email: email,
                verificationCode: otp,
            },
        };
        return this.http.post(requestURL, dataToSend).subscribe((response: any) => {
            if (response.message === 'verification_successful') {
                this.global.setLocalStorage('tenantpayToken', response.token);
                this.global.pushPage('kyc-portal');
            } else if (
                response.message === 'invalid_verification_code' ||
                response.message === 'verification_code_expired'
            ) {
                this.global.basicAlert('', 'Please check your email for a new code.');
            }
        });
    }
}
