import {forkJoin} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {DocumentType, DocumentName} from '../../account-information.modal';
import {AccountInformationService} from '../../account-information.service';

@Component({
    selector: 'app-ai-upload-document',
    templateUrl: './upload-document.modal.html',
    styleUrls: ['./upload-document.modal.scss'],
})
export class UploadDocumentModal implements OnInit {
    /** dialog title, prop received from parent component */
    title = '';
    /** name of the document */
    documentName: DocumentName;
    /** issuing country of the document */
    issuedCountry: string;
    /** show the upload document section when form does not exist or is completed */
    showUpload = true;
    /** document name, country form group */
    docNameForm = this.getDocNameFormGroup();
    /** document form doc names title */
    documentFormTitle: string;
    /** documents name to be used with form */
    docNames: DocumentName[] = [];
    /** flag to keep track of form and updates */
    isPageChanged = false;
    /** flag to show/hide country form field */
    showCountryField = false;
    /** expose DocumentName enum to html */
    DocumentName = DocumentName;
    /** form groups that stores all the uploader form groups */
    formGroups = new FormGroup({});
    /** document uploader icon to use */
    icon = 'globe-asia-australia';

    /** type of document, prop received from parent component */
    set documentType(val: DocumentType) {
        this._documentType = val;
        this.documentName = this.getDocumentNameAndDecisions();
    }

    /** type of document, prop received from parent component */
    get documentType() {
        return this._documentType;
    }

    /** type of document, used with getter/setter function */
    private _documentType: DocumentType;

    /** constructor */
    constructor(public readonly modalCtrl: ModalController, private readonly formBuilder: FormBuilder, private readonly accInfoService: AccountInformationService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Life Cycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * attach the document form group listeners
     */
    ngOnInit() {
        this.attachDocNameListener();
        this.attachCountryListener();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * converts the kebab-case to snake_case for translation purpose
     *
     * @param value kebab-case value which is to be converted
     * @returns same value in snake_case notation
     */
    getSnakeCase(value: string) {
        return value.split('-').join('_');
    }

    /**
     * gets the title key to be used for the translation
     *
     * @returns title name of the document
     */
    getTitle() {
        if (this.documentType.toString() === this.documentName) {
            return this.title;
        } else {
            return `${this.title}_${this.getSnakeCase(this.documentName)}`;
        }
    }

    /**
     * handles the back button click operation.
     * if any section exists then get backs to that section,
     * otherwise closes the modal
     */
    onBackClick() {
        if (this.isPageChanged) {
            this.isPageChanged = false;
            this.showUpload = false;
            this.formGroups.removeControl('first');
            this.formGroups.removeControl('second');
        } else {
            this.modalCtrl.dismiss();
        }
    }

    /**
     * once the form group value is updated,
     * move to document upload section
     */
    moveToUpload() {
        this.isPageChanged = true;
        this.showUpload = true;
    }

    /**
     * add form group emitted by the uploader to global form groups
     *
     * @param form form group emitted by uploader
     * @param groupName name of the group, right now we only support maximum of 2 uploads
     */
    addFormGroups(form: FormGroup, groupName: 'first' | 'second') {
        this.formGroups.addControl(groupName, form);
    }

    /**
     * upload the document details at the BE
     */
    uploadDocument() {
        if (this.formGroups.invalid) {
            return;
        }

        const forms: FormGroup[] = Object.values(this.formGroups.controls);
        const docUploadApis = forms.map((group) => this.accInfoService.updateDocument(group.value));
        forkJoin(docUploadApis).subscribe(() => this.modalCtrl.dismiss());
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * get the name of the document based on the type of document
     */
    private getDocumentNameAndDecisions(): DocumentName {
        switch (this.documentType) {
            case DocumentType.ARTICLES_OF_INCORPORATION: {
                this.icon = 'clipboard-document';
                return DocumentName.ARTICLES_OF_INCORPORATION;
            }

            case DocumentType.BANK_STATEMENT: {
                this.icon = 'clipboard-document';
                return DocumentName.BANK_STATEMENT;
            }

            case DocumentType.GOVERNMENT_ID: {
                this.showUpload = false;
                this.showCountryField = true;
                this.documentFormTitle = 'label.choose_id_to_add';
                this.docNames = [DocumentName.DRIVERS_LICENSE, DocumentName.IDENTITY_CARD, DocumentName.PASSPORT];
                this.docNameForm.controls.country.addValidators(Validators.required);
                break;
            }

            case DocumentType.BILL: {
                this.showUpload = false;
                this.icon = 'document-text';
                this.documentFormTitle = 'label.choose_bill_type';
                this.docNames = [DocumentName.TAX_BILL, DocumentName.UTILITY_BILL];
                break;
            }
        }
    }

    /**
     * attach document name change listener.
     * whenever document name changes,
     * it updates the global value of document name
     */
    private attachDocNameListener() {
        this.docNameForm?.controls.documentName.valueChanges.subscribe((documentName) => {
            this.documentName = documentName;
            switch (documentName) {
                case DocumentName.PASSPORT: {
                    this.icon = 'globe-asia-australia';
                    break;
                }

                case DocumentName.IDENTITY_CARD:
                case DocumentName.DRIVERS_LICENSE: {
                    this.icon = 'identification';
                    break;
                }
            }
        });
    }

    /**
     * attach country change listener.
     * whenever country changes,
     * it updates the global value of country
     */
    private attachCountryListener() {
        this.docNameForm?.controls.country?.valueChanges.subscribe((country) => {
            this.issuedCountry = country;
        });
    }

    /**
     * initializes the document name, country form
     *
     * @returns  document name, country form group
     */
    private getDocNameFormGroup() {
        return this.formBuilder.group({
            documentName: this.formBuilder.control(undefined, Validators.required),
            country: [''],
        });
    }
}
