import {FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {Address, AddressFormGroup} from './location.modal';
import {GlobalService} from 'src/app/services/global.service';

/**
 * version 2 of the location search component.
 * it integrates with the form group update process.
 * this will replace v1 in the future.
 */
@Component({
    selector: 'location-search-v2',
    templateUrl: './location-search-v2.component.html',
})
export class LocationSearchV2Component implements OnInit, AfterViewInit {
    /**
     * placeholder which is displayed in the input field.
     *
     * default value is `'Search (Google Address)'`
     */

    @Input()
    isReadOnly:boolean = false;
    @Input()
    placeHolder = 'Search (Google Address)';

    @Input()
    label = 'address';

    @Input()
    fixedCountry: string;

    @Input()
    retrieveContainingStreetAddresses = false;

    @Input()
    containAllFields = false;

    @Input()
    completeAddress = '';

    /** address form group which will be internally updated once the usFer selects a value */
    @Input()
    set addressGroup(val: FormGroup<AddressFormGroup>) {
        this._addressGroup = val;
        this.searchCtrl.setValue(val.controls.fullAddress.value, {emitEvent: false});

        // this will mark the search control as touched when the parent value and validity is updated
        this._addressGroup?.valueChanges.subscribe((address) => {
            this.searchCtrl.setValue(address.fullAddress, {emitEvent: false});
            this.searchCtrl.markAsTouched();
        });
    }

    /** address form group which will be internally updated once the user selects a value */
    get addressGroup() {
        return this._addressGroup;
    }

    /**
     * this helps determine wether to add a required validation to the search control
     */
    @Input()
    set required(val: '' | boolean) {
        this._required = val === '' || val;
        if (this._required) {
            this.searchCtrl.addValidators(Validators.required);
        } else {
            this.searchCtrl.removeValidators(Validators.required);
        }
    }

    /**
     * this helps determine wether to add a required validation to the search control
     */
    get required() {
        return this._required;
    }

    /** emits the address data once the user selects a location */
    @Output()
    onPlaceChanged = new EventEmitter<Address & {fullAddress: string}>();

    /** a flag which is used to track the state of the searching */
    isSearchStarted = false;
    /** search control used for search and displaying the selected item */
    searchCtrl = new FormControl('');
    /** flag to check wether the entered query returned an address */
    noResultsFound = false;

    /** location search map container reference */
    @ViewChild('locationSearchMap')
    private mapElement: ElementRef<HTMLDivElement>;
    /** address group inputted from the parent component */
    private _addressGroup?: FormGroup<AddressFormGroup>;
    /** google maps autocomplete service used for fetching predictions */
    private autoCompleteService: google.maps.places.AutocompleteService;
    /** google maps place service used for fetching selected place details */
    private placeService: google.maps.places.PlacesService;
    /** required to be used with setter/getter function */
    private _required = false;
    /** the searched item predictions returned by the autocomplete service */
    protected searchedItems: google.maps.places.AutocompletePrediction[] = [];
    /** flag which renders the additional field if any one is missing from places APi */
    protected showAdditionalFields = false;

    /** constructor */
    constructor(private readonly global: GlobalService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Life Cycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * attach the form listener
     */
    ngOnInit() {
        this.attachFormListener();
        this.searchCtrl.setValue(this.completeAddress, {emitEvent: false});
    }

    /**
     * initialize the google maps autocomplete and place service
     */
    ngAfterViewInit() {
        this.autoCompleteService = new google.maps.places.AutocompleteService();
        const map = new google.maps.Map(this.mapElement.nativeElement);
        this.placeService = new google.maps.places.PlacesService(map);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * once a user selects a location from the combo box fetch the selected location details and then
     * updates the form group, emits the address data and reset the searched inputs
     */
    selectLocation(item: google.maps.places.AutocompletePrediction) {
        this.isSearchStarted = false;
        this.placeService.getDetails(
            {placeId: item.place_id},
            ({address_components, formatted_address: fullAddress}) => {
                const address: Address = this.global.processAdress(address_components) as Address;
                const addressToStore = {...address, fullAddress};
                this.addressGroup?.reset();
                this.addressGroup?.patchValue({...addressToStore, countryCode: address.shortCountryCode});
                this.searchedItems = [];
                this.searchCtrl.patchValue(fullAddress, {emitEvent: false});
                this.onPlaceChanged.emit(addressToStore);
                this.checkIfAnyFieldIsMissing();
            }
        );
    }

    /**
     * handle resetting of the form control and searched data
     * once the user closes the select
     */
    onSelectClose() {
        if (!this.isSearchStarted) {
            return;
        }

        this.searchedItems = [];
        this.isSearchStarted = false;
        this.searchCtrl.reset();
        this.addressGroup?.reset();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * attach the value change listener on the search control
     * which fetches new predictions from the maps api
     */
    private attachFormListener() {
        this.searchCtrl.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((searchVal) => {
            this.isSearchStarted = true;

            // if the search value is empty reset the searched items
            if (!searchVal) {
                this.searchedItems = [];
                this.noResultsFound = false;
                this.isSearchStarted = false;
                return;
            }

            // fetch new predictions from the maps api
            this.autoCompleteService.getPlacePredictions(
                {
                    input: searchVal,
                    ...(this.retrieveContainingStreetAddresses && {types: ['address']}),
                    ...(this.fixedCountry && {componentRestrictions: {country: this.fixedCountry}}),
                },
                (predictions) => {
                    if (!predictions) {
                        this.noResultsFound = true;
                        return;
                    }
                    // if (this.containAllFields) {
                    //     this.searchedItems = predictions.filter((prediction) => {
                    //         const termsLength = prediction.terms.length;
                    //         return termsLength >= 8;
                    //     });
                    // } else {
                    this.searchedItems = predictions;
                    // }
                }
            );
        });
    }

    /**
     * checks if any field is missing in the form group
     * then renders the additional fields
     */
    private checkIfAnyFieldIsMissing() {
        if (this.addressGroup) {
            const addressFields = this.addressGroup.value;
            this.showAdditionalFields = Object.values(addressFields).some(
                (val) => val === undefined || val === null || !val
            );
        }
    }
}
