import {NgModule} from '@angular/core';
import {NgxMaskModule} from 'ngx-mask';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {Route, RouterModule} from '@angular/router';

import {InfoFormComponent} from './info-form';
import {UserDetailsComponent} from './user-details';
import {CompanyDetailsComponent} from './company-details';
import {DocumentItemComponent} from './documents/document-item';
import {AccountInformationPage} from './account-information.page';
import {ComponentsModule} from 'src/app/components/components.module';
import {AccountInformationResolver} from './account-information.resolver';
import {DocumentsComponent, UploadDocumentModal, DocumentUploaderComponent} from './documents';

const routes: Route[] = [
    {
        path: '',
        component: AccountInformationPage,
        resolve: {accountInformation: AccountInformationResolver},
    },
];

@NgModule({
    declarations: [
        AccountInformationPage,
        InfoFormComponent,
        CompanyDetailsComponent,
        UserDetailsComponent,
        DocumentsComponent,
        UploadDocumentModal,
        DocumentItemComponent,
        DocumentUploaderComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        ComponentsModule,
        IonicModule,
        TranslateModule,
        CommonModule,
        ReactiveFormsModule,
        NgxMaskModule,
    ],
})
export class AccountInformationModule {}
