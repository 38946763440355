<ion-item *ngIf="kycStatus$ | async as kycStatus" class="form-v2__item p-0">
    <!-- document title and subTitle -->
    <ion-label>
        <h2 class="text-[--ion-color-dark] !font-semibold">{{ snakeCaseTitle | translate }}</h2>
        <p *ngIf="useSubTitle">{{ snakeCaseTitle + '_def' | translate }}</p>
    </ion-label>

    <!-- action buttons -->
    <div class="flex gap-3 text-[#0052f5]">
        <!-- update/upload button -->
        <span
            *ngIf="kycStatus.status === KycStatus.INCOMPLETE"
            class="hover:underline hover:cursor-pointer"
            (click)="openUploadDocumentDialog()"
            >{{ (document ? 'label.update' : 'label.upload') | translate }}</span
        >

        <!-- view document buttons -->
        <ng-container *ngIf="document">
            <span
                *ngFor="let docUrl of getDocumentsUrlsAsArray()"
                id="open-modal"
                class="hover:underline hover:cursor-pointer"
                (click)="viewDocument(docUrl)"
                >{{ 'label.view' | translate }}</span
            >
        </ng-container>
    </div>
</ion-item>
