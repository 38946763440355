import {Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core';
import {IonRouterOutlet, MenuController, PopoverController} from '@ionic/angular';
import {GlobalService} from './services/global.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HelpModal} from './pages/help/help.modal';
import {FeedbackService} from './services/feedback.service';
import {GettingStartedService} from './pages/getting-started';
import {NavigationEnd, Router} from '@angular/router';
import {MixpanelService} from './services/MixPanel.service';
import {environment} from 'src/environments/environment';
// eslint-disable-next-line @typescript-eslint/ban-types
declare const gtag: Function;
const trackingId = environment.googleAnalyticsTrackingId;
import {UserIdleTimerService} from './services/userIdle.service';
import {API_URL, APP_URL} from './pathConfig';
import {UsersnapService} from './services/usersnap.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    @ViewChild('globalFileUpload', {static: true}) globalFileUpload: ElementRef;
    @ViewChild(IonRouterOutlet, {static: true}) routerOutlet: IonRouterOutlet;
    showLoader$ = this.gettingStartedService.showFixedLoader$;
    private spaceApiKey = 'e36a92bf-fa84-45db-9b2e-846aef84ab8d';
    constructor(
        public global: GlobalService,
        private renderer: Renderer2,
        public popoverCtrl: PopoverController,
        public jwtHelper: JwtHelperService,
        private menuCtrl: MenuController,
        private readonly feedbackService: FeedbackService,
        private gettingStartedService: GettingStartedService,
        private router: Router,
        private mixpanelService: MixpanelService,
        private userIdle: UserIdleTimerService,
        public userSnapService: UsersnapService
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                gtag('config', trackingId, {page_path: event.urlAfterRedirects});
            }
        });
        this.menuCtrl.enable(false);
        if (environment.production && API_URL === 'https://staging-api.tenantpay.com/api/v2') {
            const meta = document.createElement('meta');
            meta.name = 'robots';
            meta.content = 'noindex';
            document.getElementsByTagName('head')[0].appendChild(meta);
        }
    }

    menuCloseOnMatMenu() {
        this.menuCtrl.close('sideMenu');
    }

    ngOnInit() {
        /*PREVIOUS FEEDBACK BASED ON BABA TOOLS REPLACED WITH USERSNAP USING SCRIPT */

        // if (environment.production && API_URL === 'https://staging-api.tenantpay.com/api/v2') {
        //     this.feedbackService.setupFeedbackTool();
        // }
        this.global.routerOutlet = this.routerOutlet;
        this.global.globalFileUploadInput = this.globalFileUpload;
        this.global.renderer = this.renderer;
        this.mixpanelService.init();
        if (environment.production && API_URL === 'https://staging-api.tenantpay.com/api/v2') {
            this.initializeUsersnap();
        }
    }
    private initializeUsersnap() {
        // Initialize using Space API
        this.userSnapService
            .initializeWithSpace(this.spaceApiKey)
            .then((api) => {
                console.log('Usersnap Space API initialized', api);
            })
            .catch((error) => {
                console.error('Error initializing Usersnap Space API', error);
            });

        // Initialize inline embed form (optional)
    }

    // not sure about the purpose of this function
    // keeping it here, just for backward compatibility
    async openHelpModal() {
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: HelpModal,
            componentProps: {},
            cssClass: 'full-height-modal',
        });

        modal.onDidDismiss().then((detail) => {});

        await modal.present();
    }
    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        const tabs = +localStorage.getItem('tabs') - 1;
        localStorage.setItem('tabs', tabs.toString());
    }
}
