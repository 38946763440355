import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {PipesModule} from 'src/app/pipes/pipe.module';
import {InvoiceDetailsModal} from './invoice-details.modal';
import {ComponentsModule} from 'src/app/components/components.module';

@NgModule({
    declarations: [InvoiceDetailsModal],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ComponentsModule,
        PipesModule,
        ReactiveFormsModule,
        MatButtonModule,
    ],
})
export class InvoiceDetailsModalModule {}
