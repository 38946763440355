import {Observable, fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AfterViewInit, Directive, ElementRef} from '@angular/core';

/**
 * This Angular directive, named 'appEnterClick', is designed to trigger a click event on a specified submit button
 * when the 'Enter' key is pressed within an input field. It enhances the user experience by allowing users to
 * submit a form by simply hitting the 'Enter' key instead of clicking the submit button.
 *
 * Usage:
 * To use this directive, simply apply it to an input field within an Angular template, like this:
 *
 * ```html
 * <form>
 *   <input type="text" appEnterClick>
 *   <button type="submit">Submit</button>
 * </form>
 * ```
 *
 * Ensure that the input field is placed within a form element that contains a submit button.
 *
 * In this example, when the 'Enter' key is pressed within the input field, it will trigger a click event on the submit button,
 * effectively submitting the form.
 */
@Directive({
    selector: '[appEnterClick]',
})
export class InputEnterClickDirective implements AfterViewInit {
    constructor(private readonly elRef: ElementRef<HTMLElement>) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Life Cycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * It is used to set up the directive's behavior after the
     * view has been fully initialized. it perform the following tasks:
     *
     * - Identifies the nearest ancestor form element containing the input.
     * - If a form element is found, it looks for a submit button within the same form.
     * - Then attaches a keyDown event on the button
     */
    ngAfterViewInit() {
        const elementClickObs = fromEvent<KeyboardEvent>(this.elRef.nativeElement, 'keydown');

        const formEl = this.elRef.nativeElement.closest('form');
        // If no form element is found, return.
        if (!formEl) {
            return;
        }

        const submitBtn: HTMLElement = formEl.querySelector('[type=submit]');
        // If no submit button is found, return.
        if (!submitBtn) {
            return;
        }

        this.attachKeyDownListener(elementClickObs, submitBtn);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Attaches a keydown event listener to the input element with debounce functionality
     * to prevent accidental double submissions when the 'Enter' key is pressed.
     *
     * On successful enter press, this method triggers the click event on the passed
     * html element (button in most cases).
     *
     * @param elClickObs - The observable for 'keydown' events on the input element.
     * @param btn - The submit button element to trigger when 'Enter' is pressed.
     */
    private attachKeyDownListener(elClickObs: Observable<KeyboardEvent>, btn: HTMLElement) {
        elClickObs.pipe(debounceTime(400)).subscribe((event) => {
            // check if the pressed key is 'Enter'.
            if (event.key === 'Enter') {
                btn.click();
            }
        });
    }
}
