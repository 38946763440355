import {Component, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-terms-and-condition-modal',
    templateUrl: './terms-and-condition.modal.html',
    styleUrls: ['../../forms-shared.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TermsAndConditionModalComponent {
    termsAndConditions = [
        {
            header: 'Effective Date: 31-07-2024',
            text: `
        <p><strong>Welcome to TenantPay!</strong> <br>We are pleased to have you as part of our community. To ensure a clear understanding of the use of our services, we have put together these comprehensive Terms and Conditions ("<strong>Terms of Use</strong>" or "<strong>Terms</strong>") to outline the expectations, rights, and obligations associated with your use of TenantPay's services (referred to herein as "<strong>Services</strong>"). </p><p>These Terms, along with our Privacy Policy, are designed to provide you with a transparent overview of how we collect, use, and protect the information and data you provide to us. They also detail your rights and our responsibilities concerning that information. </p><p>Please carefully read these Terms and our Privacy Policy before using our services to understand your rights and our commitments to you.</p>
      `,
        },
        {
            header: 'Acceptance of Terms',
            text: `
        <p><strong>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY.</strong> BY ACCESSING OR USING TENANT PAYMENT SYSTEMS INC. SERVICES (INCLUDING OUR WEBSITE LOCATED AT <a href="https://www.tenantpay.com">WWW.TENANTPAY.COM</a> AND ANY RELATED SUBDOMAINS) YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS ("TERMS"). IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS YOU MUST NOT USE OUR SERVICES.</p>
      `,
        },
        {
            header: 'Scope of these Terms',
            text: `
        <p>These Terms govern your interactions with our digital platform, covering all aspects such as rent management, property management tools, payment processing, and additional features offered through our platform.</p><p> These Terms and Conditions are applicable to everyone who uses our website, including landlords, property managers, service providers, and those who merely view the content available on or through the website.</p><p> Our website provides financial and property management services, which may have their own specific terms and conditions, including agreements with third-party service providers. </p><p>We encourage you to review our Privacy Policy to understand our practices regarding the collection, use, and protection of your personal information. TenantPay reserves the right to amend these Terms at any time, and continued use of the Services after such changes signifies your acceptance of the updated Terms.</p>
      `,
        },
        {
            header: 'About TenantPay',
            text: `
        <p>Tenant Payment Systems Inc. ("<strong>TenantPay</strong>") is a Money Services Business ("<strong>MSB</strong>") for tenants who want to pay rent online or for landlords (or their representatives) to collect rent payment online. TenantPay’s primary goal is to provide a secure and convenient platform for rent collection, payment tracking, and reporting services. TenantPay operates only in Canada.</p>
      `,
        },
        {
            header: 'Account Registration and Eligibility',
            text: `
        <p>To access and use our services, you must register by creating a Landlord or Property Manager account on TenantPay and complete an account profile with accurate and up-to-date information.</p>
        <ul>
          <li>To qualify for our services, you must fulfill one of these requirements:</li>
          <ol>
            <li><strong>For Individuals:</strong> You must be of legal age in your jurisdiction to form binding agreements or act on behalf of someone under applicable law and you must have a valid mailing address.</li>
            <li><strong>For Entities:</strong> You must be a duly recognized corporation, partnership, or other legal entity in good standing with the authority to enter into this agreement.</li>
          </ol>
          <li>You may access and utilize our services either personally or on behalf of a registered entity. Note that the use of automated scripts or machines to interact with our services is prohibited.</li>
        </ul>
      `,
        },
        {
            header: 'Identity Verification',
            text: `
        <p>To comply with regulatory requirements and ensure the integrity of our services, you authorize TenantPay or its third-party service providers to conduct identity verification procedures. This may involve:</p>
        <ul>
          <li><strong>Information Collection:</strong> Providing additional details, confirming email ownership, and verifying financial information.</li>
          <li><strong>Verification Process:</strong> Cross-checking your information against external databases and other sources.</li>
        </ul>
        </br>
        <p>By providing this information, you confirm that you have the authority to do so and that the information is accurate.</p>
      `,
        },
        {
            header: 'Account Security',
            text: `
        <p>You are responsible for maintaining the confidentiality of your account credentials and for all the activities that occur under your account. If you suspect any unauthorized use of your account, you must notify TenantPay immediately.</p>
      `,
        },
        {
            header: 'Third Party Services',
            text: `
        <p>TenantPay may feature links to third-party sites and services, including those used for identity verification during the KYC process and property management software integrations. These third-party services are provided solely for your convenience and do not fall under TenantPay's control. TenantPay is not responsible for the quality, accuracy, or content of these third-party services or websites.</p>
        <p>TenantPay <strong>DISCLAIMS ANY LIABILITY</strong> ARISING FROM YOUR ACCESS TO OR USE OF THIRD-PARTY SERVICES AND WEBSITES. BY USING OUR SERVICES YOU ACKNOWLEDGE THAT TENANTPAY IS NOT RESPONSIBLE FOR THIRD-PARTY SERVICES OR CONTENT AND AGREE TO RELEASE TENANTPAY FROM ANY LIABILITY RELATED TO YOUR USE OF SUCH THIRD-PARTY SERVICES.</p>
      `,
        },
        {
            header: 'User Responsibilities',
            text: `
        <p>When using TenantPay, you agree to:</p>
        <ul>
          <li><strong>Use the Services for Approved Purposes:</strong> Utilize TenantPay solely for authorized purposes. You agree to use the platform for sending rental payment information to approved credit reporting agencies and any other services or purposes explicitly approved by TenantPay.</li>
          <li><strong>Adhere to Agreements:</strong> Comply fully with this Agreement and any other agreements you have entered into with TenantPay.</li>
          <li><strong>Follow All Applicable Laws:</strong> Abide by all applicable local, provincial, state, and federal laws and regulations, including those concerning anti-money laundering (AML) and counter-terrorism financing (CTF).</li>
          <li><strong>Avoid Multiple Accounts:</strong> Do not create or register multiple TenantPay accounts without obtaining prior written consent from TenantPay.</li>
          <li><strong>Respect Intellectual Property:</strong> Refrain from infringing upon TenantPay’s or any third party's intellectual property rights, including copyrights, trademarks, trade secrets, or privacy rights.</li>
          <li><strong>Verify Identity:</strong> Provide confirmation of your identity or any other requested information and cooperate with any investigations conducted by TenantPay or its authorized representatives.</li>
          <li><strong>Prohibit Unauthorized Payments:</strong> Do not use TenantPay to obtain cash advances from credit cards or to facilitate unauthorized payments.</li>
          <li><strong>Communicate Responsively:</strong> Respond promptly and reasonably to communications from TenantPay.</li>
          <li><strong>Prevent Malicious Activities:</strong> Do not introduce or facilitate the spread of viruses, worms, trojan horses, or other harmful programming that may damage or disrupt TenantPay's systems or services.</li>
          <li><strong>Respect Automation Restrictions:</strong> Avoid using robots, spiders, or any automated tools to access, monitor, or copy TenantPay’s website without prior written consent.</li>
          <li><strong>Maintain Service Integrity:</strong> Do not use devices, software, or routines to bypass TenantPay's security measures or interfere with the operation of TenantPay’s website or services.</li>
          <li><strong>Protect TenantPay’s Partnerships:</strong> Refrain from actions that could jeopardize TenantPay's relationships with its internet service providers, payment processors, or other partners.</li>
        </ul>
      `,
        },
        {
            header: 'Prohibited Activities',
            text: `
        <p>You must not engage in any illegal or unauthorized activities while using TenantPay, including but not limited to:</p>
        <ul>
          <li>Fraud or any deceptive practices.</li>
          <li>Money laundering or financing of terrorism.</li>
          <li>Any action that could compromise the security or integrity of the TenantPay platform.</li>
        </ul>
      `,
        },
        {
            header: 'Termination',
            text: `
            <ul>
            <li>
            <strong>Duration of Agreement: </strong>These Terms of Use remain effective as long as you use TenantPay services. Certain provisions outlined in these Terms will continue to apply even after termination, unless otherwise specified.
            </li>
            <li><strong>Termination by TenantPay: </strong>TenantPay reserves the right to terminate your access to its services at any time, entirely or in part, at its sole discretion. This decision may be based on various reasons, such as non-compliance with these Terms, identification of undue risk, or misappropriation of services. If such action is taken, you will receive an electronic notification at the email address registered with us. The decision to terminate can be made following regular business assessments or during annual compliance reviews by the TenantPay compliance team. Should you be removed from the platform, you may inquire about the reasons by contacting <a href="mailto:support@tenantpay.com">support@tenantpay.com</a>.
</li>
<li>
<strong>Termination by User: 
</strong>
You can terminate your account or stop using the services at any time by contacting <a href="mailto:support@tenantpay.com">support@tenantpay.com</a>.

</li>
<li>
<strong>Effects of Termination: 
</strong>Upon termination of your account, you will lose immediate access to TenantPay services. Certain clauses within these Terms, including those concerning intellectual property, liability, dispute resolution, indemnity, and any other provisions meant to survive termination, will continue to be enforceable even after the termination of your account.

</li>
<li>
<strong>Post Termination Obligations: </strong>Any financial or non-financial obligations that were due or set to be fulfilled before the date of termination will continue to be enforceable until they have been fully satisfied.
</li>
            </ul>
       
      `,
        },
        {
            header: 'Refunds',
            text: `<p>At TenantPay, we are committed to ensuring a seamless and transparent experience for our users. Our refund policy outlines the conditions and procedures for obtaining a refund for transactions processed through our platform. Please read this section carefully to understand your rights and obligations concerning refunds.</p>
            
            <h3>Eligibility for Refunds</h3>
            <p>Refunds are applicable under the following circumstances:
</p>

<p>
<ul><li><strong>Erroneous Transactions: </strong>If a transaction was processed incorrectly due to an error on TenantPay's part or a technical issue within our platform.</li>
<li><strong>Unauthorized Transactions: </strong>If a transaction was completed without the account holder’s authorization and is reported within a stipulated time frame.
</li>
<li><strong>Service Disruptions: </strong>If you experienced a service interruption that resulted in a failed transaction.
</li></ul></p>
<h3>Timeframes for Refund Requests</h3>
<p>The timeframes for processing refund requests are as follows:</p>
<p>
<ul>
<li><strong>Transactions Within the Last 12 Months:
</strong>
<ol><li>If you request a refund for any transaction completed within the last 12 months, the refund will be processed within 2 to 3 weeks.</li>
<li>A fee of $25.00 will be deducted per transaction refunded.</li></ol> </li>
<li><strong>Transactions Beyond 12 Months:
</strong>
<ol><li>Refund requests for transactions older than 12 months will be processed within 4 to 6 weeks.
</li>
<li>A fee of $35.00 will be deducted per transaction refunded.
</li></ol></li>
</ul></p>
<h3>Refund Process</h3>
<p>To initiate a refund, follow these steps:</p>
<p>
<ul>
  <li><strong>Contact Support:</strong> Reach out to our customer support team at <a href="mailto:support@tenantpay.com">support@tenantpay.com</a> with your refund request. Provide the transaction details, including the transaction ID, date, and amount, along with a brief explanation of the reason for the refund.</li>
  <li><strong>Verification:</strong> Our support team will review your request and may contact you for additional information or documentation to verify the transaction and eligibility for a refund.</li>
  <li><strong>Approval:</strong> Once your request is verified and approved, we will initiate the refund process within the specified timeframes. You will receive an email notification confirming the refund status.</li>
</ul>
</p>
<h3>Important Considerations</h3>
<p>
<ul>
  <li><strong>Non-Refundable Fees:</strong> Please note that any fees associated with currency conversion, bank transfers, or other third-party charges are non-refundable and will not be reimbursed by TenantPay.</li>
  <li><strong>Refund Method:</strong> Refunds will be credited back to the original payment method used for the transaction. If the original payment method is unavailable or closed, please inform our support team to discuss alternative arrangements.</li>
  <li><strong>Limitation of Liability:</strong> TenantPay is not responsible for any delays in the refund process due to factors beyond our control, such as delays by banks or third-party service providers.</li>
</ul>
</p>
`,
        },

        {
            header: 'Data Retention',
            text: `
        TenantPay retains user information for <strong>at least five years</strong> following the termination of your account. This retention is necessary for compliance with legal obligations and internal record-keeping.
`,
        },
        {
            header: 'Limitation of Liability',
            text: `
        <p>TENANTPAY IS NOT LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES RESULTING FROM THE USE OF OUR SERVICES.</p>
        <p>This includes, but is not limited to, losses such as:
</p>
<ul>
<li>Profit loss</li>
<li>Data loss</li>
<li>Interruption of service</li>
<li>Costs incurred for acquiring replacement goods or services</li>
</ul>
</br>
<p>These limitations apply regardless of the legal theory under which such liabilities are claimed, whether due to a breach of contract, tort (including negligence), or any other legal theory, and regardless of whether such damages were foreseeable or TenantPay was informed of the potential for such damages.</p>
<p>To the fullest extent allowed by applicable law, TenantPay's total liability for any and all claims related to these Terms will not exceed the higher of:
</p>
<ul><li>$100.00, or
</li>The total amount you have paid to TenantPay in service fees, if any, during the past month.
</li></ul>
      `,
        },
        {
            header: 'Indemnification',
            text: `
        <p>You agree to indemnify, defend, and hold TenantPay harmless from any claims, liabilities, damages, losses, and expenses, including legal fees, arising from your use of our services or your violation of these Terms.
</p>
      `,
        },
        {
            header: 'Intellectual Property Rights',
            text: `
        <h3>Limited Use Authorization</h3>
        <p>In accordance with these Terms and Conditions, TenantPay provides you with a personal, non-exclusive, revocable, and non-transferable authorization to access and utilize our services for the purposes outlined within this Agreement.</p>
        <h3>Ownership and Intellectual Rights</h3>
        <p>This Agreement outlines your rights to access and use TenantPay’s services, which are protected under intellectual property laws. All rights, ownership, and interests in the services are held by TenantPay or its licensors, if applicable. By accessing our services, you agree to the following:</p>
        <ul>
          <li><strong>Restrictions on Use:</strong> You are not permitted to transfer, assign, or sublicense any rights granted under this Agreement to any other party. Your use of the services must strictly adhere to the permissions outlined in this Agreement. Any attempt to transfer, assign, sublicense, or use the services beyond the authorized scope is invalid.</li>
          <li><strong>Prohibition on Alterations:</strong> You are prohibited from altering, modifying, or adapting the services or creating any derivative works based on them. Allowing third parties to make changes to the services is also prohibited.</li>
          <li><strong>No Reverse Engineering:</strong> You must not decompile, decode, reverse engineer, disassemble, or otherwise convert the services into any readable format.</li>
          <li><strong>Preservation of Notices:</strong> All proprietary notices or labels contained within the services must remain intact and unaltered.</li>
        </ul>
        </br>
        <p>Except for the limited permissions expressly granted in this Agreement, all rights, ownership, and intellectual property rights in the services remain with TenantPay and its licensors. You are prohibited from using any TenantPay trademarks or brand elements without obtaining prior written approval.</p>
      `,
        },
        {
            header: 'Warranty Disclaimer',
            text: `
        <p>TenantPay provides its services and any related third-party content or software on an "as is" and "as available" basis. Except as explicitly stated in this agreement, TenantPay makes no warranties or representations of any kind, whether express, implied, or statutory, regarding the services and any third-party content.</p>
        <p>TenantPay specifically disclaims any implied warranties, including but not limited to:</p>
        <ul>
          <li>Quality</li>
          <li>Accuracy</li>
          <li>Performance</li>
          <li>Availability</li>
          <li>Merchantability</li>
          <li>Fitness for a particular purpose</li>
          <li>Title</li>
          <li>Non-infringement</li>
        </ul>
        </br>
        <p>TenantPay does not guarantee that the services will be uninterrupted, secure, or error-free, or that any defects will be corrected. You acknowledge that you are using the services at your own risk, and if you are dissatisfied with any part of our services, your only recourse is to discontinue using them.</p>
        <p>Any material accessed or downloaded through the use of TenantPay's services is done at your own discretion and risk. TenantPay is not liable for any damage to your computer system or loss of data that results from such actions. Furthermore, no advice or information, whether oral or written, obtained from TenantPay, shall create any warranty or representation not explicitly stated in these terms and conditions.</p>
      `,
        },
        {
            header: 'Dispute Resolution',
            text: `
        <p>If a disagreement arises between you and TenantPay regarding these terms or your use of the services that cannot be resolved through informal means, such dispute shall be resolved through binding arbitration in accordance with the rules of the Canadian Arbitration Association.</p>
        <ul>
          <li><strong>Location:</strong> The arbitration will take place in Toronto, Ontario.</li>
          <li><strong>Arbitrator:</strong> The arbitration will be conducted by an arbitrator mutually agreed upon by both parties.</li>
          <li><strong>Confidentiality:</strong> All aspects of the arbitration process, including any related information, will be kept confidential by all parties involved.</li>
          <li><strong>Costs:</strong> The costs of arbitration, including any fees, shall be shared equally between both parties.</li>
        </ul>
        </br>
        <p>By agreeing to arbitration, both you and TenantPay acknowledge that you are waiving the right to a trial by jury or to participate in a class action lawsuit.</p>
      `,
        },
        {
            header: 'Additional Terms',
            text: `
        <h3>Monetary Units</h3>
        <p>Unless otherwise noted, all financial figures mentioned in this Agreement are stated in Canadian dollars.</p>
        <h3>Nature of Relationship</h3>
        <p>TenantPay acts as an independent entity, and nothing in this Agreement creates a partnership, joint venture, agency, or any other similar relationship between the parties.</p>
        <h3>Third-Party Collaborations</h3>
        <p>TenantPay may engage with third-party vendors to deliver our services. By agreeing to this, you allow the sharing of your personal and transaction information with these vendors, as necessary, in accordance with our Privacy Policy.</p>
        <h3>Service Modifications</h3>
        <p>You acknowledge that our services may change over time as new features are added or improvements are made. TenantPay reserves the right to update, modify, or discontinue any aspect of the services, including promotions and features, at any time without prior notice. Any improvements or new features will be subject to this Agreement. We do not guarantee the continuous availability of services or any associated features, and they may change at our discretion.</p>
        <h3>Complete Agreement</h3>
        <p>This Agreement constitutes the entire understanding between the parties concerning its subject matter, superseding all prior or contemporaneous agreements and discussions.</p>
        <h3>Legal Assurance</h3>
        <p>You warrant that entering into and fulfilling this Agreement does not conflict with any legal obligations or agreements you have with third parties and that you have obtained any necessary authorizations for this Agreement.</p>
        <h3>Notification Process</h3>
        <p>Notices required or permitted under this Agreement must be provided in the specified manner to be effective.</p>
        <h3>Electronic Communication Risks</h3>
        <p>You acknowledge and accept all risks associated with electronic communication with TenantPay, including the possibility of misdelivery, interception, loss, or unauthorized alteration by third parties.</p>
        <h3>External Links</h3>
        <p>Our website may include links to third-party websites that we do not own or control. TenantPay does not endorse or take responsibility for the content, products, services, or privacy practices of these external sites. We are not liable for any losses or claims arising from your use of these linked websites.</p>
      `,
        },
        {
            header: 'Amendments to Terms',
            text: `
        <p>TenantPay may modify these Terms and Conditions at any time. We will notify you of any significant changes via email or through our platform. Your continued use of the Services following any changes constitutes acceptance of the revised Terms.</p>
      `,
        },
        {
            header: 'Contact Information',
            text: `
        <p>If you have any questions or concerns regarding these Terms, please contact us at <a href="mailto:support@tenantpay.com">support@tenantpay.com</a>.</p>
      `,
        },
    ];

    constructor(private modalController: ModalController, public readonly global: GlobalService) {}

    ngOnInit() {}

    acceptTerms() {
        // Handle the acceptance of terms
        this.modalController.dismiss({accepted: true});
    }

    closeModal() {
        this.modalController.dismiss({accepted: false});
    }
}
