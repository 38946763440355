<ion-app>
    <ion-split-pane contentId="main-content">
        <ion-menu
            class="sidebar"
            type="overlay"
            menuId="sideMenu"
            contentId="main-content"
            swipeGesture="false"
            [disabled]="true">
            <app-sidebar (menuCloseOnMatMenu)="menuCloseOnMatMenu()"></app-sidebar>
        </ion-menu>

        <div id="main-content" class="flex flex-col flex-1 basis-full">
            <app-topbar *ngIf="!!global.loggedInUser"></app-topbar>
            <ion-router-outlet
                class="min-[992px]:top-0"
                [ngClass]="{'top-16': !!global.loggedInUser}"></ion-router-outlet>
        </div>
    </ion-split-pane>
    <input
        type="file"
        [multiple]="global.multipleFileUpload"
        #globalFileUpload
        id="globalFileUpload"
        name="globalFileUpload"
        (change)="global.initializeUpload($event)"
        accept="images/*"
        style="display: none" />
</ion-app>

<div *ngIf="(showLoader$ | async) && false" class="fix-loader">
    <ion-spinner name="lines"> </ion-spinner>
</div>
