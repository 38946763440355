<ion-header>
    <ion-toolbar>
        <ion-title class="ion-text-capitalize" >
            {{'label.help' | translate}}
        </ion-title>
        <ion-button slot="end" (click)="addFeedbackModal()">
            {{'label.send_feedback' | translate}}
        </ion-button>
        <ion-button slot="end" color="dark" (click)="global.closeModal(false)">
            <ion-icon name="close"></ion-icon>
        </ion-button>
       
    </ion-toolbar>
</ion-header>

<ion-content class="light-background">
    <ion-list lines="none" class="responsive-section">
        <ion-item detail *ngFor="let entry of links" (click)="openLink(entry)">
            <ion-label>
                {{entry['name'] | translate}}
            </ion-label>
            <ion-icon slot="start" name="information-circle-sharp"></ion-icon>
        </ion-item>
    </ion-list>
</ion-content>
