import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router, ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {GlobalService} from './global.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    authState = new BehaviorSubject(false);
    constructor(public global: GlobalService, public jwtHelper: JwtHelperService, public router: Router) {}

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        const noLogin = route.data.noLogin;
        const token = this.global.getLocalStorage('tenantpayToken');

        if (!this.global.appInitiated) {
            this.global.initLoggedInUser();
        }
        if (noLogin === true) {
            return true;
        } else {
            if (typeof token === 'undefined' || this.jwtHelper.isTokenExpired(token)) {
                localStorage.removeItem('tenantpayToken');
                this.global.logoutUser();
                return false;
            } else {
                // this will be passed from the route config
                // to get data passed on route use the data property route.data
                // E.g. route.data.hasOwnProperty(dataKey)
                if (route.data.hasOwnProperty('roles')) {
                    const {roles} = route.data;
                    if (roles.indexOf(this.global.loggedInUser.userRole) < 0) {
                        this.global.pushPage('dashboard');
                        return false;
                    }
                }
                return true;
            }
        }
    }
}
