import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
	selector: 'top-menu',
	templateUrl: './top-menu.component.html',
	styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnChanges {

	@Input() title: any;
	@Input() center = false;
	@Input() btn = null;
	@Input() backBtn = false;
	@Input() backLink = '';
	@Input() disabled = false;

	@Output() btnClicked: EventEmitter<any> = new EventEmitter();

	constructor(public global: GlobalService) {

	}
	ngOnChanges(changes: SimpleChanges): void {

	}

	btnClick() {
		this.btnClicked.emit(true);
	}
	backBtnClick() {
		if (this.backLink !== '') {
			this.global.pushPage(this.backLink);
		} else {
			this.global.goBackOrPush(this.backLink);
		}
	}
}
