import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {GlobalService} from 'src/app/services/global.service';
import {UserIdleTimerService} from 'src/app/services/userIdle.service';

@Injectable()
export class AuthResolver implements Resolve<void> {
    /** constructor */
    constructor(private readonly global: GlobalService, public jwtHelper: JwtHelperService, public userIdleService: UserIdleTimerService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * checks wether or not current url is of superLogin.
     * if yes, then executes the superLogin function
     *
     * @param route activated route snapshot
     */
    resolve(route: ActivatedRouteSnapshot) {
        const isSuperLogin = route.data.isSuperLogin as boolean;
        // if normal user type then check if it's credentials already exist or not
        const token = this.global.getLocalStorage('tenantpayToken');
        if (!isSuperLogin) {
            // perform auto login if token already exist
            if (typeof token !== 'undefined' && !this.jwtHelper.isTokenExpired(token) && token !== null && token !== '') {
                this.global.initLoggedInUser(true);
            }
            return;
        }

        const {superToken, userHash} = route.params as Record<string, string>;
        this.superLogin(superToken, userHash);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * handle the super user login flow.
     *
     * @param superToken super user token
     * @param hash super user hash
     */
    private superLogin(superToken: string, hash: string) {
        this.global.trackTabs();
        this.global.showLoading();

        if (+localStorage.getItem('tabs') > 1) {
            this.global.superLoginWithExistingTab = true;
            localStorage.clear();
        } else {
            localStorage.clear();
        }
        this.global.loggedInUser = false;

        // send an AJAX request to verify hash login details.
        this.global.setLocalStorage('tenantpayToken', superToken);
        this.global.sendAjaxRequest('login/verifyHashLogin', {userHash: hash}).subscribe((response) => {
            if (response.message === 'success') {
                this.global.setLocalStorage('tenantpayToken', response.token);
                this.global.initLoggedInUser(true);
            } else {
                this.global.basicAlert('', 'label.enter_valid_credentials', true);
                this.global.hideLoading();
            }
        });
    }
}
