import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-need-more-details',
  templateUrl: './need-more-details.modal.html',
})
export class NeedMoreDetailsComponent implements OnInit {

  constructor( public global: GlobalService) { }

  ngOnInit() {}

}
