import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

import {ProfileService} from '../profile.service';
import {mustMatch} from 'src/app/utils/custom-validators';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-profile-reset-password',
    templateUrl: './reset-password.component.html',
    styles: [
        `
            .validation-list {
                margin-top: 2px;
                font-size: 10px;
            }
            .validation-list div {
                display: flex;
                align-items: baseline;
            }

            .valid {
                color: green;
            }

            .invalid {
                color: grey;
            }
            .valid span,
            .invalid span {
                margin-right: 8px;
            }
        `,
    ],
})
export class ResetPasswordComponent {
    /** reset password form group */
    form = this.initForm();
    showValidationMessages = false;
    hideTimeout: any;

    /** constructor */
    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly profileService: ProfileService,
        public global: GlobalService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * resets the password of the logged in user
     */
    resetPassword() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const payload = {passwordData: this.form.value};
        this.profileService.resetPassword(payload);
        this.form.reset();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initializes the individual information form
     *
     * @returns individual information form group
     */
    private initForm() {
        return this.formBuilder.group(
            {
                oldPassword: ['', Validators.required],
                newPassword: ['', Validators.required],
                confirmPassword: ['', Validators.required],
            },
            {validators: mustMatch('newPassword', 'confirmPassword')}
        );
    }
    checkPasswordValidation() {
        const password = this.form.get('newPassword').value;
        this.global.isPasswordValid.minLength = password.length >= 8;
        this.global.isPasswordValid.lowerCase = /[a-z]/.test(password);
        this.global.isPasswordValid.upperCase = /[A-Z]/.test(password);
        // eslint-disable-next-line id-blacklist
        this.global.isPasswordValid.number = /\d/.test(password);
        this.global.isPasswordValid.symbol = this.global.allowedSymbols.test(password);

        const allValid =
            this.global.isPasswordValid.minLength &&
            this.global.isPasswordValid.lowerCase &&
            this.global.isPasswordValid.upperCase &&
            this.global.isPasswordValid.number &&
            this.global.isPasswordValid.symbol;
        if (allValid) {
            clearTimeout(this.hideTimeout);
            this.hideTimeout = setTimeout(() => {
                this.showValidationMessages = false;
            }, 1000);
        } else {
            clearTimeout(this.hideTimeout);
            this.showValidationMessages = true;
        }
    }
}
