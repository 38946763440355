import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FileUploaderComponent} from 'src/app/components/file-uploader/file-uploader.component';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {GlobalService} from 'src/app/services/global.service';
import {KYCService} from '../../kyc.service';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {canadianProvinces, genderOptions} from '../../kyc.constant';
import * as moment from 'moment';

@Component({
    selector: 'app-business-info-step',
    templateUrl: './business-info-step.component.html',
    styleUrls: ['./business-info-step.component.scss'],
})
export class BusinessInfoStepComponent implements OnInit {
    @Input() disableForm = false;
    shareholderUploadReq;
    recordOfExistenceUploadReq;
    businessInfoForm: FormGroup;
    companyTypeId: string;
    accountType = [];
    employmentSectors;
    public googleAddress = true;
    businessTypes;
    public uploadedFilesOptions: {externalButton: boolean};
    hideAddressFields = false;
    hideMailingAddressFields = true;
    public triggerExternalClickCount = 0;
    public triggerRecordOfExistencefileClickCount = 0;
    lastSavedkycDetails;
    canadianProvincesOptions = canadianProvinces;
    genders = genderOptions;
    isKycCompleted = false;
    filesToSend: any[] = [];
    triggerDocumentUploadError = false;
    today: Date = new Date();
    kycCompanySubscription: Subscription;
    data$: Observable<any>;

    nationalitiesOptions;
    recordOfExistencefile: any[] = [];
    @ViewChild('fileUploader', {static: false}) fileUploader: FileUploaderComponent;
    @ViewChild('RecordOfExistencefileUploader', {static: false}) recordOfExistencefileUploader: FileUploaderComponent;
    kycId;
    showMailingAddress = true;
    constructor(
        public fb: FormBuilder,
        public translate: TranslateService,
        public apiService: ApiResponseService,
        public global: GlobalService,
        public kycDataService: KYCService
    ) {
        this.kycCompanySubscription = this.kycDataService.curentKycCompanyId$.subscribe((data) => {
            this.companyTypeId = data;
        });
    }

    ngOnInit() {
        this.shareholderUploadReq = {
            dest: `kyc_documents/kycId_${this.kycDataService.currentKYCID}/shareholdersDocument`,
            createThumb: false,
            newFileName: `${this.global.loggedInUser.userId}_shareholdersDocument`,
            thumbDest: '',
            fileUpload: true,
            onlyImage: true,
            uploadToS3: true,
            IsKycDocument: true,
            allowedFileTypes: ['pdf', 'jpeg'],
        };
        this.recordOfExistenceUploadReq = {
            dest: `kyc_documents/kycId_${this.kycDataService.currentKYCID}/recordOfExistence`,
            createThumb: false,
            newFileName: `${this.global.loggedInUser.userId}_RecordOfExistence`,
            thumbDest: '',
            fileUpload: true,
            onlyImage: true,
            uploadToS3: true,
            IsKycDocument: true,
            allowedFileTypes: ['pdf', 'jpeg'],
        };

        this.businessInfoForm = this.fb.group({
            personalInfo: this.fb.group({
                dateOfBirth: [, [Validators.required]],
                firstName: ['', [Validators.required, this.noWhitespaceValidator]],
                lastName: ['', [Validators.required, this.noWhitespaceValidator]],
                occupation: ['', [Validators.required, this.noWhitespaceValidator]],
                kycEmploymentSectorId: ['14', Validators.required],
                otherEmploymentSector: [''],
                gender: ['', Validators.required],
                nationalityAlpha2Code: ['CA', Validators.required],
                phoneNumber: [''],
            }),
            companyInfo: this.fb.group({
                companyName: ['', [Validators.required, this.noWhitespaceValidator]],
                kycEmploymentSectorId: ['14', Validators.required],
                otherEmploymentSector: [''],
                phoneNumber: ['', Validators.required],
                documentDate: [null],
                incorporationDate: [null],
                documentIssueLocation: [''],
            }),
            address: this.fb.group({
                fullAddress: ['', [Validators.required, this.noWhitespaceValidator]],
                buildingNumber: ['', [Validators.required, this.noWhitespaceValidator]],
                streetName: ['', [Validators.required, this.noWhitespaceValidator]],
                city: ['', [Validators.required, this.noWhitespaceValidator]],
                province: ['', [Validators.required, this.noWhitespaceValidator]],
                country: [{value: '', disabled: true}, [Validators.required, this.noWhitespaceValidator]],
                postalCode: ['', [Validators.required, this.noWhitespaceValidator]],
            }),

            mailingAddress: this.fb.group({
                fullAddress: ['', [Validators.required, this.noWhitespaceValidator]],
                buildingNumber: ['', [Validators.required, this.noWhitespaceValidator]],
                streetName: ['', [Validators.required, this.noWhitespaceValidator]],
                city: ['', [Validators.required, this.noWhitespaceValidator]],
                province: ['', [Validators.required, this.noWhitespaceValidator]],
                country: [{value: '', disabled: true}, [Validators.required, this.noWhitespaceValidator]],
                postalCode: ['', [Validators.required, this.noWhitespaceValidator]],
            }),
        });

        this.disableFormBasedOnCondition();

        this.apiService.getCompanyTypes().subscribe((response) => {
            this.accountType = response;
        });

        this.apiService.getNationalities().subscribe((response) => {
            this.nationalitiesOptions = response.options;
        });
        this.apiService.getKycEmploymentSector().subscribe((response) => {
            this.employmentSectors = response;
            this.businessTypes = response;
        });

        this.kycCompanySubscription = this.kycDataService.curentKycCompanyId$.subscribe((data) => {
            this.companyTypeId = data;
        });

        this.apiService.getKycBaseDetails().subscribe((response) => {
            this.companyTypeId = this.companyTypeId === '' ? response.companyInfo.companyTypeId : this.companyTypeId;
            this.kycDataService.updateCurrentKycCompanyId(this.companyTypeId);
            this.isKycCompleted = response.kycInfo.completed;
            if (this.companyTypeId) {
                this.apiService.getkycInfo().subscribe((lastSavedresponse) => {
                    this.lastSavedkycDetails = lastSavedresponse;
                    this.businessInfoForm.get('personalInfo').patchValue(lastSavedresponse.personalInfo);
                    this.businessInfoForm.get('address').patchValue(this.lastSavedkycDetails.address);
                    this.businessInfoForm.get('mailingAddress').patchValue(this.lastSavedkycDetails.mailingAddress);
                    this.businessInfoForm.get('companyInfo').patchValue(this.lastSavedkycDetails.companyInfo);
                    if (this.lastSavedkycDetails.documents) {
                        this.filesToSend = this.lastSavedkycDetails.documents
                            .filter((x) => x.kycDocumentTypeId === '1')
                            .map((x) => ({
                                fileDest: x.filePath.split('/')[0] + '/',
                                originalNameWithExt: x.filePath.split('/')[3].split('_')[1],
                                fileUrl: x.filePath,
                                fileNameWithExt: x.filePath.split('/')[1],
                                downloadUrl: x.url,
                                kycDocumentId: x.kycDocumentId,
                            }));

                        this.recordOfExistencefile = this.lastSavedkycDetails.documents
                            .filter((x) => x.kycDocumentTypeId === '2')
                            .map((x) => ({
                                fileDest: x.filePath.split('/')[0] + '/',
                                originalNameWithExt: x.filePath.split('/')[3].split('_')[1],
                                fileUrl: x.filePath,
                                fileNameWithExt: x.filePath.split('/')[1],
                                downloadUrl: x.url,
                                kycDocumentId: x.kycDocumentId,
                            }));
                    }
                });
            }
        });
    }

    disableFormBasedOnCondition() {
        if (this.disableForm) {
            this.businessInfoForm.disable();
        }
    }

    /**
     * @summary fills the address fields with values from google places
     * @param place
     */
    locationResults(place): void {
        this.businessInfoForm.get('address').get('country').setValue(place['country']);
        this.businessInfoForm.get('address').get('buildingNumber').setValue(place['buildingNumber']);
        this.businessInfoForm.get('address').get('province').setValue(place['province']);
        this.businessInfoForm.get('address').get('city').setValue(place['city']);
        this.businessInfoForm.get('address').get('postalCode').setValue(place['postalCode']);
        this.businessInfoForm.get('address').get('streetName').setValue(place['streetName']);
        this.businessInfoForm.get('address').get('fullAddress').setValue(place['fullAddress']);
        this.hideAddressFields = true;
    }

    /**
     * Sets the location results for the mailing address in the KYC form.
     * @param place - The place object containing the location results.
     */
    locationResultsForMailingAddress(place): void {
        this.businessInfoForm.get('mailingAddress').get('country').setValue(place['country']);
        this.businessInfoForm.get('mailingAddress').get('buildingNumber').setValue(place['buildingNumber']);
        this.businessInfoForm.get('mailingAddress').get('province').setValue(place['province']);
        this.businessInfoForm.get('mailingAddress').get('city').setValue(place['city']);
        this.businessInfoForm.get('mailingAddress').get('postalCode').setValue(place['postalCode']);
        this.businessInfoForm.get('mailingAddress').get('streetName').setValue(place['streetName']);
        this.businessInfoForm.get('mailingAddress').get('fullAddress').setValue(place['fullAddress']);
        this.hideMailingAddressFields = false;
    }

    /**
     * Custom validator function that checks if a control's value contains only whitespace characters.
     * @param control - The form control to validate.
     * @returns An object with a `whitespace` property set to `true` if the value contains only whitespace, or `null` otherwise.
     */
    noWhitespaceValidator(control: AbstractControl): {[key: string]: any} | null {
        const isWhitespace = (control.value || '').trim().length === 0;
        return isWhitespace ? {whitespace: true} : null;
    }

    /**
     * Gets the address label based on the first name and last name.
     * If both first name and last name are provided, the label will be in the format: "Address for (firstName lastName)".
     * If either first name or last name is missing, the label will be "Address".
     *
     * @returns The address label.
     */
    getAddressLabel(): string {
        const firstName = this.businessInfoForm.get('personalInfo').get('firstName').value;
        const lastName = this.businessInfoForm.get('personalInfo').get('lastName').value;
        if (firstName && lastName) return 'Address for (' + firstName + ' ' + lastName + ')';
        else return 'Address';
    }
    /**
     * Determines whether the checkbox should be disabled based on certain conditions.
     * The checkbox is disabled if:
     * - KYC is already completed (isKycCompleted === 1)
     * - The current step is 4 and the company type is '1'
     * - The current step is 5 and the company type is not '1'
     *
     * @returns {boolean} True if the checkbox should be disabled, false otherwise.
     */
    disableCheckbox(): boolean {
        return false;
    }
    /**
     * Checks if the address data for a given address type is valid.
     * @param addressType - The type of address to validate ('address' or 'mailingAddress').
     * @returns True if all address fields are valid, false otherwise.
     */
    isAddressDataValid(addressType: 'address' | 'mailingAddress'): boolean {
        const addressFields = ['fullAddress', 'buildingNumber', 'streetName', 'city', 'province', 'postalCode'];
        for (const field of addressFields) {
            if (!this.businessInfoForm.get(`${addressType}.${field}`).valid) {
                return false;
            }
        }

        return true;
    }

    /**
     * Retrieves the company type based on the selected company type ID.
     * @returns The company type as a string.
     */
    getCompanyType(): string {
        const company = this.accountType.find((x) => x.companyTypeId === this.companyTypeId)?.value || '';
        return company.toLowerCase() === 'other' ? 'Entity' : company;
    }
    /**
     * Displays the shareholding document information.
     */
    shareholdingDocumentInfo() {
        const companyType = this.getCompanyType();
        const message = `Please upload a document describing shareholding structure of all the stakeholders in this ${companyType}`;
        return message;
    }

    /**
     * @summary Tracks the checkbox click event and updates the mailing address based on the address value.
     * @param event - The checkbox click event.
     */
    trackCheckboxClick(): void {
        this.showMailingAddress = !this.showMailingAddress;
        this.businessInfoForm.get('mailingAddress').reset();
    }

    /**
     * Displays an alert message based on the selected company type ID.
     */
    recordsOfexistenceInfo() {
        let alertText = '';
        if (this.companyTypeId) {
            switch (this.companyTypeId) {
                case '2':
                    alertText = `
                  Please upload one of the following documents:
                  1. A certificate of incorporation.
                  2. A record that has to be filed annually under provincial securities legislation.
                  3. The most recent version of any other record that confirms the corporation's existence and contains its name and address and the names of its directors, such as a certificate of active corporate status, the corporation's published annual report signed by an audit firm, or a letter or notice of assessment for the corporation from a municipal, provincial, territorial or federal government.
              `;
                    break;
                case '3':
                    alertText = `
                  Please upload one of the following documents:
                  1. Trust Deed.
                  2. A partnership agreement.
                  3. Articles of association.
                  4. The most recent version of any other record that confirms its existence and contains its name and address.
              `;
                    break;
                case '4':
                    alertText = `
                  Please upload one of the following documents:
                  1. A partnership agreement.
                  2. Articles of association.
                  3. The most recent version of any other record that confirms its existence and contains its name and address.
              `;
                    break;
                case '5':
                    alertText = `
                  Please upload one of the following documents:
                  1. A partnership agreement;
                  2. Articles of association.
                  3. The most recent version of any other record that confirms its existence and contains its name and address.
              `;
                    break;
                default:
                    alertText = 'Invalid company type ID';
                    break;
            }
            return alertText;
        }
    }

    /**
     * @summary handles after upload functions
     * @param $event
     * @param fileType
     */
    afterUpload($event, fileType): void {
        const file = $event[0];
        this.sendDocuments(file['fileUrl'], fileType.toString());
        if (fileType == 1) this.filesToSend.push($event[0]);
        else this.recordOfExistencefile.push($event[0]);
    }
    /**
     * @summary triggers the photo upload
     */
    triggerPhotoUpload(): void {
        this.fileUploader.onClick();
    }

    /**
     * @summary sends the documents to the server
     * @param document
     * @param type
     */
    sendDocuments(document, type): void {
        this.apiService.sendDocuments(document, type).subscribe((response) => {
            const kycDocumentId = response['kycDocumentId'];
            if (type === '1') {
                this.filesToSend[this.filesToSend.length - 1].kycDocumentId = kycDocumentId;
            } else {
                this.recordOfExistencefile[this.recordOfExistencefile.length - 1].kycDocumentId = kycDocumentId;
            }
        });
    }

    /**
     * @summary checks if the personal info is valid
     * @returns boolean
     */
    isPersonalInfoValid(): boolean {
        const personalInfo = this.businessInfoForm.get('personalInfo');
        if (this.companyTypeId == '1') {
            return (
                personalInfo.get('dateOfBirth').valid &&
                personalInfo.get('firstName').valid &&
                personalInfo.get('lastName').valid &&
                personalInfo.get('occupation').valid &&
                personalInfo.get('kycEmploymentSectorId').valid
            );
        } else return true;
    }
    isDocumentsValid() {
        return this.filesToSend.length > 0 && this.recordOfExistencefile.length > 0;
    }

    triggerRecordOfExistenceUpload(): void {
        this.recordOfExistencefileUploader.onClick();
    }

    isDocumentsUploaded(): boolean {
        if (this.isShareHoldingDocumentsUploaded() && this.isRecordOfExistencefile())
            this.triggerDocumentUploadError = false;

        return this.isShareHoldingDocumentsUploaded() && this.isRecordOfExistencefile();
    }

    isShareHoldingDocumentsUploaded(): boolean {
        return this.filesToSend.length > 0;
    }
    isRecordOfExistencefile(): boolean {
        return this.recordOfExistencefile.length > 0;
    }
    removeFile(index, filetype, documentId): void {
        if (filetype === 1) {
            this.filesToSend.splice(index, 1);
        } else {
            this.recordOfExistencefile.splice(index, 1);
        }
        this.apiService.deleteDocument(documentId).subscribe();
    }

    onSubmit() {
        if (
            (this.companyTypeId === '1' &&
                this.businessInfoForm.get('personalInfo').valid &&
                this.isAddressDataValid('address')) ||
            (this.companyTypeId !== '1' &&
                (this.showMailingAddress || this.isAddressDataValid('mailingAddress')) &&
                this.businessInfoForm.get('companyInfo').valid &&
                this.isDocumentsUploaded() &&
                this.isAddressDataValid('address')) ||
            this.businessInfoForm.disabled
        ) {
            const personalInfo = this.businessInfoForm.get('personalInfo').value;
            const companyInfo = this.businessInfoForm.get('companyInfo').value;
            const address = this.businessInfoForm.get('address').getRawValue();
            if (this.showMailingAddress) {
                this.businessInfoForm.get('mailingAddress').patchValue(address);
            }
            const mailingAddress = this.businessInfoForm.get('mailingAddress').getRawValue();

            // Step 3.2: Format date values
            personalInfo.dateOfBirth = moment(personalInfo.dateOfBirth).format('YYYY-MM-DD');
            if (companyInfo.documentDate) {
                companyInfo.documentDate = moment(companyInfo.documentDate).format('YYYY-MM-DD');
            }
            if (companyInfo.incorporationDate) {
                companyInfo.incorporationDate = moment(companyInfo.incorporationDate).format('YYYY-MM-DD');
            }
            this.kycDataService.updateStepValidity('businessInfo', true);
            // Step 3.3: Send KYC information to server
            if (!this.isKycCompleted) {
                this.apiService
                    .sendKycInformation(personalInfo, companyInfo, address, this.companyTypeId, mailingAddress)
                    .subscribe(() => {});
            }
        } else {
            this.triggerDocumentUploadError = true;
            this.businessInfoForm.get('personalInfo').markAllAsTouched();
            this.businessInfoForm.get('companyInfo').markAllAsTouched();
            this.businessInfoForm.get('address').markAllAsTouched();
            if (this.companyTypeId !== '1') {
                this.businessInfoForm.get('mailingAddress').markAllAsTouched();
            }
        }
    }
}
