import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * A wrapper component for defining consistent page heading.
 * it takes the page heading as input and any extra content if needs to rendered.
 * By default the additional content will be rendered at the end of the container.
 *
 * Usage:
 *
 * ```html
 * <app-page-header pageHeading="A demo page heading">
 *  <!-- any additional html content -->
 *  <button>A demo button</button>
 * </app-page-header>
 * ```
 */
@Component({
    selector: 'app-page-header',
    template: `
        <div [style.justify-content]="justifyContent" class="flex items-center mx-4 my-5 sm:m-8">
            <div class="flex items-center gap-2">
                <ng-content select="[page-prefix]"></ng-content>
                <h2 class="text-[#5B5B5B] !text-lg font-semibold capitalize !m-0">{{ pageHeading | translate }}</h2>
                <button *ngIf="showButton" mat-icon-button matSuffix matTooltip="{{matTooltipText}}" (click)="handleButtonClick()">
                    <svg class="w-5 h-5 ml-2">
                        <use xlink:href="/assets/icon/heroicons-outline.svg#information-circle"></use>
                    </svg>
                </button>
            </div>

            <ng-content></ng-content>
        </div>
    `,
})
export class PageHeaderComponent {
    /**
     * Heading of the page to be rendered.
     *
     * This can also be translation key,
     * which will be translated internally.
     */
    @Output() buttonClick = new EventEmitter<void>();
    @Input()
    pageHeading = 'Demo Heading';
    @Input()
    showButton = false;

    @Input()
    matTooltipText = ''; 

    @Input()
    justifyContent= 'space-between';
    
    handleButtonClick() {
        this.buttonClick.emit();
    }
}
