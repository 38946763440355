<ion-header *ngIf="!countdownReachedZero">
    <ion-toolbar>
        <span>{{ 'label.logged_out_in' | translate }}</span>
        <ion-button slot="end" fill="none" (click)="dismiss()">
            <ion-icon name="close"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<div class="ion-padding" style="justify-content: center">
    <div *ngIf="!countdownReachedZero" class="countdown">{{ formattedTime }}</div>
    <h2 style="text-align: center; margin-top: 30px" *ngIf="countdownReachedZero">
        {{ 'label.inactivity_logged_out' | translate }}
    </h2>
</div>
<ion-footer class="mt-5">
    <ion-button class="btn__primary btn__primary-outlined" (click)="dismiss()">{{
        'label.close' | translate
    }}</ion-button></ion-footer
>
