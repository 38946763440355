import {Component, OnInit, OnDestroy} from '@angular/core';
import {GlobalService} from '../../services/global.service';
import {SendFeedbackModal} from '../dashboard/send-feedback/send-feedback.modal';

@Component({
    selector: 'app-help',
    templateUrl: './help.modal.html',
})
export class HelpModal {
    btn = {
        title: 'label.send_feedback',
        icon: 'help-buoy-sharp',
    };
    links = [
        {
            name: 'label.resident_poster',
            link: 'https://tpprocessor-live.s3.ca-central-1.amazonaws.com/posters/resident_poster.pdf',
        },
        {
            name: 'label.help_add_bank_account',
            link: 'http://help.tenantpay.com/support/solutions/articles/72000569358-how-to-add-a-new-bank-account-',
        },
        {
            name: 'label.help_add_building',
            link: 'https://help.tenantpay.com/support/solutions/articles/72000572624-how-to-add-a-building-',
        },
        {
            name: 'label.help_add_resident',
            link: 'https://help.tenantpay.com/support/solutions/articles/72000572169-how-to-add-a-resident-',
        },
        {
            name: 'label.help_filterby_unit',
            link: 'http://help.tenantpay.com/support/solutions/articles/72000569719-how-to-see-the-transactions-by-unit-',
        },
        {
            name: 'label.help_change_account',
            link: 'http://help.tenantpay.com/support/solutions/articles/72000569381-how-to-change-bank-account-details-',
        },
        {
            name: 'label.help_send_letter',
            link: 'http://help.tenantpay.com/support/solutions/articles/72000569364-how-to-send-tenantpay-number-',
        },
        {
            name: 'label.help_remove_resident',
            link: 'https://help.tenantpay.com/en/support/solutions/articles/72000569382-how-to-remove-resident-',
        },
        {
            name: 'label.help_disable_building',
            link: 'http://help.tenantpay.com/support/solutions/articles/72000569363-how-to-disable-building-',
        },
    ];
    constructor(public global: GlobalService) {}
    ngOnInit() {}

    openLink(tab) {
        this.global.launchExternal(tab['link']);
    }

    async addFeedbackModal() {
        const modal: HTMLIonModalElement = await this.global.modalCtrl.create({
            component: SendFeedbackModal,
            componentProps: {},
            cssClass: 'full-height-modal',
        });
        this.global.openedModals.push(modal);
        modal.onDidDismiss().then((detail) => {
            this.global.openedModals.pop();
        });

        await modal.present();
    }
}
