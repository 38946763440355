import {FormControl} from '@angular/forms';
import {Component, EventEmitter, Input, Output} from '@angular/core';

import {PresignedUploadImage} from './image-upload.modal';

/**
 * `ImageUploadComponent` is a reusable component that facilitates the functionality of uploading and displaying of an image.
 *  It can be used in two ways:
 *
 * 1. Displaying an image by passing its URL through the 'image' input property.
 * 2. Managing image display through a form control by passing it through the 'imageCtrl' input property,
 *    which allows for easier integration with Angular forms.
 *
 * The component provides the following features:
 *
 * - Displaying the provided image or a default image if none is provided.
 * - Triggering the file upload dialog.
 * - Removing of image and resetting the form control.
 * - Emitting events 'onChange' and 'imageChange' to notify parent components of changes in the image state.
 * - Handling the image upload to S3, updating the form control, and emitting events when the upload is completed.
 */
@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styles: [
        `
            :host {
                position: relative;
            }
        `,
    ],
})
export class ImageUploadComponent {
    /** the image control internally to be used with setter function of the same name */
    private _imageCtrl = new FormControl<string | null>('');
    /** the image internally to be used with setter function of the same name */
    private _img = '';

    /** image that will be displayed */
    protected displayImage = '';
    /** helps triggering the upload file event */
    protected triggerExternalClickCount = 0;
    /**
     * this is the default image which will be used in 2 scenarios
     *  - the passed image is null or undefined
     *  - the uploaded images fails to load or is an invalid image format
     */
    protected readonly DEFAULT_IMAGE = '/assets/img/user.svg';

    /** image which will be displayed initially */
    @Input()
    set image(val: string | null) {
        this._img = val;
        this.displayImage = val || this.DEFAULT_IMAGE;
    }

    /**
     * this is the second way of using this component.
     * instead of the image file, a form control containing the image can be passed.
     * the form control will be updated internally and then parent does not need to handle the updating part.
     */
    @Input()
    set imageCtrl(val: FormControl<string | null>) {
        this._imageCtrl = val;
        // this hack needs to be used to work both with image and formControl
        // because of the image use/store behavior at BE
        let imgToUse = val.value;
        if (this._img) {
            imgToUse = this._img;
        }
        this.displayImage = imgToUse || this.DEFAULT_IMAGE;
    }

    /** wether or not to use the UI provided by the component */
    @Input()
    useUi = true;

    /** this will emit the uploaded file details */
    @Output()
    onChange = new EventEmitter<PresignedUploadImage | null>();

    /** this will emit the uploaded file download url and can be used with image for two way binding */
    @Output()
    imageChange = new EventEmitter<string | null>();

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * triggers the file upload operation which opens the OS file upload dialog
     */
    uploadImage = () => {
        this.triggerExternalClickCount++;
    };

    /**
     * removes the image, resets everything including form control
     * and emits null value to parent
     */
    removeImage = () => {
        this.displayImage = this.DEFAULT_IMAGE;
        this.onChange.emit(null);
        this.imageChange.emit(null);
        this._imageCtrl.setValue(null);
    };

    /**
     * after the image uploading to S3 is completed
     * updates the form control and emits the updated values to parent
     *
     * @param [image] the uploaded image received from S3
     */
    afterUpload([image]: PresignedUploadImage[]) {
        this.displayImage = image.downloadUrl;
        this.onChange.emit(image);
        this.imageChange.emit(image.downloadUrl);
        this._imageCtrl.setValue(image.fileUrl);
    }
}
