import {from} from 'rxjs';
import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';

import {UploadDocumentModal} from '../upload-document';
import {GettingStartedService} from 'src/app/pages/getting-started';
import {DocumentType, KycDocument} from '../../account-information.modal';
import {KycStatus} from 'src/app/pages/getting-started/getting-started.model';

@Component({
    selector: 'app-ai-document-item',
    templateUrl: './document-item.component.html',
})
export class DocumentItemComponent {
    /** title of the document */
    @Input()
    set title(val: DocumentType) {
        this._title = val;
        // convert to snake case for translation purpose
        this.snakeCaseTitle = `document_type.${val.split('-').join('_')}`;
    }

    /** title of the document */
    get title() {
        return this._title;
    }

    /**  wether to show subtitle or not */
    @Input()
    set useSubTitle(val: '' | boolean) {
        this._useSubtitle = val === '' || val;
    }

    /** wether to show subtitle or not */
    get useSubTitle() {
        return this._useSubtitle;
    }

    /** previously uploaded document */
    @Input()
    document: KycDocument | KycDocument[] = [];

    /** kyc status details state */
    kycStatus$ = this.gettingStartedService.kycStatus$;
    /** expose kycStatus enum to html */
    KycStatus = KycStatus;

    /** used to display and translation purpose only */
    protected snakeCaseTitle = 'Demo Title';

    /** title of the document, used with setter and getter function */
    private _title: DocumentType;
    /** wether to show subtitle or not, used with setter and getter function */
    private _useSubtitle = false;

    /** constructor */
    constructor(
        private readonly modalCtrl: ModalController,
        private readonly gettingStartedService: GettingStartedService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * open a dialog for handling document uploading operation
     */
    openUploadDocumentDialog() {
        const dialogProps = {
            title: this.snakeCaseTitle,
            documentType: this.title,
        };

        // create the upload document modal element
        const modalPromise = this.modalCtrl.create({
            component: UploadDocumentModal,
            componentProps: dialogProps,
            cssClass: 'auto-height ion-disable-focus-trap ',
        });

        // opens the dialog
        from(modalPromise).subscribe((modal) => modal.present());
    }

    /**
     * opens the uploaded document in new window
     */
    viewDocument(url: string) {
        window.open(url, '_blank');
    }

    /**
     * get the list of documents url for displaying purpose
     */
    getDocumentsUrlsAsArray() {
        if (!Array.isArray(this.document)) {
            return [this.document.url];
        }
        return this.document.map((doc) => doc.url);
    }
}
