import {Component} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

import {AuthService} from '../../auth.service';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['../forms-shared.scss'],
})
export class ForgotPasswordComponent {
    /** username form control which holds the updated value of the username */
    usernameCtrl = new FormControl('', Validators.required);
    /** flag maintained to display the final message after the forgot password request is successful */
    isReqSuccessful = false;
    /** error message received from BE */
    errorMessage?: string;

    /** constructor */
    constructor(public readonly global: GlobalService, private readonly authService: AuthService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * send forgot password request to BE
     */
    sendForgotPasswordReq() {
        if (this.usernameCtrl.invalid) {
            this.global.basicAlert('', 'label.user_name_missing', true);
            return;
        }

        const trimmedUsername = this.usernameCtrl.value.trim();
        if (trimmedUsername.length > 0) {
            this.authService.forgotPassword(trimmedUsername).subscribe((response) => {
                if (response.message === 'successful') {
                    this.isReqSuccessful = true;
                } else {
                    // identify the type of error
                    const msg = ['no_email', 'no_user'].includes(response.message) ? response.message : 'general';
                    this.errorMessage = `errors.${msg}`;
                    // set the beError flag in form control to show validation error message
                    this.usernameCtrl.setErrors({beError: true});
                }
            });
        } else {
            this.usernameCtrl.setErrors({required: true});
        }
    }
}
