<!-- image uploader -->
<app-image-upload
    #imgUpload
    [useUi]="false"
    class="mx-auto hidden"
    [(image)]="image"
    (onChange)="onFileUpload($event.fileNameWithExt)"
    [imageCtrl]="form.controls.filePath">
</app-image-upload>

<!-- upload UI -->
<div *ngIf="!image" class="uploadImage cursor-pointer" (click)="imgUpload.uploadImage()">
    <!-- document type icon -->
    <svg class="sidebar__item-icon" slot="start">
        <use [attr.xlink:href]="'/assets/icon/heroicons-outline.svg#' + icon"></use>
    </svg>

    <!-- document upload description -->
    <ion-label>
        <h3>{{ 'label.upload_' + snakeCaseTitle | translate }}</h3>
        <p>{{ 'label.jpeg_or_png_only' | translate }}</p>
    </ion-label>
</div>

<!-- display final image when upload is completed -->
<img *ngIf="image" [src]="image" class="h-40 mx-auto" alt="uploaded document image" />
