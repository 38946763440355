<!--
    forgot password form
    this form is conditionally rendered until request is completed successfully
 -->
<form *ngIf="!isReqSuccessful; else finalMessage" class="form">
    <!-- form heading and sub heading -->
    <div class="form__heading-container">
        <ion-text class="form__heading">
            {{ 'label.forgot_ur_password' | translate }}
        </ion-text>

        <ion-text class="form__sub-heading">{{ 'label.username_for_resetting_password' | translate }}</ion-text>
    </div>

    <!-- username input field -->
    <ion-item class="w-full form__input">
        <ion-label position="floating">{{ 'label.user_name' | translate }}</ion-label>
        <ion-input
            appEnterClick
            type="text"
            [formControl]="usernameCtrl"
            (keydown)="this.errorMessage = undefined"
            data-cy="app-forgot-password-username">
        </ion-input>
        <ion-note *ngIf="usernameCtrl.touched && usernameCtrl.hasError('required')" slot="error">
            {{ 'errors.required' | translate }}
        </ion-note>
        <!-- error message received from BE -->
        <ion-note
            id="forgot-password-error-msg"
            *ngIf="errorMessage"
            slot="error"
            [innerHTML]="errorMessage | translate">
        </ion-note>
    </ion-item>

    <!-- actions button container -->
    <ion-grid class="w-full">
        <ion-row>
            <!-- forgot password request initiator button -->
            <ion-col>
                <ion-button
                    type="submit"
                    class="w-full btn__primary"
                    tappable
                    id="forgotPasswordBtn"
                    expand="block"
                    (click)="sendForgotPasswordReq()"
                    >{{ 'label.reset_password' | translate }}</ion-button
                >
            </ion-col>

            <!-- back to login page button -->
            <ion-col>
                <ion-button
                    class="w-full btn__primary-outlined"
                    tappable
                    id="forgotPasswordBtn"
                    expand="block"
                    (click)="global.goBackOrPush('/login')"
                    >{{ 'label.cancel' | translate }}</ion-button
                >
            </ion-col>
        </ion-row>
    </ion-grid>
</form>

<!--
    success message template
    this template will be rendered conditionally
    once the forgot password request is completed successfully
 -->
<ng-template #finalMessage>
    <div class="form">
        <ion-text id="password-link-sent-msg" class="form__heading">{{
            'label.password_link_sent' | translate
        }}</ion-text>
    </div>
</ng-template>
