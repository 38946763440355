import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';

import {ProfileService} from '../../profile';
import {getAddressFormGroup} from '../../../utils/helpers';
import {AccountInformation} from '../account-information.modal';
import {AccountInformationService} from '../account-information.service';

@Component({
    selector: 'app-ai-user-details',
    templateUrl: './user-details.component.html',
})
export class UserDetailsComponent {
    /** individual information form group */
    form = this.initForm();

    /**
     * constructor
     *
     * patches the form with data received from the resolver
     */
    constructor(
        readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly profileService: ProfileService,
        private readonly accInfoService: AccountInformationService
    ) {
        const {individualInformation} = route.snapshot.data.accountInformation as AccountInformation;
        this.form.patchValue(individualInformation);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * update the individual information data at Backend
     */
    updateIndividualInformation() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const payload = this.form.value;
        // sets empty url to null for resetting the value
        if (!payload.linkedinUrl) {
            payload.linkedinUrl = null;
        }

        this.accInfoService.updateIndividualInformation(payload);
    }

    /**
     * patch the logged in user profile details into the form
     */
    copyProfileDetails() {
        const profileDetails = this.profileService.profileDetails$.value;
        this.form.patchValue(profileDetails);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initializes the individual information form
     *
     * @returns individual information form group
     */
    private initForm() {
        return this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            phoneNumber: ['', Validators.required],
            linkedinUrl: [null],
            address: getAddressFormGroup(),
        });
    }
}
