import {Address} from 'src/app/utils/modals/address';
import {TimeStampAddedUpdated} from 'src/app/utils/modals/general';

/** account type */
export enum KycType {
    COMPANY = 'company',
    INDIVIDUAL = 'individual',
}

/** key details */
export interface Kyc extends TimeStampAddedUpdated {
    completed: number;
    hashId: string | null;
    isActive: number;
    kycId: string;
    kycType: KycTypeResp;
    kycTypeId: number;
    referenceId: string;
    referenceTable: string;
    removed: number;
}

/** updated kyc response */
interface KycTypeResp extends TimeStampAddedUpdated {
    kycTypeId: string;
    name: KycType;
    removed: number;
}

/** number of units */
export enum NumberOfUnits {
    '1 - 50' = '1 - 50',
    '51 - 250' = '51 - 250',
    '251 - 1000' = '251 - 1000',
    '1001 - 5000' = '1001 - 5000',
    '5001 - 20000' = '5001 - 20000',
    '20000+' = '2500+',
}

/** real estate types */
export enum RealEstateType {
    APARTMENT = 'Apartment',
    CONDO_STRATA = 'Condo/Strata',
    CO_OP = 'Co-Op',
    NOT_FOR_PROFIT = 'Not-For-Profit',
    TRAILER_PARK_RV = 'Trailer Park/RV',
    SECURE_STORAGE = 'Secure Storage',
    RETAIL = 'Retail',
    STUDENT_HOUSING = 'Student Housing',
    OTHER = 'Other',
}

/** company management types */
export enum CompanyManagementType {
    SELF_OWNED_PROPERTIES = 'self-owned-properties',
    PROPERTIES_FOR_OTHERS = 'properties-for-others',
    BOTH = 'both',
}

/** company meta data details */
export interface CompanyMetaData extends TimeStampAddedUpdated {
    accountingSoftware: string | null;
    companyId: string;
    companyManagementTypeId: string;
    companyMetaDataId: string;
    kycId: string;
    numberOfBankAccounts: number | null;
    numberOfBuildings: number;
    numberOfUnits: NumberOfUnits;
    realEstates: RealEstate[];
    referedByUserId: string | null;
}

/** real estate details */
interface RealEstate {
    companyId: string;
    companyRealEstateId: string;
    otherType: string | null;
    realEstateType: RealEstateType;
    timestampAdded: Date;
    timestampRemoved: Date | null;
}

/** company information details */
export interface CompanyInformation extends TimeStampAddedUpdated {
    address: Address;
    companyName: string;
    email: string | null;
    isPersonalAccount: number;
    kycCompanyInformationId: string;
    kycId: string;
    phoneNumber: string;
    taxNumber: number | null;
    website: number | null;
}

/** individual user information details */
export interface IndividualInformation extends TimeStampAddedUpdated {
    address: Address;
    dateOfBirth: Date | null;
    email: string;
    firstName: string;
    kycBasicInformationId: string;
    kycId: string;
    lastName: string;
    linkedinUrl: string;
    phoneNumber: string;
}

/** KYC documents response */
export interface KycDocuments {
    [DocumentType.ARTICLES_OF_INCORPORATION]: KycDocument;
    [DocumentType.BANK_STATEMENT]: KycDocument;
    [DocumentType.BILL]: KycDocument;
    [DocumentType.GOVERNMENT_ID]: KycDocument[];
}

/** KYC document structure */
export interface KycDocument extends TimeStampAddedUpdated {
    approved: number;
    comments: string | null;
    documentName: DocumentName;
    documentType: DocumentType;
    fileName: string;
    filePath: string;
    issuedCountry: string;
    kycDocumentId: string;
    kycId: string;
    removed: number;
    removedByUserId: string | null;
    timestampRemoved: string | null;
    url: string;
}

/** Allowed Document Types */
export enum DocumentType {
    GOVERNMENT_ID = 'government-id',
    BANK_STATEMENT = 'bank-statement',
    BILL = 'bill',
    ARTICLES_OF_INCORPORATION = 'articles-of-incorporation',
}

/** Allowed Document Names */
export enum DocumentName {
    PASSPORT = 'passport',
    IDENTITY_CARD = 'identity-card',
    // driver's license as a whole is not a document name, but kept here for internal purposes
    DRIVERS_LICENSE = 'driver-license',
    DRIVERS_LICENSE_FRONT = 'drivers-license-front',
    DRIVERS_LICENSE_BACK = 'drivers-license-back',
    BANK_STATEMENT = 'bank-statement',
    TAX_BILL = 'tax-bill',
    UTILITY_BILL = 'utility-bill',
    ARTICLES_OF_INCORPORATION = 'articles-of-incorporation',
}

/** account information object helpful for resolver */
export interface AccountInformation {
    kycType: Kyc;
    companyMetaData: CompanyMetaData;
    companyInformation: CompanyInformation;
    individualInformation: IndividualInformation;
    documents: KycDocuments;
}
