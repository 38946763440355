<!-- company related info -->
<div class="company">
    <!-- tenant pay logo -->
    <img src="/assets/logos/tenantpay-white.png" class="company__logo" />

    <!-- product image -->
    <img src="/assets/img/product.webp" class="company__product" />

    <!-- tag line -->
    <ion-label fill="outline" class="ion-text-wrap company__heading">
        {{ 'label.company_tagline' | translate }}
    </ion-label>

    <!-- app description -->
    <ion-label class="company__data">
        {{ 'label.company_work' | translate }}
    </ion-label>
</div>
