import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';

import {TopbarComponent} from './topbar.component';

@NgModule({
    declarations: [TopbarComponent],
    imports: [IonicModule],
    exports: [TopbarComponent],
})
export class TopbarModule {}
