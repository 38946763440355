import {TranslateService} from '@ngx-translate/core';
import {MatFormField} from '@angular/material/form-field';
import {AbstractControl, FormControl, FormControlName} from '@angular/forms';
import {AfterViewInit, Directive, ElementRef, Injector, Input, OnInit, Renderer2} from '@angular/core';

import {ParamsTranslatePipe} from 'src/app/pipes/paramsTranslate.pipe';

/**
 * A custom directive which is to be used within material form field.
 * This directive automatically detects the error on the form control,
 * translates the error message and attaches to the element it has been placed upon.
 *
 * Usage:
 * To use this directive, simply apply it to an mat-error or any container element
 * where you want to show the error, like this:
 *
 * ```html
 * <mat-form-field>
 *   <input type="text" formControlName="firstName">
 *   <mat-error appFieldErrors></mat-error>
 * </mat-form-field>
 * ```
 *
 * Ensure that the container element where the directive is placed upon
 * is within a material form field element.
 */
@Directive({
    selector: '[appFieldErrors]',
})
export class FieldErrorsDirective implements OnInit, AfterViewInit {
    /** mat form field injected down in the code */
    matFormField!: MatFormField;
    /** the error message to display */
    error = '';
    /** the name or identifier of the associated form control */
    controlName: string | number = '';
    /** additional data related that needs for translation purpose */
    additionalData: Record<string, any> = {};
    /** the form control associated with the form field inside which this directive is placed */
    control: FormControl | AbstractControl = new FormControl();

    /** Custom property name to be used for error translation. If not provided, the control name is used */
    @Input()
    set customPropertyName(val: string) {
        this._customPropertyName = val;
        this.createAdditionObj();
        this.setErrorValueOnField();
    }

    /** Custom property name to be used for error translation. If not provided, the control name is used */
    get customPropertyName() {
        return this._customPropertyName;
    }

    /** The type of field, used for translations */
    @Input()
    fieldType: 'Character' | 'Digit' = 'Character';

    /** extra parameters for the field, which are related to the translation purpose */
    @Input('appFieldErrors')
    set paramsField(params: Record<string, any> | string) {
        this._params = params;
        if (this.controlName) {
            this.createAdditionObj();
        }
    }

    /** params used with the paramsField setter function */
    private _params: Record<string, any> | string = '';
    /** parameter translating pipe instance */
    private paramsPipe = new ParamsTranslatePipe(this.translateService);
    /** custom property name used with setter/getter function */
    private _customPropertyName = '';

    /** constructor */
    constructor(private readonly injector: Injector, private readonly renderer: Renderer2, private readonly elRef: ElementRef<HTMLElement>, private readonly translateService: TranslateService) {
        this.matFormFieldInjector();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Life Cycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * attach a subscriber to listen for translation changes.
     */
    ngOnInit() {
        this.attachTranslationChangeSubscriber();
    }

    /**
     * set up the directive after the view has been initialized, retrieve the associated form control,
     * and attach a listener to monitor its status changes.
     */
    ngAfterViewInit(): void {
        const ctrl = this.matFormField._control?.ngControl as FormControlName;
        if (!ctrl) {
            return;
        }

        this.control = ctrl.control;
        this.controlName = this.convertToSnakeCase(ctrl.name || '');
        this.createAdditionObj();
        this.attachStatusUpdateSubscriber();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * attach a listener to monitor form control status changes.
     * update the error message when the form control becomes invalid.
     */
    private attachStatusUpdateSubscriber() {
        this.control.statusChanges.subscribe((ctrlStatus) => {
            if (ctrlStatus !== 'INVALID') {
                return;
            }
            this.getFormControlErrors();
            this.setErrorValueOnField();
        });

        // updates the validity of the control to trigger the subscription
        this.control.updateValueAndValidity();
    }

    /**
     * Attach a subscriber to listen for translation changes.
     * update the error message when the translation changes.
     */
    private attachTranslationChangeSubscriber() {
        this.translateService.onLangChange.subscribe(this.setErrorValueOnField);
    }

    /**
     * create additional data related to be used by params translation pipe
     */
    private createAdditionObj(): void {
        const property = `label.${this.customPropertyName || this.controlName}`;

        this.additionalData = {
            property,
            ...(typeof this._params !== 'string' && this._params),
        };
    }

    /**
     * check common error keys for the given form control and set the error message.
     * this is the third version and will replace the old versions once the all html changes has been done
     */
    private getFormControlErrors() {
        const errors = this.control.errors;
        const errorKeys = Object.keys(errors ?? {});
        if (!errors || !errorKeys.length) {
            this.error = '';
            return;
        }
        const getErrorKey = (error: string | number) => `errors.${error}`;
        const orderedErrors = [
            {error: 'matDatepickerParse', key: getErrorKey('date.invalid_date')},
            {error: 'required', key: getErrorKey('property_required')},
            {error: 'whitespace', key: getErrorKey('whitespace')},
            {error: 'leadingTrailingSpaces', key: getErrorKey('leadingTrailingSpaces_desc')},
            {error: 'maxlength', key: `${getErrorKey('max_length_crossed')}${this.fieldType}`},
            {error: 'minlength', key: `${getErrorKey('min_length_not_reached')}${this.fieldType}`},
            {error: 'mask', key: getErrorKey(this.controlName)},
            {error: 'pattern', key: getErrorKey(`${this.controlName}_pattern`)},
            {error: 'matchValueError', key: getErrorKey('value_match')},
            {
                error: 'matDatepickerMax',
                key: getErrorKey('date.greater_than_max_date'),
            },
            {error: 'matDatepickerMin', key: getErrorKey('date.less_than_min_date')},
        ];
        const finalErrorKey = orderedErrors.find(({error}) => this.control.hasError(error))?.key ?? getErrorKey(errorKeys[0]);
        this.error = finalErrorKey;
    }

    /**
     * set the error message on the directive's host element based on the translated error key and additional data.
     */
    private setErrorValueOnField = () => {
        if (!this.error) {
            return;
        }

        // translate additional parameters
        const paramsTranslation = this.paramsPipe.transform(this.additionalData);
        // translate the error message
        const translatedError = this.translateService.instant(this.error, paramsTranslation);
        // render the error message on the element
        this.renderer.setProperty(this.elRef.nativeElement, 'innerText', translatedError);
    };

    /**
     * injects the matTooltip instance from the injector.
     * throws an error if matTooltip is not found in the injector.
     */
    private matFormFieldInjector() {
        try {
            this.matFormField = this.injector.get(MatFormField);
        } catch (e) {
            throw new Error(`'appFieldErrors' directive must be used inside a 'MatFormField' element.\nMake sure you have wrapped it correctly.`);
        }
    }

    /**
     * convert a given input value to snake_case
     */
    private convertToSnakeCase = (val: string | number) =>
        val
            .toString()
            .replace(/([a-z])([A-Z])/g, '$1_$2')
            .replace(/[\s_]+/g, '_')
            .toLowerCase();
}
