import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {debounceTime} from 'rxjs/operators';

import {GlobalService} from './global.service';

/**
 * service for managing loading indicators in the Angular application.
 */
@Injectable({providedIn: 'root'})
export class LoaderService {
    /**
     * a subject that emits a boolean value to indicate whether loading is in progress.
     */
    isLoading = new Subject<boolean>();

    /**
     * the total number of active loading requests.
     */
    totalRequests = 0;

    /** constructor */
    constructor(private readonly global: GlobalService) {
        this.attachLoaderListener();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * increments the totalRequests count and triggers the loading indicator.
     */
    show() {
        this.totalRequests += 1;
        this.isLoading.next(true);
    }

    /**
     * decrements the totalRequests count and hides the loading indicator if no active requests are present.
     */
    hide() {
        if (this.totalRequests > 0) {
            this.totalRequests -= 1;
        }
        if (this.totalRequests < 1) {
            this.isLoading.next(false);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * attaches a listener which will internally call the
     * globalService's show/hide loader methods
     */
    private attachLoaderListener() {
        // debounce time ensures that multiple loaders won't be shown for multiple requests
        this.isLoading.pipe(debounceTime(600)).subscribe((loading) => {
            if (loading) {
                this.global.showLoading();
            } else {
                this.global.hideLoading();
            }
        });
    }
}
