import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {
    KycType,
    NumberOfUnits,
    RealEstateType,
    AccountInformation,
    CompanyManagementType,
} from '../account-information.modal';
import {AccountInformationService} from '../account-information.service';

/** basic information form group */
type Form = FormGroup<{
    realEstateTypes: FormArray<FormControl<RealEstateType>>;
    otherType?: FormControl<string>;
    companyManagementType: FormControl<string>;
    numberOfBuildings: FormControl<number>;
    numberOfUnits: FormControl<NumberOfUnits | null>;
}>;

@Component({
    selector: 'app-ai-info-form',
    templateUrl: './info-form.component.html',
})
export class InfoFormComponent implements OnInit {
    /** basic information form group */
    form: Form = this.initForm();
    /** account type form control */
    accountTypeCtrl = new FormControl<KycType | null>(null);
    /** account types for conditional checking */
    KycType = KycType;
    /** cost per transaction */
    costPerTransaction = 2;

    /** account types for rendering */
    protected accountTypes = Object.values(KycType);
    /** number of units */
    protected numberOfUnits = Object.keys(NumberOfUnits);
    /** company manages rendering options */
    protected companyManages = Object.entries(CompanyManagementType);
    /** real estate rendering options */
    protected realEstateTypes = Object.entries(RealEstateType);
    /** flag used to maintain the state of selection of other type realEstate */
    protected isOtherSelected = false;

    /**
     * constructor
     *
     * initializes the account type control
     */
    constructor(
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly accInfoService: AccountInformationService
    ) {
        this.accountTypeCtrl.setValue(this.accInfoService.accountType$.value);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Life Cycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * attach the account type value change listeners
     */
    ngOnInit() {
        this.attachAccountTypeListener();
        this.attachOtherTypeListener();
        this.patchFormData();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * update the company meta data at Backend
     */
    updateCompanyMetadata() {
        const payload = this.form.value;
        payload.realEstateTypes = payload.realEstateTypes.filter((v) => v !== null);

        if (this.form.invalid || !payload.realEstateTypes.length) {
            this.form.markAllAsTouched();
            return;
        }

        this.accInfoService.updateCompanyMetaData(this.form.value);
    }

    /**
     * updates the checkbox form control value with the realEstate type instead of default boolean value
     *
     * @param index the currently selected checkbox index in the form array
     * @param event the custom event triggered by the ion-checkbox when value is changed
     */
    updateCheckboxValue(index: number, event: Event) {
        const realEstateCtrl = this.form.controls.realEstateTypes.controls[index];
        const checked = (event as CustomEvent<{checked: true}>).detail.checked;

        if (checked) {
            // if user selects the value then get the real estate value from enum and update it to form control
            const [_, value] = this.realEstateTypes[index];
            realEstateCtrl.setValue(value);
        } else {
            // otherwise reset the form control to default null value
            realEstateCtrl.reset();
        }
    }

    /**
     * determines whether to trigger an alert condition based on form values.
     *
     * @returns true if the alert condition is met, otherwise false.
     */
    getAlertCondition() {
        const noOfUnits = this.form.controls.numberOfUnits.value;
        const realEstateTypes = this.form.controls.realEstateTypes.value;

        // check if 'Not for Profit' and 'Co-Op' are selected in realEstateTypes.
        const isNotForProfit = realEstateTypes.includes(RealEstateType.NOT_FOR_PROFIT);
        const isCoOp = realEstateTypes.includes(RealEstateType.CO_OP);
        const isNfpOrCoOp = isNotForProfit || isCoOp;

        // set the cost per transaction based on the selection.
        this.costPerTransaction = isNfpOrCoOp ? 1.5 : 2;
        if (isNfpOrCoOp && noOfUnits === NumberOfUnits['10 - 25']) {
            return true;
        }

        if (noOfUnits === NumberOfUnits['0 - 10']) {
            return true;
        }

        return false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initializes the basic account information form
     *
     * @returns basic account information form group
     */
    private initForm() {
        // initializes the basic form group
        const form = this.formBuilder.group({
            realEstateTypes: this.formBuilder.array<RealEstateType>([]),
            companyManagementType: this.getCompanyManagementTypeCtrl(),
            numberOfBuildings: this.formBuilder.control<number | null>(null, [Validators.min(0), Validators.required]),
            numberOfUnits: this.formBuilder.control<NumberOfUnits | null>(null, Validators.required),
        });

        // initializes the form array with the total number of checkboxes controls
        const realEstateCtrl = form.controls.realEstateTypes;
        Object.values(RealEstateType).forEach(() => {
            const ctrl = this.formBuilder.control<RealEstateType | null>(null);
            realEstateCtrl.push(ctrl);
        });

        return form;
    }

    /**
     * attach the account type value change listener.
     * updates the kyc type at BEand conditionally add/remove
     * the companyManagementType control from the form group
     */
    private attachAccountTypeListener() {
        this.accountTypeCtrl.valueChanges.subscribe((accountType) => {
            this.accInfoService.updateKycType(accountType);
            switch (accountType) {
                // in case of company type add the company management control
                case KycType.COMPANY: {
                    this.form.addControl('companyManagementType', this.getCompanyManagementTypeCtrl());
                    break;
                }

                // in case of individual type remove the company management control
                case KycType.INDIVIDUAL: {
                    this.form.removeControl('companyManagementType');
                }
            }
        });
    }

    /**
     * attache the value change listener on the other type checkbox control
     */
    private attachOtherTypeListener() {
        const otherCheckboxCtrl = this.form.controls.realEstateTypes.controls[this.realEstateTypes.length - 1];
        otherCheckboxCtrl.valueChanges.subscribe((otherType) => {
            if (!!otherType) {
                this.form.addControl('otherType', this.getOtherTypeControl());
            } else {
                this.form.removeControl('otherType');
            }
            this.isOtherSelected = !!otherType;
        });
    }

    /**
     * patches the form with data received from the resolver
     */
    private patchFormData() {
        const {companyMetaData} = this.route.snapshot.data.accountInformation as AccountInformation;
        let managementId = +companyMetaData.companyManagementTypeId || undefined;

        // backend stores value from 1-3, while frontend array starts with 0
        if (managementId) {
            managementId--;
        }

        // patches the basic data
        this.form.patchValue({
            ...companyMetaData,
            companyManagementType: this.companyManages[managementId]?.[1],
        });

        // patches the form array data
        const realEstateControls = this.form.controls.realEstateTypes.controls;
        companyMetaData.realEstates?.forEach((realEstate) => {
            const realEstateIndex = this.realEstateTypes.findIndex(
                ([_, realEstateType]) => realEstateType === realEstate.realEstateType
            );
            realEstateControls[realEstateIndex].setValue(realEstate.realEstateType);

            // if the other type checkbox is selected then patch it's value
            if (realEstate.realEstateType === RealEstateType.OTHER) {
                this.form.controls.otherType?.setValue(realEstate.otherType);
            }
        });
    }

    /**
     * get the companyManagementType form control
     *
     * @returns companyManagementType Form Control
     */
    private getCompanyManagementTypeCtrl() {
        return this.formBuilder.control('', [Validators.min(1), Validators.max(3)]);
    }

    /**
     * get the otherType form control
     *
     * @returns otherType form control
     */
    private getOtherTypeControl() {
        return this.formBuilder.control('', Validators.required);
    }
}
