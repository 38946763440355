import {Injectable, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {
    AlertController,
    ToastController,
    LoadingController,
    MenuController,
    ModalController,
    NavController,
    PopoverController,
    ActionSheetController,
} from '@ionic/angular';
import {catchError, finalize, map} from 'rxjs/operators';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, from, of} from 'rxjs';
import $ from 'jquery';
import decode from 'jwt-decode';
import * as _ from 'lodash';
import * as globalPaths from '../pathConfig';
import * as moment from 'moment';
import {Camera, CameraResultType, CameraSource, Photo} from '@capacitor/camera';
import {EventService} from './event.service';
import {Browser} from '@capacitor/browser';
// import { CurrencyPipe } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    // public API_URL = 'http://localhost:3000/api';
    public $ = $;
    public _ = _;
    public moment = moment;
    public isMobileApplication = globalPaths.IS_MOBILE_APPLICAION;
    public API_URL = globalPaths.API_URL;
    public routerOutlet;
    public loader;
    public httpReqOptions = {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})};
    loadingAnimationRunning = false;
    showBackIcon = false;
    loggedInUser = null;
    appInitiated = false;
    public globalFileUploadInput;
    public multipleFileUpload;
    public FILEOF;
    public fakeItems = [1, 2, 3, 4, 5, 6];
    public renderer;
    public limitForEntries = 20;
    public currencyCode = 'CAD';
    public currencySymbol = '$';
    public langCode = 'en';
    public userRole = null;
    public isResident = null;
    public allowResidentLogin = false;
    public APPTOUR_COMPLETED_KEY = 'tour_completed';
    public dashboardTour = false;
    fileUploadClicked = false;
    isWatching = false;
    yardiLoading = false;
    markingViewSummary = false;
    yardiTransactionDateForLog;
    startDate;
    tabs = 1;
    openedModals: HTMLIonModalElement[] = [];
    countFinished = false;
    private dashboardTransactionCountSubject = new BehaviorSubject<number>(5);
    dashboardTransactionCount$ = this.dashboardTransactionCountSubject.asObservable();
    superLoginWithExistingTab = false;
    pendingKyc = false;
    kycCompleted = true;
    public isPasswordValid = {
        minLength: false,
        lowerCase: false,
        upperCase: false,
        // eslint-disable-next-line id-blacklist
        number: false,
        symbol: false,
    };
    public allowedSymbols = /[!@#$%^&*]/;
    constructor(
        public http: HttpClient,
        // private translate: Injector,
        public translate: TranslateService,
        public modalCtrl: ModalController,
        public actionSheetCtrl: ActionSheetController,
        public navCtrl: NavController,
        public loadingCtrl: LoadingController,
        public menuCtrl: MenuController,
        public alertCtrl: AlertController,
        public toastCtrl: ToastController,
        public eventBus: EventService,
        public popover: PopoverController,
        public jwtHelper: JwtHelperService
    ) {
        // for some reason this is causing circular dependency with HTTP interceptors
        // moving it inside timeout seems to fix the problem
        setTimeout(() => {
            translate.setDefaultLang('en');
            translate.use('en');
        });
    }

    initLoggedInUser(redirect = false) {
        const token = this.getLocalStorage('tenantpayToken');
        if (token !== '' && token !== null && !this.jwtHelper.isTokenExpired(token)) {
            try {
                const tokenPayload = decode(token);
                this.appInitiated = true;
                this.loggedInUser = tokenPayload;
                this.loginEssential(redirect);
                return true;
            } catch (error) {
                return false;
            }
        }
        return false;
    }

    loginEssential(redirect) {
        this.sendAjaxRequest('user/verifyUserToken', {}).subscribe((response) => {
            if (response['message'] === 'success') {
                this.loggedInUser = {...this.loggedInUser, ...response['user']};
                this.userRole = this.loggedInUser['userRole'];
                this.pendingKyc = response.user.kycRequired;

                if (this.userRole === 'resident') {
                    this.isResident = true;
                } else {
                    this.isResident = false;
                }

                // this.loggedInUser['customerId'] = response['user']['customerId'];
                // this.loggedInUser['mainAccount'] = response['user']['mainAccount'];
                // this.loggedInUser['companyName'] = response['user']['companyName'];
                //  setTimeout(() => this.menuCtrl.enable(true), 2000); // a small delay added as the menu was not initializing when navigating from onboarding page.
                this.eventBus.broadcastEvent('AppInitatedSet', {});

                if (this.loggedInUser.companyId || this.loggedInUser.superUserId) {
                    if (redirect) {
                        this.pushPage('dashboard');
                    }
                } else {
                    this.pushPage('kyc-portal');
                }

                // this is the temporary solution for fetching logged in user details with new API
                // this needs to be shifted

                setTimeout(() => this.menuCtrl.enable(true), 500);
            } else {
                this.pushPage('login');
            }
        });
    }

    getRequestUrl(requestURL: string) {
        return this.API_URL + '/' + requestURL;
    }

    setDashboardTransactionCount(count: number) {
        this.dashboardTransactionCountSubject.next(count);
    }
    dashboardDynamicHeight(property) {
        let height: number;

        this.dashboardTransactionCount$.subscribe((count) => {
            if (count > 5) {
                if (property === 'transaction row') {
                    height = 5;
                } else if (property === 'stat row') {
                    height = 1;
                } else if (property === 'grid height') {
                    height = 600;
                } else if (property === 'stat col') {
                    height = 4;
                }
            } else {
                if (property === 'transaction row') {
                    height = 4;
                } else if (property === 'stat row') {
                    height = 2;
                } else if (property === 'grid height') {
                    height = 400;
                } else if (property === 'stat col') {
                    height = 2;
                }
            }
        });

        return height;
    }

    async showLoading(message: string = '', duration: number = 0) {
        if (!this.loadingAnimationRunning) {
            this.loadingAnimationRunning = true;
            let options = {};
            if (duration !== 0) {
                options = {
                    message,
                    duration,
                };
            } else {
                options = {
                    message,
                };
            }

            this.loader = this.loadingCtrl.create(options);
            return await this.loader.then((a) => {
                a.present().then(() => {
                    if (!this.loadingAnimationRunning) {
                        a.dismiss().then(() => {});
                    }
                });
            });
        }
    }

    async hideLoading() {
        if (!this.yardiLoading) {
            try {
                this.loadingAnimationRunning = false;
                return await this.loadingCtrl.dismiss().then(() => {});
            } catch (err) {
                if (typeof err === 'string' && !err.includes('overlay does not exist')) {
                    console.error(err);
                }
            }
        }
    }

    async invalidToast(message, inPosition = 'middle', inDuration = 2000) {
        const toast = await this.toastCtrl.create({
            message: this.translate.instant(message),
            duration: inDuration,
            position: 'top',
            color: 'danger',
            cssClass: 'small-toast',
        });
        toast.present();
    }

    async basicToast(message, inPosition = 'middle', inDuration = 2000) {
        const toast = await this.toastCtrl.create({
            message: this.translate.instant(message),
            duration: inDuration,
            position: 'top',
            cssClass: 'small-toast',
        });
        toast.present();
    }
    /*
		This function show simple alert with ok button as per environment
	*/
    async basicAlert(inTitle, inMessage, inTranslate = false) {
        if (inTranslate === true) {
            if (inTitle !== '') {
                inTitle = this.translate.instant(inTitle);
            }
            if (inMessage !== '') {
                inMessage = this.translate.instant(inMessage);
            }
        }

        const alert = await this.alertCtrl.create({
            header: inTitle,
            subHeader: '',
            message: inMessage,
            buttons: [this.translate.instant('label.ok')],
        });
        await alert.present();
    }

    async openActionSheet(title, actionSheetOptions) {
        const actionSheet = await this.actionSheetCtrl.create({
            header: title,
            buttons: actionSheetOptions,
        });
        await actionSheet.present();
    }

    param(data) {
        return $.param(data);
    }

    setToken() {
        const tempToken = this.getLocalStorage('tenantpayToken');
        if (tempToken !== 'false' && tempToken !== '') {
            this.httpReqOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // eslint-disable-next-line quote-props
                    Authorization: 'Bearer ' + tempToken,
                }),
            };
        }
    }
    sendAjaxRequest(requestURL, requestParam = {}, redirectOnUnauthorized = true, download = false): Observable<any> {
        requestURL = this.getRequestUrl(requestURL);
        this.setToken();

        if (this.isMobileApplication) {
            const httpResult = from(this.http.post(requestURL, this.param(requestParam), this.httpReqOptions));
            return httpResult.pipe(
                map((response) => {
                    const result = response;
                    if (redirectOnUnauthorized) this.handleUnauthorizedResponse(result);
                    return result;
                })
            );
        } else {
            const headers = download ? {...this.httpReqOptions, responseType: 'blob' as 'json'} : this.httpReqOptions;
            return this.http.post(requestURL, this.param(requestParam), headers).pipe(
                map((response) => {
                    const result = response;
                    if (redirectOnUnauthorized) this.handleUnauthorizedResponse(result);
                    return result;
                }),
                catchError((errorMessage) => of(errorMessage.error))
            );
        }
    }

    handleUnauthorizedResponse(result) {}

    async closeModal(data = {}) {
        const topModal = await this.modalCtrl.getTop();
        if (topModal) {
            await this.modalCtrl.dismiss(data);
        }
    }

    goBackOrPush(link) {
        if (this.routerOutlet.canGoBack()) {
            this.goBack();
        } else {
            this.pushPage(link);
        }
    }
    pushPage(link) {
        this.showBackIcon = true;
        this.navCtrl.navigateForward(link, {animated: false});
    }
    goBack() {
        this.navCtrl.back();
    }

    async closeAllModals() {
        if (this.openedModals.length > 1) {
            // Dismiss the second-to-last modal
            const secondLastModal = this.openedModals[this.openedModals.length - 2];
            await secondLastModal.dismiss();
            // Remove the dismissed modal from the stack
            this.openedModals.splice(this.openedModals.length - 2, 1);
        }
    }

    logoutUser() {
        this.isWatching = false;
        localStorage.clear();
        sessionStorage.clear();
        this.loggedInUser = false;
        this.menuCtrl.enable(false);
        this.countFinished = true;
        if (this.isResident) {
            this.navCtrl.navigateRoot('tenant-login');
        } else {
            this.navCtrl.navigateRoot('login');
        }
    }

    trackTabs() {
        if (localStorage.getItem('user-activity') === null) {
            localStorage.setItem('user-activity', this.startDate);
            localStorage.setItem('tabs', '1');
            if (this.superLoginWithExistingTab) {
                localStorage.setItem('tabs', '2');
            }
        } else if (this.startDate !== localStorage.getItem('user-activity')) {
            const tabs = +localStorage.getItem('tabs') + 1;
            localStorage.setItem('tabs', tabs.toString());
        }
    }
    openSideMenu() {
        this.menuCtrl.open('sideMenu');
    }
    closeSideMenu() {
        this.menuCtrl.close('sideMenu');
    }

    generateRandomNumber(inFrom, to) {
        return Math.floor(Math.random() * to) + inFrom;
    }
    openGuide(guide) {
        switch (guide) {
            case 'yardi':
                this.launchExternal(
                    'https://tpprocessor-live.s3.ca-central-1.amazonaws.com/integration-guide/yardi.pdf'
                );
                break;
            case 'newviews':
                this.launchExternal(
                    'https://tpprocessor-live.s3.ca-central-1.amazonaws.com/integration-guide/newviews.pdf'
                );
                break;
            case 'spectra':
                this.launchExternal(
                    'https://tpprocessor-live.s3.ca-central-1.amazonaws.com/integration-guide/spectra.pdf'
                );
                break;
            case 'shiftsuite':
                this.launchExternal(
                    'https://tpprocessor-live.s3.ca-central-1.amazonaws.com/integration-guide/spectra.pdf'
                );
                break;
            default:
                break;
        }
    }
    /******************* Photo Uploading Functions ************************* */
    clickFileUpload(allowedFileTypes: string = '') {
        if (this.isMobileApplication) {
            this.mobileUploadMenu();
        } else {
            this.fileUploadClicked = true;
            // eslint-disable-next-line max-len
            // <input type="file"  [multiple]="global.multipleFileUpload" #globalFileUpload id="globalFileUpload" name="globalFileUpload" (change)="global.initializeUpload($event)"  accept="images/*"  style="display: none;"  />
            // input type='file' mention in app.component.html page
            const fileUploadClickEvent = new MouseEvent('click', {});
            if (allowedFileTypes) {
                this.globalFileUploadInput.nativeElement.setAttribute('accept', allowedFileTypes);
            }
            this.globalFileUploadInput.nativeElement.dispatchEvent(fileUploadClickEvent);
        }
    }
    initializeUpload(e) {
        const file = {...e.target.files[0], type: e.target.files[0].type, name: encodeURI(e.target.files[0].name)};
        this.sendAjaxRequest('upload/presignedUrl', {
            filename: file.name,
            fileType: file.type,
            destination: this.FILEOF.dest,
        }).subscribe(async (response) => {
            if (response.message === 'success') {
                this.showLoading();
                const res = await fetch(response.uploadUrl, {
                    method: 'PUT',
                    body: e.target.files[0],
                    headers: {
                        'Content-Type': file.type,
                    },
                });
                const uploadEvent = {
                    fileDest:
                        'account-' +
                        (this.FILEOF.IsKycDocument ? this.loggedInUser.userId : this.loggedInUser.companyId) +
                        '/',
                    originalNameWithExt: file.name,
                    fileUrl: response.url,
                    fileNameWithExt: response.fileName,
                    downloadUrl: response.downloadUrl,
                };
                this.hideLoading();
                this.eventBus.broadcastEvent(this.FILEOF.event, [uploadEvent]);
                $('#globalFileUpload').val('');
            }
        });
    }

    // uploadFile = function(event) {
    //     const data = new FormData();
    //     const files = event.target.files;
    //     // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    //     console.log('FILE: ' + JSON.stringify(files,null,2));

    //     // $.each(files, function(key, value) {
    //     //     data.append('file', value);
    //     // });
    //     // data.append('dest', this.FILEOF.dest);
    //     // data.append('fileUpload', this.FILEOF.fileUpload);
    //     // data.append('thumbDest', this.FILEOF.thumbDest);
    //     // data.append('createThumb', this.FILEOF.createThumb);
    //     // data.append('newFileName', this.FILEOF.newFileName);
    //     // data.append('isImage', this.FILEOF.isImage);
    //     // data.append('videoUpload', this.FILEOF.videoUpload);
    //     // if (this.FILEOF.uploadToS3 === false) data.append('uploadToS3', this.FILEOF.uploadToS3);

    //     const tempGlobal = this;
    //     // const tempAccessToken = this.getLocalStorage('tenantpayToken');

    //     tempGlobal.sendAjaxRequest('upload/presignedUrl', {}).subscribe(async (response) => {
    //         if(response.message === 'success') {
    //             const res = await fetch(response.url, {
    //                 method: 'PUT',
    //                 body: files[0],
    //             });
    //             console.log(JSON.stringify(res, null, 2));
    //             tempGlobal.hideLoading();
    //             tempGlobal.eventBus.broadcastEvent(tempGlobal.FILEOF.event, 'success');
    //             $('#globalFileUpload').val('');

    //             $.ajax({
    //                 // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    //                 xhr() {
    //                     const xhr = new XMLHttpRequest();
    //                     return xhr;
    //                 },
    //                 url: this.API_URL + '/media/uploadFile',
    //                 type: 'POST',
    //                 data,
    //                 cache: false,
    //                 dataType: 'json',
    //                 headers: {
    //                     Authorization: 'Bearer ' //,+ tempAccessToken,
    //                 },
    //                 processData: false, // Don't process the files
    //                 contentType: false, // Set content type to false as jQuery will tell the server its a query string request
    //                 // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    //                 success(returnData: any) {
    //                     tempGlobal.hideLoading();
    //                     tempGlobal.eventBus.broadcastEvent(tempGlobal.FILEOF.event, returnData);
    //                     $('#globalFileUpload').val('');
    //                 },
    //             });
    //         }
    //     });

    // };

    async mobileUploadMenu() {
        const image = await Camera.getPhoto({
            quality: 30,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source: CameraSource.Prompt, // Camera, Photos or Prompt!
        });

        if (image) {
            const response = await fetch(image.webPath);
            const blob = await response.blob();
            this.startUpload(blob);
        }
    }
    async startUpload(imageBlob) {
        const formData = new FormData();
        formData.append('file', imageBlob);
        formData.append('dest', this.FILEOF.dest);
        formData.append('fileUpload', this.FILEOF.fileUpload);
        formData.append('thumbDest', this.FILEOF.thumbDest);
        formData.append('createThumb', this.FILEOF.createThumb);
        formData.append('newFileName', this.FILEOF.newFileName);
        formData.append('isImage', this.FILEOF.isImage);
        formData.append('videoUpload', this.FILEOF.videoUpload);
        if (this.FILEOF.uploadToS3 === false) formData.append('uploadToS3', this.FILEOF.uploadToS3);

        this.uploadData(formData);
    }
    async uploadData(formData: FormData) {
        this.showLoading();
        const url = this.API_URL + '/uploadFile';
        this.http
            .post(url, formData, this.httpReqOptions)
            .pipe(
                finalize(() => {
                    this.hideLoading();
                })
            )
            .subscribe((uploadResponse) => {
                const response = uploadResponse;
                this.eventBus.broadcastEvent(this.FILEOF.event, response);
                this.hideLoading();
            });
    }
    onFail(message) {
        alert('Failed because: ' + message);
    }

    /*************************Validation Functions*************************** */
    isFormValid(formToCheck, showAlert = true) {
        let isValid = true;
        let firstInvalidElementId = '';
        formToCheck['validatedOnce'] = true;
        let allControlsAreValid = true;

        // eslint-disable-next-line guard-for-in
        for (const controlName in formToCheck.controls) {
            const control = formToCheck.controls[controlName];
            if (!control.valid && control.status !== 'DISABLED') {
                if (isValid) {
                    if (document.querySelector('#' + controlName) != null) {
                        firstInvalidElementId = controlName;
                    }
                }
                control.updateValueAndValidity();
                control.markAsTouched();
                isValid = false;
            }

            if (!isValid) {
                // in case if errors do not include required error and value is empty then it should be considered as valid
                if (
                    control.errors != null &&
                    !control.errors.hasOwnProperty('required') &&
                    !control.errors.hasOwnProperty('myRequired') &&
                    (control.value === '' || control.value == null)
                ) {
                    isValid = true;
                }
            }

            // even if one invalid element found then is should return invalid message
            if (isValid === false) {
                allControlsAreValid = false;
            }
        }

        if (!allControlsAreValid && showAlert) {
            this.basicAlert('', 'errors.please_fill_all_required_fields', true);
        }

        return allControlsAreValid;
    }
    isFieldInvalid(field: any) {
        if (typeof field === 'undefined' || field == null || field === '') {
            return false;
        } else {
            return field.errors != null && (field.dirty || field.touched);
        }
    }
    getFieldValidationErrorMessage(field: any) {
        if (field.errors != null) {
            if (field.name === 'notificationEmail') {
                if (field.errors.required) {
                    return this.translate.instant('errors.required');
                } else {
                    return this.translate.instant('errors.email_invalid');
                }
            }
            if (field.errors.required) {
                return this.translate.instant('errors.required');
            } else if (field.errors.email) {
                return this.translate.instant('errors.email_invalid');
            } else if (field.errors.phone) {
                return this.translate.instant('errors.phone_invalid');
            } else if (field.errors.number) {
                return this.translate.instant('errors.enter_valid_number');
            } else {
                return this.translate.instant('errors.required');
            }
        }
    }

    processAdress(unProcessedAddress) {
        const address = {};
        unProcessedAddress.forEach((element) => {
            switch (element['types'][0]) {
                case 'street_number':
                    address['buildingNumber'] = element['long_name'];
                    break;

                case 'route':
                    address['streetName'] = element['long_name'];
                    break;

                case 'administrative_area_level_1':
                    address['province'] = element['long_name'];
                    address['shortProvinceCode'] = element['short_name'];
                    break;

                case 'sublocality_level_1':
                case 'locality':
                    address['city'] = element['long_name'];
                    break;

                case 'country':
                    address['country'] = element['long_name'];
                    address['shortCountryCode'] = element['short_name'];
                    break;

                case 'postal_code':
                    address['postalCode'] = element['long_name'];
                    break;
            }
        });

        return address;
    }

    removeFromStorage(key) {
        try {
            return localStorage.removeItem(key);
        } catch (error) {
            return '';
        }
    }
    getLocalStorage(key) {
        try {
            const returnValue = localStorage[key];
            if (typeof returnValue === 'undefined' || returnValue === 'undefined') {
                return null;
            } else {
                return localStorage[key];
            }
        } catch (error) {
            return null;
        }
    }
    setLocalStorage(key, value) {
        try {
            localStorage[key] = value;
        } catch (error) {
            return false;
        }
    }
    isEmptyObject(obj) {
        if (obj === 0 || obj == null || typeof obj === 'undefined') {
            return true;
        }
        return Object.keys(obj).length === 0;
    }
    isValidEmail(value) {
        var EMAIL_REGEXP =
            /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

        if (!EMAIL_REGEXP.test(value)) return false;
        else return true;
    }
    async launchExternal(link, outside = true) {
        let newWin = null;
        if (this.isMobileApplication) {
            if (outside) window.open(link, '_system');
            else {
                const openCapacitorSite = async () => {
                    await Browser.open({url: link});
                };
            }
        } else {
            newWin = window.open(link, '_blank');
        }
    }
    isValidPhoneNumber(value) {
        const PHONE_REHEXP = /^\+?1?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/i;

        if (!PHONE_REHEXP.test(value)) return false;
        else return true;
    }

    currencyFormatter(currency, sign) {
        const sansDec = currency.toFixed(2);
        const formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return sign + `${formatted}`;
    }
}
