import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

/**
 * Custom Angular Pipe for translating values using ngx-translate.
 * This pipe can be used to translate strings or objects with translation keys.
 * This pipe is specifically created to be used for translating the params passed
 * in the translate pipe.
 *
 * Usage:
 *
 * ```html
 * <div>
 *  {{
 *      'error.required' | translate: {
 *          property: 'label.first_name' | paramsTranslate
 *      }
 *  }}
 * </div>
 * ```
 *
 * The above example will translate the `label.first_name` to `First name`
 * which then will be passed as a param to translate pipe.
 */
@Pipe({name: 'paramsTranslate', pure: false})
export class ParamsTranslatePipe implements PipeTransform {
    /** constructor */
    constructor(private translator: TranslateService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Transforms a value or an object of values by translating them.
     *
     * @param value - The value to be translated, which can be a string or an object.
     * @returns The translated value or object.
     */
    transform(value: string | Record<string, unknown>) {
        if (typeof value === 'string') {
            return this.translator.instant(value);
        }

        const newObj: Record<string, unknown> = {};
        Object.entries(value).forEach(([key, val]) => {
            newObj[key] = typeof val === 'string' ? this.translator.instant(val) : val;
        });

        return newObj;
    }
}
