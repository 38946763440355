<div class="h-16 bg-[#2151EC] text-white flex items-center justify-between px-1 pr-4">
    <ion-menu-button class="h-10 w-10">
        <svg class="h-full">
            <use xlink:href="/assets/icon/sprite.svg#icon-menu"></use>
        </svg>
    </ion-menu-button>

    <img class="h-6" src="/assets/logos/tenantpay-white.png" />

    <img
        [src]="userProfileImg"
        class="w-8 h-8 border border-solid border-[#5C82FF] rounded-[50%] object-cover cursor-pointer"
        (error)="userProfileImg = this.DEFAULT_PROFILE"
        alt="user profile image"
        (click)="global.pushPage('profile')" />
</div>
