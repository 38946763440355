/* eslint-disable no-underscore-dangle */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
declare var _API_URL: any;
declare var _IS_MOBILE_APPLICAION: any;
declare var _SITE_RUNNING;

export let IS_MOBILE_APPLICAION;
export let API_URL;
export let SITE_RUNNING;
export let APP_URL;
/****************************************End of Mobile application constant variables*******************************************/

// this function to getbaseurl
// e.g. http://localhost/
let url;
const l = window.location;
const pathArray = l.pathname.split('/');

IS_MOBILE_APPLICAION = false;
if (_IS_MOBILE_APPLICAION) {
    // if protocol is file: then its running on mobile
    IS_MOBILE_APPLICAION = true;
    SITE_RUNNING = _SITE_RUNNING;
    API_URL = _API_URL;
} else if (l.hostname === 'staging-manage.tenantpay.com') {
    API_URL = 'https://staging-api.tenantpay.com/api/v2';
    SITE_RUNNING = 'developer';
    url = l.protocol + '//' + l.host;
    APP_URL = '';
} else if (l.hostname === 'manage.tenantpay.com') {
    API_URL = 'https://api.tenantpay.com/api/v2';
    SITE_RUNNING = 'live';
    url = l.protocol + '//' + l.host;
    APP_URL = 'https://manage.tenantpay.com';
} else {
    // to hit local BE
    SITE_RUNNING = 'localhost';
    API_URL = 'http://localhost:3000/api/v2';
    APP_URL = 'http://localhost:3100';
    // API_URL = 'http://staging.tenantpay.com/api/public';
    // SITE_RUNNING = 'developer';
    // url = l.protocol + '//' + l.host;
    // APP_URL = '';
}
