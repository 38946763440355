import {Injectable} from '@angular/core';
import {loadSpace, loadEmbed} from '@usersnap/browser';

@Injectable({
    providedIn: 'root', // Provide the service at the root level
})
export class UsersnapService {
    private usersnapApi: any | null = null;

    constructor() {}

    // Initialize Usersnap with Space API
    initializeWithSpace(spaceApiKey: string, initParams: object = {}): Promise<any> {
        return loadSpace(spaceApiKey).then((api) => {
            api.init(initParams);
            this.usersnapApi = api;
            return api;
        });
    }
}
