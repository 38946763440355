import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {LoaderService} from '../../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    /** constructor */
    constructor(public loaderService: LoaderService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * loader interceptor which triggers loader show/hide on every ongoing node-api request
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this condition will make sure not to show loader on php apis
        if (req.url.includes('tenantpay/api') || !req.url.includes('api')) {
            return next.handle(req);
        }

        // show the loader before sending request
        if (!this.isExceptionUrl(req.url)) {
            this.loaderService.show();
        }
        return next.handle(req).pipe(
            finalize(() => {
                // hide the loader once the request is completed
                this.loaderService.hide();
            })
        );
    }

    isExceptionUrl(url: string) {
        const dashboardApiExp = new RegExp(/(\/dashboard\/manager\/)|(\/kpi\/manager\/)|(\/transaction\/manager\/)/);
        return dashboardApiExp.test(url), url;
    }
}
