import {Inject, Injectable} from '@angular/core';
import {ShepherdService} from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import {GlobalService} from 'src/app/services/global.service';
import {API_URL} from 'src/app/pathConfig';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
const STEPS_BUTTONS = {
    back: {
        classes: 'custom-back-button',
        text: 'Back',
        type: 'back',
    },
    next: {
        classes: 'custom-next-button',
        text: 'Next',
        type: 'next',
    },
};

interface StatusResponse {
    data: {
        id: any;
        timestampUpdated: any;
        type: string;
        value: any;
    };
    timestamp: string;
}

@Injectable({
    providedIn: 'root',
})
export class TourService {
    constructor(
        @Inject(ShepherdService) private shepherdService: ShepherdService,
        private globalService: GlobalService,
        private readonly http: HttpClient
    ) {}
    exitButton = {
        text: 'Finish Tour',
        classes: 'custom-cancel-button',
        secondary: true,
        action: () => {
            this.destroyTour();
            this.globalService.pushPage('dashboard');
        },
    };
    anytourActive = false;
    private readonly baseUrl = `${API_URL}`;
    isTourCompleted(): boolean {
        return localStorage.getItem(this.globalService.APPTOUR_COMPLETED_KEY) === 'true';
    }

    markTourCompleted() {
        localStorage.setItem(this.globalService.APPTOUR_COMPLETED_KEY, 'true');
    }
    getStepStatus(value) {
        return this.http
            .get<StatusResponse>(`${this.baseUrl}/user-settings/${value}`)
            .pipe(map((response) => response.data.value));
    }
    tourDestroyed = 'notDone';
    isTourDestroyed(): Observable<boolean> {
        return this.getStepStatus('webManagerJoyrideDashboardNav').pipe(
            map((response) => {
                this.tourDestroyed = response;
                return this.tourDestroyed === 'done';
            })
        );
    }
    startTour(steps) {
        if (this.isTourCompleted()) {
            return;
        }
        this.anytourActive = true;
        const defaultOptions = {
            classes: 'custom-default-class',
            scrollTo: true,
            cancelIcon: {
                enabled: false,
            },
        };

        this.shepherdService.defaultStepOptions = defaultOptions;
        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;

        this.shepherdService.addSteps(steps);
        this.shepherdService.start();
    }
    addToCurrentTour(steps) {
        this.shepherdService.addSteps(steps);
        this.shepherdService.next();
    }
    hideSteps() {
        if (this.shepherdService.tourObject != null) {
            this.shepherdService.hide();
        }
    }
    completeTour() {
        this.anytourActive = false;
        this.shepherdService.complete();
    }

    setStatusComplete(key) {
        const type = key;
        const value = 'done';
        this.http.post(`${this.baseUrl}/user-settings`, {type, value}).subscribe();
    }

    destroyTour() {
        this.setStatusComplete('webManagerJoyrideDashboardNav');
        this.anytourActive = false;
        this.shepherdService.complete();
        this.markTourCompleted();
    }
    showById(id) {
        this.shepherdService.tourObject?.show(id);
    }
    getWelcomeSteps() {
        return [
            {
                buttons: [
                    this.exitButton,
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.openSideMenu();
                            setTimeout(() => {
                                this.shepherdService.next();
                            }, 100);
                        },
                    },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                id: 'welcome',
                title: 'Welcome to Tenant Pay.',
                text: ' Welcome to Tenant Pay! Let\'s kick off your Tenant Pay experience. Click "Next" to begin your journey.',
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(5)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    STEPS_BUTTONS.back,
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.pushPage('bank-accounts');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('add bank account');
                            }, 200);
                        },
                    },
                ],
                classes: 'custom-class-name-1',
                id: 'choose-bank-account',
                title: 'Start with Bank Accounts. <span class="step-number">1 of 12</span>',
                text: 'Facilitate your financial operations seamlessly by adding new bank accounts directly from here.',
            },
            {
                attachTo: {
                    element: '.addbank',
                    on: 'bottom-start',
                },
                buttons: [this.exitButton, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
                classes: 'custom-class-name-2',
                id: 'add bank account',
                title: 'Add Bank Account <span class="step-number">2 of 12</span>',
                text: 'Optimize rent collection processes by designating a specific bank account to receive payments. Tailor this to your financial preferences.',
                modalOverlayOpeningRadius: 7,
            },
            {
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.globalService.pushPage('bank-accounts');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('add bank account');
                            }, 200);
                        },
                    },
                    STEPS_BUTTONS.next,
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                id: 'wait',
                title: 'Wait for Approval <span class="step-number">3 of 12</span>',
                text: 'After adding a bank account, just hang tight while our team carefully reviews and approves to ensure everything is compliant and secure.',
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(3)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('wait');
                            }, 200);
                        },
                    },
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.pushPage('buildings');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('add building');
                            }, 200);
                        },
                    },
                ],
                id: 'choose-building',
                title: 'Go to Buildings <span class="step-number">4 of 12</span>',
                text: 'Propel your real estate endeavors by adding new buildings. Prior to this, ensure that your designated bank account has received the necessary approval.',
            },
            {
                attachTo: {
                    element: '.addBuilding',
                    on: 'bottom-start',
                },
                buttons: [this.exitButton, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
                classes: 'custom-class-name-1 custom-class-name-2',
                id: 'add building',
                title: 'Add Building <span class="step-number">5 of 12</span>',
                text: 'Streamline rent collection for each building by associating it with an approved bank account. This guarantees a frictionless financial workflow.',
                modalOverlayOpeningRadius: 7,
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(4)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.globalService.pushPage('buildings');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('add building');
                            }, 200);
                        },
                    },
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.pushPage('units');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('addUnit');
                            }, 200);
                        },
                    },
                ],
                id: 'choose-units',
                title: 'Go to Units <span class="step-number">6 of 12</span>',
                text: 'Manage units effortlessly right from this platform, where each building can house multiple units.',
            },
            {
                attachTo: {
                    element: '.addUnit',
                    on: 'bottom-start',
                },
                buttons: [this.exitButton, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
                classes: 'custom-class-name-1 custom-class-name-2',
                id: 'addUnit',
                title: 'Add Unit <span class="step-number">7 of 12</span>',
                text: 'Add your rental units for each building right here. It’s a straightforward process that you can complete with just a few clicks.',
                modalOverlayOpeningRadius: 7,
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(2)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.globalService.pushPage('units');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('addUnit');
                            }, 200);
                        },
                    },
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.pushPage('contacts/residents');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('addResident');
                            }, 200);
                        },
                    },
                ],
                id: 'choose-residents',
                title: 'Go to Residents<span class="step-number">8 of 12</span>',
                text: 'Streamline resident management by navigating to this section, where you can conveniently view information on both current and previous tenants.',
            },
            {
                attachTo: {
                    element: '.addResident',
                    on: 'bottom-start',
                },
                buttons: [this.exitButton, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
                classes: 'custom-class-name-1 custom-class-name-2',
                id: 'addResident',
                title: 'Add Resident<span class="step-number">9 of 12</span>',
                text: 'Add new residents effortlessly by clicking this button. For enhanced data analysis, please include lease duration and rent amount for each resident, enabling us to provide you with more insightful statistics.',
                modalOverlayOpeningRadius: 7,
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(10)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.globalService.pushPage('contacts/residents');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('addResident');
                            }, 200);
                        },
                    },
                    STEPS_BUTTONS.next,
                ],
                id: 'choose-manager',
                title: 'Managers Page<span class="step-number">10 of 12</span>',
                text: 'Strengthen your property management team by adding key members here. You can assign roles to each team member to control their access.',
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(6)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    STEPS_BUTTONS.back,
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.pushPage('dashboard');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('choose-home');
                            }, 200);
                        },
                    },
                ],
                id: 'choose-transactions',
                title: 'Transactions Page <span class="step-number">11 of 12</span>',
                text: 'Maintain a comprehensive overview of all financial transactions by reviewing the transaction log within the platform.',
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(1)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('choose-transactions');
                            }, 200);
                        },
                    },
                ],
                id: 'choose-home',
                title: 'Dashboard Page <span class="step-number">12 of 12</span>',
                text: 'Harness the power of data with the interactive summary, providing in-depth insights into the performance and details of all your properties.',
            },
        ];
    }
    getWelcomeStepsManager() {
        return [
            {
                buttons: [
                    this.exitButton,
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.openSideMenu();
                            setTimeout(() => {
                                this.shepherdService.next();
                            }, 100);
                        },
                    },
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                id: 'welcome',
                title: 'Welcome to Tenant Pay.',
                text: ' Welcome to Tenant Pay! Let\'s kick off your Tenant Pay experience. Click "Next" to begin your journey.',
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(5)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    STEPS_BUTTONS.back,
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.pushPage('bank-accounts');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('add bank account');
                            }, 200);
                        },
                    },
                ],
                classes: 'custom-class-name-1',
                id: 'choose-bank-account',
                title: 'Start with Bank Accounts. <span class="step-number">1 of 11</span>',
                text: 'Facilitate your financial operations seamlessly by adding new bank accounts directly from here.',
            },
            {
                attachTo: {
                    element: '.addbank',
                    on: 'bottom-start',
                },
                buttons: [this.exitButton, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
                classes: 'custom-class-name-2',
                id: 'add bank account',
                title: 'Add Bank Account <span class="step-number">2 of 11</span>',
                text: 'Optimize rent collection processes by designating a specific bank account to receive payments. Tailor this to your financial preferences.',
                modalOverlayOpeningRadius: 7,
            },
            {
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.globalService.pushPage('bank-accounts');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('add bank account');
                            }, 200);
                        },
                    },
                    STEPS_BUTTONS.next,
                ],
                classes: 'custom-class-name-1 custom-class-name-2',
                id: 'wait',
                title: 'Wait for Approval <span class="step-number">3 of 11</span>',
                text: 'After adding a bank account, just hang tight while our team carefully reviews and approves to ensure everything is compliant and secure.',
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(3)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('wait');
                            }, 200);
                        },
                    },
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.pushPage('buildings');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('add building');
                            }, 200);
                        },
                    },
                ],
                id: 'choose-building',
                title: 'Go to Buildings <span class="step-number">4 of 11</span>',
                text: 'Propel your real estate endeavors by adding new buildings. Prior to this, ensure that your designated bank account has received the necessary approval.',
            },
            {
                attachTo: {
                    element: '.addBuilding',
                    on: 'bottom-start',
                },
                buttons: [this.exitButton, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
                classes: 'custom-class-name-1 custom-class-name-2',
                id: 'add building',
                title: 'Add Building <span class="step-number">5 of 11</span>',
                text: 'Streamline rent collection for each building by associating it with an approved bank account. This guarantees a frictionless financial workflow.',
                modalOverlayOpeningRadius: 7,
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(4)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.globalService.pushPage('buildings');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('add building');
                            }, 200);
                        },
                    },
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.pushPage('units');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('addUnit');
                            }, 200);
                        },
                    },
                ],
                id: 'choose-units',
                title: 'Go to Units <span class="step-number">6 of 11</span>',
                text: 'Manage units effortlessly right from this platform, where each building can house multiple units.',
            },
            {
                attachTo: {
                    element: '.addUnit',
                    on: 'bottom-start',
                },
                buttons: [this.exitButton, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
                classes: 'custom-class-name-1 custom-class-name-2',
                id: 'addUnit',
                title: 'Add Unit <span class="step-number">7 of 11</span>',
                text: 'Add your rental units for each building right here. It’s a straightforward process that you can complete with just a few clicks.',
                modalOverlayOpeningRadius: 7,
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(2)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.globalService.pushPage('units');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('addUnit');
                            }, 200);
                        },
                    },
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.pushPage('contacts/residents');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('addResident');
                            }, 200);
                        },
                    },
                ],
                id: 'choose-residents',
                title: 'Go to Residents<span class="step-number">8 of 11</span>',
                text: 'Streamline resident management by navigating to this section, where you can conveniently view information on both current and previous tenants.',
            },
            {
                attachTo: {
                    element: '.addResident',
                    on: 'bottom-start',
                },
                buttons: [this.exitButton, STEPS_BUTTONS.back, STEPS_BUTTONS.next],
                classes: 'custom-class-name-1 custom-class-name-2',
                id: 'addResident',
                title: 'Add Resident<span class="step-number">9 of 11</span>',
                text: 'Add new residents effortlessly by clicking this button. For enhanced data analysis, please include lease duration and rent amount for each resident, enabling us to provide you with more insightful statistics.',
                modalOverlayOpeningRadius: 7,
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(6)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.globalService.pushPage('contacts/residents');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('addResident');
                            }, 200);
                        },
                    },
                    {
                        text: 'Next',
                        classes: 'custom-next-button',
                        action: () => {
                            this.globalService.pushPage('dashboard');
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('choose-home');
                            }, 200);
                        },
                    },
                ],
                id: 'choose-transactions',
                title: 'Transactions Page <span class="step-number">10 of 11</span>',
                text: 'Maintain a comprehensive overview of all financial transactions by reviewing the transaction log within the platform.',
            },
            {
                attachTo: {
                    element: 'app-nav-item:nth-child(1)',
                    on: 'right',
                },
                buttons: [
                    this.exitButton,
                    {
                        text: 'Back',
                        classes: 'custom-back-button',
                        action: () => {
                            this.shepherdService.hide();
                            setTimeout(() => {
                                this.showById('choose-transactions');
                            }, 200);
                        },
                    },
                ],
                id: 'choose-home',
                title: 'Dashboard Page <span class="step-number">11 of 11</span>',
                text: 'Harness the power of data with the interactive summary, providing in-depth insights into the performance and details of all your properties.',
            },
        ];
    }
}
