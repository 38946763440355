<div class="comobo-box">
    <div *ngIf="multiChoice">
        <ion-row class="selected-option" *ngFor="let item of selectedItems">
            <ion-col >
                <div class="inline-title">{{ item[listObject['title']] }}</div>
            </ion-col>
            <ion-col class="close-col ion-text-center" (click)="removeItem(item)">
                <ion-icon color="danger" name="close-sharp"></ion-icon>
            </ion-col>
        </ion-row>
    </div>
    <ion-row *ngIf="selectedItems && (selectedItems.length === 0 || multiChoice)">
        <ion-col class="ion-no-padding" *ngIf="!fetchingValue">
            <ion-input *ngIf="ajaxOptions" type="text" class="ion-text-capitalize" (ionFocus)="loadAjaxOptions(null);showOptions = true" (ionBlur)="resetOption()" [(ngModel)]="filterObject.searchQuery" (keyup)="loadAjaxOptions(null)" [placeholder]="searchBarPlaceHolder"></ion-input>
            <ion-input *ngIf="!ajaxOptions" type="text" class="ion-text-capitalize" (ionFocus)="showOptions = true" (ionBlur)="resetOption()" [(ngModel)]="filterObject.searchQuery" [placeholder]="searchBarPlaceHolder"></ion-input>
        </ion-col>
        <ion-col *ngIf="fetchingValue" class="ion-no-padding">
            <ion-progress-bar type="indeterminate"></ion-progress-bar>
        </ion-col>
        <ion-col *ngIf="comboBox" size="auto" class="ion-text-center add-btn" (click)="addNewItem()">
            {{btnTitle | translate}}
        </ion-col>
    </ion-row>
    <div *ngIf="!multiChoice">
        <ion-row class="selected-option" [ngClass]="{'disabled-input':disabled }" *ngFor="let item of selectedItems">
            <ion-col >
                <div class="inline-title">{{ item[listObject['title']] }}</div>
            </ion-col>
            <ion-col *ngIf="!disabled" class="close-col ion-text-center" (click)="clear()">
                <ion-icon color="danger" name="close-sharp"></ion-icon>
            </ion-col>
        </ion-row>
    </div>


    <div class="combobox-opt" *ngIf="showOptions && (selectedItems.length === 0 || multiChoice)">
        <ion-progress-bar *ngIf="searchingInProgress" type="indeterminate"></ion-progress-bar>
        <ion-row *ngIf="!searchingInProgress && listObject['list'].length === 0">
            <ion-col class="ion-text-center">
                {{'label.no_results' | translate}}
            </ion-col>
        </ion-row>
        <ion-row *ngFor="let item of listObject['list']" (click)="actionOnItem(item)">
            <ion-col *ngIf="translateTitle">
                {{ item[listObject['title']] | translate }}
            </ion-col>
            <ion-col *ngIf="!translateTitle" [innerHTML]="item[listObject['title']]"></ion-col>
            <!-- <ion-radio slot="start " [disabled]="item.disabled " [value]="item[listObject.value] "></ion-radio> -->
        </ion-row>
        <ion-infinite-scroll threshold="50% " (ionInfinite)="loadAjaxOptions($event) ">
            <ion-infinite-scroll-content loadingText="{{ 'label.loading_more_data'|translate }} "></ion-infinite-scroll-content>
        </ion-infinite-scroll>

        <!-- <ng-container *ngIf="!ajaxOptions ">
            <div *ngIf="isListArray ">
                <ion-row *ngFor="let item of listObject.list | StringFilterPipe:filterIndex:filterValue " (click)="actionOnItem(item) ">
                    <div *ngIf="translateTitle "> {{ item[listObject['title']] | translate }}</div>
                    <div *ngIf="!translateTitle " [innerHTML]="item[listObject['title']]"></div>
                </ion-row>
            </div>
            <div *ngIf="!isListArray ">
                <ion-row *ngFor="let item of listObject.list | ObjectKeysPipe | ObjectStringFilterPipe:filterIndex:filterValue " (click)="actionOnItem(item.value) ">
                    <div *ngIf="translateTitle "> {{ item.value[listObject['title']] | translate }}</div>
                    <div *ngIf="!translateTitle " [innerHTML]='item.value[listObject['title']]'></div>
                </ion-row>
            </div>
        </ng-container>
        <ng-container *ngIf="ajaxOptions && listObject[list] ">
            <ion-row *ngFor="let item of listObject[ 'list'] " (click)="actionOnItem(item) ">
                <ion-col *ngIf="translateTitle ">
                    {{ item[listObject['title']] | translate }}
                </ion-col>
                <ion-col *ngIf="!translateTitle " [innerHTML]="item[listObject['title']]"></ion-col>
            </ion-row>
            <ion-infinite-scroll threshold="50% " (ionInfinite)="loadAjaxOptions($event) ">
                <ion-infinite-scroll-content loadingText="{{ 'label.loading_more_data'|translate }} "></ion-infinite-scroll-content>
            </ion-infinite-scroll>
        </ng-container> -->
    </div>
</div>