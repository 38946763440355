import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {NavItemComponent} from './nav-item';
import {SidebarComponent} from './sidebar.component';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
    declarations: [SidebarComponent, NavItemComponent],
    imports: [IonicModule, FontAwesomeModule, CommonModule, MatMenuModule],
    exports: [SidebarComponent],
})
export class SidebarModule {}
