<!-- <ion-searchbar [(ngModel)]="searchbarValue" (ionInput)="onChange()" (ionBlur)="onBlurSearch()" #searchbar></ion-searchbar> -->
<ion-item *ngIf="type === 'ion-item'" input lines="none" class="search-ion-item">
    <ion-icon icon-small color="primary" [name]="searchIcon"></ion-icon>
    <ion-input type="text" [(ngModel)]="searchbarValue" (keyup)="onChange()" [placeholder]="placeHolder"></ion-input>
</ion-item>

<ion-input *ngIf="type === 'input'" type="text" [(ngModel)]="searchbarValue" (keyup)="onChange()" [placeholder]="placeHolder"></ion-input>


<ion-list *ngIf="autocompleteItems.length > 0">
    <ion-item *ngFor="let item of autocompleteItems" (click)="chooseItem(item)">
        {{ item.description }}
    </ion-item>
</ion-list>
<div #locationSearchMap id="locationSearchMap"></div>