<div class="max-width">
<h1 class="flex justify-center application-title" style="font-weight: 700 !important;">Carefully Review your info</h1>
<app-business-info-step [disableForm]="true" ></app-business-info-step>
<app-company-metadata-step [disableForm]="true"  ></app-company-metadata-step>
<app-kyc-stakeholder *ngIf="companyTypeId !== '1'" [disableAddBtn]="true" ></app-kyc-stakeholder>
<ion-row class="mt-3 ">
    <ion-col size="12" class="ml-6">
        <mat-checkbox #legalCapacityAppliedMatCheckbox
            (change)="trackTermsAndConditionsCheckboxClick('informationVerfied')" [color]="'primary'"
            [value]="'informationVerfied'" [checked]="isInformationVerifiedChecked" [required]="true">
            <div style="white-space: normal;">
                I hereby certify that the information provided is correct to the best of my knowledge.
            </div>
        </mat-checkbox>
    </ion-col>
    <ion-col size="12" *ngIf="companyTypeId!=='1'" class="ml-6 mb-2 pr-3">
        <mat-checkbox #isInformationVerifiedCheckedMatCheckbox
            (change)="trackTermsAndConditionsCheckboxClick('legalCapacityApplied')" [value]="'legalCapacity'"
            [checked]="legalCapacityApplied" [color]="'primary'" [required]="true"
            >
            <div style="white-space: normal;">
                I certify that I have the legal capacity or authorization to open an account in the name of
                the entity subject to the present process.
            </div>
        </mat-checkbox>
    </ion-col>
    <ion-col size="12" class="ml-6" *ngIf="triggerTermsAndConditionError">
        <mat-error>*Please check the terms and conditions before proceeding.*</mat-error>
    </ion-col>
</ion-row>
</div>