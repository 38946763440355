import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Tell Angular2 we're creating a Pipe with TypeScript decorators
@Pipe({
	name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

	constructor(private sanitized: DomSanitizer) { }
	// Transform is the new "return function(value, args)" in Angular 1.x
	transform(value) {
		// console.log(obj);
		return this.sanitized.bypassSecurityTrustHtml(value);
	}
}


@Pipe({ name: 'safeURL' })

export class SafeUrlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) { }
	transform(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
