import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { GeneralPopover } from './general-popover';
import { ComponentsModule } from '../components.module';
import { PipesModule } from 'src/app/pipes/pipe.module';

@NgModule({
    declarations: [
        GeneralPopover
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ComponentsModule,
        ReactiveFormsModule,
        PipesModule
    ]
})
export class GeneralPopoverModule { }
