import {Component, OnInit, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, Validators, UntypedFormGroup} from '@angular/forms';
import {GlobalService} from '../../services/global.service';
import {MenuController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './resetPassword.page.html',
    styleUrls: ['./resetPassword.page.scss'],
})
export class ResetPasswordPage {
    public resetPasswordData = {};
    submitAttempt = false;
    resetPasswordForm: UntypedFormGroup;
    tenantLogin = false;
    showValidationMessages = false;
    hideTimeout: any;
    constructor(
        public global: GlobalService,
        public route: ActivatedRoute,
        public router: Router,
        private formBuilder: UntypedFormBuilder,
        private menuCtrl: MenuController
    ) {
        this.resetPasswordForm = this.formBuilder.group({
            password: ['', Validators.pattern(/^(?=.*\d)[\w\W]{8,}$/)],
            password2: ['', Validators.pattern(/^(?=.*\d)[\w\W]{8,}$/)],
        });
    }
    ionViewWillEnter() {
        this.menuCtrl.enable(false);
    }
    onTouch = () => (this.submitAttempt = false);
    submitResetPasswordForm() {
        this.global.$('#resetPasswordBtn').focus();
        this.submitAttempt = true;
        if (
            this.resetPasswordForm.valid &&
            this.resetPasswordData['password'] === this.resetPasswordData['password2']
        ) {
            const hash = this.route.snapshot.paramMap.get('hash');
            this.global.showLoading();
            this.global
                .sendAjaxRequest('login/forgotPasswordReset', {
                    payload: {
                        password: this.resetPasswordData['password'],
                        hash,
                    },
                })
                .subscribe((response) => {
                    this.global.hideLoading();
                    this.submitAttempt = false;
                    if (response.message === 'successful') {
                        this.global.pushPage('login');
                    } else if (response.message === 'expired') {
                        this.global.basicAlert('', 'label.link_expired', true);
                    } else {
                        this.global.basicAlert('', 'label.enter_valid_credentials', true);
                    }
                });
        }
    }
    checkPasswordValidation() {
        const password = this.resetPasswordData['password'];
        this.global.isPasswordValid.minLength = password?.length >= 8;
        this.global.isPasswordValid.lowerCase = /[a-z]/.test(password);
        this.global.isPasswordValid.upperCase = /[A-Z]/.test(password);
        // eslint-disable-next-line id-blacklist
        this.global.isPasswordValid.number = /\d/.test(password);
        this.global.isPasswordValid.symbol = this.global.allowedSymbols.test(password);

        const allValid =
            this.global.isPasswordValid.minLength &&
            this.global.isPasswordValid.lowerCase &&
            this.global.isPasswordValid.upperCase &&
            this.global.isPasswordValid.number &&
            this.global.isPasswordValid.symbol;
        if (allValid) {
            clearTimeout(this.hideTimeout);
            this.hideTimeout = setTimeout(() => {
                this.showValidationMessages = false;
            }, 1000);
        } else {
            clearTimeout(this.hideTimeout);
            this.showValidationMessages = true;
        }
    }
}
