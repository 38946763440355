import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from 'src/app/components/components.module';
import {PipesModule} from 'src/app/pipes/pipe.module';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {DirectivesModule} from 'src/app/utils/directives';
import {StatHelpModal} from './stat-help.modal';

@NgModule({
    declarations: [StatHelpModal],
    imports: [CommonModule, TranslateModule, IonicModule, ComponentsModule, PipesModule, DirectivesModule],
})
export class StatHelpModalModule {}
