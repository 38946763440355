<top-menu title="label.account_information"></top-menu>

<ion-content *ngIf="kycStatus$ | async as kycStatus" class="light-background">
    <!-- 
        basic information card
          - account information
          - company meta data
     -->
    <ion-card class="card-v2">
        <ion-card-header>
            <ion-card-title>{{'label.basic_information' | translate}}</ion-card-title>
        </ion-card-header>

        <app-ai-info-form #infoForm></app-ai-info-form>

        <ion-footer *ngIf="kycStatus.status === KycStatus.INCOMPLETE">
            <ion-button class="btn__primary" (click)="infoForm.updateCompanyMetadata()"
                >{{ 'label.save' | translate }}</ion-button
            >
        </ion-footer>
    </ion-card>

    <!-- 
        company information card
     -->
    <ion-card *ngIf="(accountType$|async)  === kycType.COMPANY" class="card-v2">
        <ion-card-header>
            <ion-card-title>{{'label.company_details' | translate}}</ion-card-title>
        </ion-card-header>

        <app-ai-company-details #companyDetailsForm></app-ai-company-details>

        <ion-footer *ngIf="kycStatus.status === KycStatus.INCOMPLETE">
            <ion-button class="btn__primary" (click)="companyDetailsForm.updateCompanyInformation()"
                >{{ 'label.save' | translate }}</ion-button
            >
        </ion-footer>
    </ion-card>

    <!-- 
        individual user card
     -->
    <ion-card *ngIf="(accountType$|async)  === kycType.INDIVIDUAL" class="card-v2">
        <ion-card-header>
            <ion-card-title>{{'label.user_details' | translate}}</ion-card-title>
            <ion-button
                class="btn__primary ml-7 !text-xs btn-header"
                (click)="individualDetailsForm.copyProfileDetails()"
                >{{ 'label.i_am_owner' | translate }}</ion-button
            >
        </ion-card-header>

        <app-ai-user-details #individualDetailsForm></app-ai-user-details>

        <ion-footer *ngIf="kycStatus.status === KycStatus.INCOMPLETE">
            <ion-button class="btn__primary" (click)="individualDetailsForm.updateIndividualInformation()"
                >{{ 'label.save' | translate }}</ion-button
            >
        </ion-footer>
    </ion-card>

    <!-- 
        documents card
     -->
    <ion-card *ngIf="(accountType$|async)" class="card-v2">
        <ion-card-header>
            <ion-card-title>{{'label.documents' | translate}}</ion-card-title>
        </ion-card-header>

        <app-ai-documents></app-ai-documents>
    </ion-card>
</ion-content>
