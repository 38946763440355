<ion-content scroll="false">
    <div class="login">
        <!-- 
            forms which are conditionally rendered based on the current url
        -->
        <div [ngSwitch]="currentPage" class="login__container" style="overflow-y: auto">
            <!-- login form -->
            <app-login-form *ngSwitchCase="AuthPageOptions.LOGIN"></app-login-form>

            <!-- forgot password form -->
            <app-forgot-password *ngSwitchCase="AuthPageOptions.FORGOT_PASSWORD"></app-forgot-password>

            <!-- sign up page -->
            <app-sign-up-form
                *ngSwitchCase="AuthPageOptions.SIGN_UP"
                style="max-height: 95vh !important"></app-sign-up-form>
            <app-verify-otp
                *ngSwitchCase="AuthPageOptions.VERIFY_OTP"
                style="max-width: 475px !important"></app-verify-otp>
        </div>

        <!-- company related info -->
        <div class="login__container login__container--company-data">
            <app-login-portal-info></app-login-portal-info>
        </div>
    </div>
</ion-content>
