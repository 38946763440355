import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

import {AuthService} from '../../auth.service';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-login-form',
    templateUrl: './login.component.html',
    styleUrls: ['../forms-shared.scss'],
})
export class LoginFormComponent {
    /** user login form group */
    form = this._initForm();
    hide = true;
    /** constructor  */
    constructor(
        public readonly global: GlobalService,
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * handle user login flow.
     */
    login() {
        // check if the form is invalid.
        // mark all form fields as touched to trigger validation messages.
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.authService.login(this.form.value);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initializes and returns a form group for user login
     *
     * @returns FormGroup instance for user login
     */
    private _initForm() {
        return this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
        });
    }
}
