<!-- header with logo -->
<ion-header class="sidebar__header">
    <ion-toolbar class="sidebar__header-toolbar">
        <img src="/assets/logos/tenantpay-white.png" style="height: 1.5rem" />
    </ion-toolbar>
</ion-header>

<!-- 
    navigation list
        - main pages
        - other pages
 -->
<ion-content>
    <div
        *ngIf="showProfile"
        class="profile"
        [matMenuTriggerFor]="menu"
        (menuOpened)="showDropMenu = true"
        (menuClosed)="showDropMenu = false">
        <img
            [src]="userProfileImg"
            class="profile__image"
            (error)="userProfileImg = this.DEFAULT_PROFILE"
            alt="user profile image" />

        <!-- <div *ngIf="kycStatus$ | async" class="profile__details">-->
        <div class="profile__details">
            <span>
                {{ userName }}
            </span>

            <span class="profile__details--email">
                {{ userEmail }}
            </span>
        </div>

        <!--  <svg
            *ngIf="kycStatus$ | async"
            class="profile__icon"
            [ngStyle]="{transform: showDropMenu ? 'rotate(0)' : 'rotate(-180deg)'}">
            <use xlink:href="/assets/icon/sprite.svg#icon-chevron"></use>
        </svg> -->
        <svg class="profile__icon" [ngStyle]="{transform: showDropMenu ? 'rotate(0)' : 'rotate(-180deg)'}">
            <use xlink:href="/assets/icon/sprite.svg#icon-chevron"></use>
        </svg>
    </div>

    <!-- main pages list -->
    <ion-list class="sidebar__list" id="inbox-list">
        <!-- <ion-list-header>Inbox</ion-list-header>
             <ion-note>hi@ionicframework.com</ion-note> -->

        <!-- <ng-container *ngIf="kycStatus$ | async as kycStatus"> -->
        <ng-container>
            <!-- load all data if the kyc is completed -->
            <!-- ngIf check temporary changed to NOT(!) 
            <ng-container *ngIf="kycStatus.status !== KycStatus.APPROVED; else kycProgressPagesTemplate">
                <app-nav-item *ngFor="let page of appPages" [page]="page"></app-nav-item>
            </ng-container>-->

            <ng-container>
                <app-nav-item *ngFor="let page of appPages" [page]="page"></app-nav-item>
            </ng-container>
            <!-- load only kyc related nav items 
            <ng-template #kycProgressPagesTemplate>
                <app-nav-item [page]="gettingStartedPage"></app-nav-item>
                <hr class="my-4 border-[--ion-color-dark-tint]" />
                <app-nav-item *ngFor="let page of kycProgressPages" [page]="page"></app-nav-item>
            </ng-template>-->
        </ng-container>
    </ion-list>
    <div class="logout-button" *ngIf="!showProfile">
        <button style="color: white" (click)="global.logoutUser()">Logout</button>
    </div>
</ion-content>

<mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let page of otherPages" (click)="routeToPage(page)">
        {{ page.title }}
    </button>
</mat-menu>
