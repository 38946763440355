<form [formGroup]="form" class="form-v2">
    <!-- company name -->
    <app-form-field label="label.company_name" required>
        <ion-item class="form-v2__item form-v2__item--input">
            <ion-input placeholder="ABC Solutions Limited" formControlName="companyName"></ion-input>
        </ion-item>
    </app-form-field>

    <!-- Address  -->
    <app-form-field label="label.address" required>
        <location-search-v2 [addressGroup]="form.controls.address" required></location-search-v2>
    </app-form-field>

    <ion-grid class="info-grid-v2">
        <ion-row>
            <!-- Company Phone Number -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.company_phone_number" required>
                    <ion-item class="form-v2__item form-v2__item--input">
                        <input
                            class="ion-input-replica"
                            placeholder="800-555-0199"
                            formControlName="phoneNumber"
                            mask="000-000-0000" />
                    </ion-item>
                </app-form-field>
            </ion-col>

            <!-- Company Website -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.company_website">
                    <ion-item class="form-v2__item form-v2__item--input">
                        <ion-input
                            placeholder="http://www.abc-solutions-404.com/"
                            formControlName="website"></ion-input>
                    </ion-item>
                </app-form-field>
            </ion-col>
        </ion-row>
    </ion-grid>
</form>
