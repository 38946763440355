import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';

import {getAddressFormGroup} from '../../../utils/helpers';
import {AccountInformation} from '../account-information.modal';
import {AccountInformationService} from '../account-information.service';

@Component({
    selector: 'app-ai-company-details',
    templateUrl: './company-details.component.html',
})
export class CompanyDetailsComponent {
    /** company information form group */
    form = this.initForm();

    /**
     * constructor
     *
     * patches the form with data received from the resolver
     */
    constructor(
        readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly accInfoService: AccountInformationService
    ) {
        const {companyInformation} = route.snapshot.data.accountInformation as AccountInformation;
        this.form.patchValue(companyInformation);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * update the company information data at Backend
     */
    updateCompanyInformation() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.form.controls.address.updateValueAndValidity();
            return;
        }

        this.accInfoService.updateCompanyInformation(this.form.value);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initializes the company information form
     *
     * @returns company information form group
     */
    private initForm() {
        return this.formBuilder.group({
            companyName: ['', Validators.required],
            phoneNumber: ['', Validators.required],
            website: [null],
            address: getAddressFormGroup(),
        });
    }
}
