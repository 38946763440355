import {Component} from '@angular/core';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.page.html',
    styles:[
        `ion-card-title{
            color: #172655;
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 1.08px;
        text-transform: capitalize;
        }
        `
    ]
})
export class ProfilePage {}
