import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';

var that: any;
@Component({
	selector: 'app-payments',
	templateUrl: 'payments.page.html'
})
export class PaymentsPage {
	rnt = '';
	amount = 0;
	url = '';
	constructor(public global: GlobalService, public route: ActivatedRoute) {

    }
	ngOnInit() {

	}
	makePayment() {
		if(this.rnt && this.amount) {
			this.global.showLoading();
			this.global.sendAjaxRequest('payment/getPaymentUrl', {rnt: this.rnt, amount: this.amount}).subscribe(response => {
				if(response.message === 'success') {
					// this.url = response.url;
					window.open(response.url, '_blank');
					this.global.hideLoading();
				}
			});
		}
	}
}
