<!-- <top-menu title="label.buildings" [btn]="btn" (btnClicked)="openBuildingModal(0)"></top-menu> -->
<div [ngClass]="{'min-width':!disableAddBtn }" style="margin-bottom: 100px; overflow-x: auto">
    <div cdkScrollable>
        <!-- page heading -->

        <app-page-header
            pageHeading="label.directors_signatories_ubos"
            [showButton]="true"
            [matTooltipText]="stakeholderInfo()">
            <div *ngIf="!disableAddBtn">
                <ion-button class="btn__primary" (click)="addStakeHolder()" *ngIf="!disableAddBtn">
                    <svg>
                        <use xlink:href="/assets/icon/sprite.svg#icon-circle-plus"></use>
                    </svg>
                    <span>{{'label.add_stakeholder' | translate}}</span>
                </ion-button>
            </div>
        </app-page-header>
        <p style="text-align: right; margin-right: 3.5%; margin-top: -2%; margin-bottom: 10px; cursor: pointer">
            <span (click)="needMoreDetails()">Need more info?</span>
        </p>

        <!-- table data with filters -->
        <app-table-with-filters>
            <!-- filters -->

            <!-- table heading -->
            <!-- table -->
            <ag-grid-angular
                id="myGrid"
                twf-table
                domLayout="autoHeight"
                class="ag-theme-alpine"
                [ngClass]="{'max-h': true}"
                [ngStyle]="{'height': (totalCount.toString() + '90') + 'px'}"
                [gridOptions]="gridOptions"
                [overlayNoRowsTemplate]="'errors.no_stakeholders_found' | translate"
                (gridReady)="onGridReady($event)"
                (filterChanged)="onFilterChanged($event)"
                (columnMoved)="reindexColumns($event)">
            </ag-grid-angular>
            <app-paginator
                [page]="1"
                [limit]="10"
                [totalCount]="totalCount"
                *ngIf="totalCount > 50"
                (pageChange)="onPaginationChanged($event)"
                #paginator></app-paginator>
        </app-table-with-filters>
    </div>
</div>
