import {Component, Output, EventEmitter, ViewChild, NgZone, ElementRef, OnInit, Input} from '@angular/core';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'combo-box',
    templateUrl: './combo-box.component.html',
    styleUrls: ['./combo-box.component.scss'],
})
export class ComboBoxComponent implements OnInit {
    public searchbarValue = '';
    @Input() selectedIds = [];
    @Input() selectedId;
    @Input() selectedItems = [];
    @Input() selectedItem;

    @Input() disabled = false;
    @Input() isRequired = false;
    @Input() listObject = {};
    @Input() filterIndex;
    @Input() comboBox = false;
    @Input() showImage = false;
    @Input() searchBarPlaceHolder = '';
    @Input() ajaxOptions = null;
    @Input() componentTitle = false;
    @Input() multiChoice = false;
    @Input() display = 'title';
    @Input() translateTitle = false;
    @Input() btnTitle = 'label.add';

    showOptions = false;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() selectedItemChange = new EventEmitter();
    @Output() selectedIdChange = new EventEmitter();
    @Output() selectedIdsChange = new EventEmitter();
    @Output() selectedItemsChange = new EventEmitter();
    public titleValue;

    public filterObject = {offset: 0, searchQuery: '', selectedValue: null};
    public infiniteScrollEvent;
    public value;
    public tempInitialValue;
    public initialSearchClearSearch = false;
    public searchingInProgress = false;
    public hideLoadMore = true;
    public fetchingValue = false;
    constructor(public global: GlobalService) {
        if (this.searchBarPlaceHolder === '') {
            if (this.comboBox) this.searchBarPlaceHolder = this.global.translate.instant('label.select_or_add');
            else this.searchBarPlaceHolder = this.global.translate.instant('label.type_n_select');
        }
    }
    ngOnInit(): void {
        // throw new Error('Method not implemented.');
        if (this.ajaxOptions) {
            this.filterObject = this.ajaxOptions['params'];
            this.filterObject['searchQuery'] = '';
            this.filterObject['offset'] = 0;
            this.listObject['list'] = [];
            if (this.filterIndex == null) this.filterObject['searchQuery'] = this.titleValue;
            else if (this.value !== '' && this.value !== null) {
                this.initialSearchClearSearch = true;
                this.filterObject['initialSearchQuery'] = this.value;
            } // do not remove this hide loading
            else this.global.hideLoading();
            // this.loadAjaxOptions(null);
        }
    }

    ngOnChanges(inputs) {
        if (
            inputs.selectedItem &&
            inputs.selectedItem.currentValue !== null &&
            typeof inputs.selectedItem.currentValue !== 'undefined'
        ) {
            this.selectedItems = [inputs.selectedItem.currentValue];
            setTimeout(() => {
                this.selectedId = this.selectedItem[this.listObject['value']];
                this.selectedIdChange.emit(this.selectedId);
            }, 1);
        } else if (
            inputs.selectedId &&
            inputs.selectedId.currentValue !== null &&
            typeof inputs.selectedId.currentValue !== 'undefined'
        ) {
            if (this.ajaxOptions?.hasOwnProperty('fetchInitialValue') && this.ajaxOptions['fetchInitialValue']) {
                this.fetchingValue = true;
                const tempData = this.global._.cloneDeep(this.ajaxOptions['params']);
                tempData['fetchSelectedRecord'] = inputs.selectedId.currentValue;
                this.global.sendAjaxRequest(this.ajaxOptions['url'], tempData).subscribe((response) => {
                    this.selectedId = inputs.selectedId.currentValue;
                    this.selectedItems = [response];
                    this.selectedItem = response;
                    this.fetchingValue = false;
                });
            }
        }
    }

    // onValueChange(value, titleValue) {
    // 	if (!this.comboBox || (this.comboBox && value !== '' && value != null)) {
    // 		if (this.tempInitialValue !== value)
    // 			this.valueChange = true;

    // 		// this.modalCtrl.dismiss({ valueChange: this.valueChange, value, titleValue });
    // 	}
    // }

    loadAjaxOptions(infiniteScroll) {
        if (infiniteScroll == null) {
            this.searchingInProgress = true;
            this.filterObject.offset = 0;
        }

        this.hideLoadMore = true;
        this.global.sendAjaxRequest(this.ajaxOptions['url'], this.filterObject).subscribe((response) => {
            if (infiniteScroll == null) {
                this.searchingInProgress = false;
                this.listObject['list'] = response.options;
                if (this.initialSearchClearSearch) {
                    this.initialSearchClearSearch = false;
                    delete this.filterObject['initialSearchQuery'];
                }
            } else {
                if (infiniteScroll !== false && infiniteScroll != null) this.infiniteScrollEvent = infiniteScroll;
                if (this.infiniteScrollEvent) this.infiniteScrollEvent.target.complete();

                // tslint:disable-next-line:prefer-for-of
                for (let index = 0; index < response.options.length; index++)
                    this.listObject['list'].push(response.options[index]);
            }
            // eslint-disable-next-line radix
            this.filterObject.offset += parseInt(response.options.length);
            if (response.options.length >= this.global.limitForEntries) this.hideLoadMore = false;
        });
    }

    actionOnItem(item = null) {
        const indexName = this.listObject['value'];

        if (!this.multiChoice) {
            this.selectedIds = [item[indexName]];
            this.selectedItems = [item];
            this.selectedId = item[indexName];
            this.selectedItem = item;
            this.emitValues();
        } else {
            if (this.selectedItems.length < 10) {
                let exist = null;
                for (let i = 0; i < this.selectedIds.length; i++) {
                    if (this.selectedIds[i] === item[indexName]) {
                        exist = i;
                        break;
                    }
                }
                if (exist === null) {
                    this.selectedIds.push(item[indexName]);
                    this.selectedItems.push(item);
                    this.emitValues();
                }
            } else {
                this.global.basicAlert('', 'You cannot select more than 5 options.', true);
            }
        }
    }

    removeItem(item) {
        let exist = null;
        const indexName = this.listObject['value'];
        for (let i = 0; i < this.selectedIds.length; i++) {
            if (this.selectedIds[i] === item[indexName]) exist = i;
        }
        if (exist !== null) {
            this.selectedIds.splice(exist, 1);
            this.selectedItems.splice(exist, 1);
        }
        this.emitValues();
    }
    addNewItem() {
        this.selectedIds = [0];
        this.selectedId = 0;
        const indexName = this.listObject['value'];
        const item = {};
        item[this.listObject['value']] = null;
        item[this.listObject['title']] = this.filterObject['searchQuery'];
        this.filterObject['searchQuery'] = '';
        this.selectedItems = [item];
        this.selectedItem = item;
        this.emitValues();
    }
    public clear() {
        this.selectedIds = [];
        this.selectedItems = [];
        this.selectedId = null;
        this.selectedItem = null;
        this.emitValues();
    }
    emitValues() {
        this.selectedItemChange.emit(this.selectedItem);
        this.selectedIdChange.emit(this.selectedId);
        this.selectedItemsChange.emit(this.selectedItems);
        this.selectedIdsChange.emit(this.selectedIds);

        this.onChange.emit({
            selectedIds: this.selectedIds,
            selectedItems: this.selectedItems,
            selectedItem: this.selectedItem,
            selectedId: this.selectedId,
        });
    }

    resetOption() {
        setTimeout(() => {
            this.showOptions = false;
            this.filterObject.searchQuery = '';
        }, 300);
    }
}
