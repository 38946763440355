import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';

@Directive({
   selector: '[appNoLeadingTrailingSpaces]',
   providers: [{ provide: NG_VALIDATORS, useExisting: NoLeadingTrailingSpacesDirective, multi: true }]
})
export class NoLeadingTrailingSpacesDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        const value: string = control.value;
        if (value && (value.trim() !== value)) {
            return { leadingTrailingSpaces: true };
        }
        return null;
    }
}
