import {FormControl, FormControlName, NgModel} from '@angular/forms';
import {Component, ContentChild, EventEmitter, Input, Output} from '@angular/core';
@Component({
    selector: 'app-form-field',
    template: `
        <div class="flex">
            <mat-label [ngClass]="{invisible: isLabelHidden}">
                {{ label | translate }}
                <span *ngIf="isRequired" class="required-asterisk">*</span>
                {{ additionalLabel | translate }}
            </mat-label>
            <button *ngIf="showButton" mat-icon-button matSuffix (click)="handleButtonClick()">
                <svg class="w-5 h-5 ml-2">
                    <use xlink:href="/assets/icon/heroicons-outline.svg#question-mark-circle"></use>
                </svg>
            </button>
            <button
                *ngIf="showButtonInfo"
                mat-icon-button
                matSuffix
                matTooltip="{{ matTooltipText }}"
                (click)="handleButtonClick()"
                [matTooltipClass]="'tooltip'">
                <svg class="w-5 h-5 ml-2">
                    <use xlink:href="/assets/icon/heroicons-outline.svg#information-circle"></use>
                </svg>
            </button>
        </div>

        <ng-content></ng-content>
    `,
    styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
    /**
     * label which is displayed over the form field.
     *
     * default value is `'Demo Label'`
     */
    @Input()
    label = 'Demo Label';

    /** additional label displayed next to the main label. */
    @Input()
    additionalLabel = '';

    @Input()
    matTooltipText = '';

    @Input()
    showButton = false;

    @Input()
    showButtonInfo = false;

    // eslint-disable-next-line @typescript-eslint/ban-types
    @Output() buttonClick = new EventEmitter<void>();
    /**
     * mark the field as required or optional manually.
     *
     * this setter allows you to manually override the default behavior and mark the form field as required or optional.
     *
     * @param val - Use an empty string or a boolean to specify the field's required status.
     *   - If `val` is an empty string or evaluates to `true`, the field will be marked as required.
     *   - If `val` evaluates to `false`, the field will be marked as optional.
     */
    @Input()
    set required(val: '' | boolean) {
        this.isRequired = val === '' || val;
    }

    /**
     * mark the field label as visible or hidden.
     *
     * this setter allows you to manually override the default behavior and make the label visible or hidden.
     *
     * @param val - Use an empty string or a boolean to specify the field's label visible status.
     *   - If `val` is an empty string or evaluates to `true`, the field's label will be visible.
     *   - If `val` evaluates to `false`, the field's label will be set to hidden.
     */
    @Input()
    set hideLabel(val: '' | boolean) {
        this.isLabelHidden = val === '' || val;
    }

    /**
     * When a `FormControlName` is projected into this component, the `formControlName` setter is called,
     * which in turn calls the `updateRequiredField` method to update the `isRequired` property based on the
     * presence of validators and whether the control is marked as required.
     */
    @ContentChild(FormControlName)
    protected set formControlName(val: FormControlName) {
        this.updateRequiredField(val?.control);
    }

    /**
     * When a `FormControl` is projected into this component, the `formControl` setter is called,
     * which in turn calls the `updateRequiredField` method to update the `isRequired` property based on the
     * presence of validators and whether the control is marked as required.
     */
    @ContentChild(FormControl)
    protected set formControl(val: FormControl) {
        this.updateRequiredField(val);
    }

    /**
     * When a `NgModel` is projected into this component, the `ngModel` setter is called,
     * which in turn calls the `updateRequiredField` method to update the `isRequired` property based on the
     * presence of validators and whether the control is marked as required.
     */
    @ContentChild(NgModel)
    protected set ngModel(val: NgModel) {
        this.updateRequiredField(val);
    }

    handleButtonClick() {
        this.buttonClick.emit();
    }

    /**
     * used to mark field as required by adding a (*) next to the label.
     *
     * when set to `true`, the form field will be visually indicated as required.
     * when set to `false`, the form field will be visually indicated as optional.
     */
    protected isRequired = false;

    /**
     * used to hide the label by setting the visibility to hidden.
     *
     * when set to `true`, the label's visibility will be set to hidden.
     * when set to `false`, the label's visibility will be set to visible.
     */
    protected isLabelHidden = false;

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * set the 'isRequired' property based on the provided form control.
     *
     * this function is automatically called when a form control is projected into the component.
     *
     * @param ctrl - the projected form control.
     * if the provided control has a validator and is marked as required, 'isRequired' will be set to 'true', otherwise 'false'.
     */
    private updateRequiredField(ctrl?: FormControl | NgModel) {
        if (!ctrl || !ctrl?.validator) {
            return;
        }

        this.isRequired = ctrl.validator(new FormControl())?.required;
    }
}
