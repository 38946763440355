import {animate, state, style, transition, trigger} from '@angular/animations';

/** duration of the drop in/out in out animation */
const dropInOutDuration = '500ms cubic-bezier(.73,.32,.34,1.5)';

/**
 * menu drop in/out angular animation
 */
export const menuDropInOut = trigger('menuDropInOut', [
    // initial state
    state('void', style({height: '0'})),
    transition(':enter', [
        // styles applied on element entering state
        style({height: '0'}),
        animate(dropInOutDuration, style({height: '400px'})),
    ]),
    transition(':leave', [
        // styles applied on element leaving state
        animate(dropInOutDuration, style({height: '0'})),
    ]),
]);
