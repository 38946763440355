import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * A custom validator used to compare the values of two form controls and
 * check if they match. It can be applied to a form control to ensure that its
 * value matches the value of another complementary form control. If the values
 * do not match, a validation error with the key 'matchValueError' is set on the
 * complementary control.
 *
 * @param controlName - The name of the form control whose value you want to compare.
 * @param complementaryControlName - The name of the complementary form control to compare with.
 * @returns A ValidatorFn function that checks if the values of the specified form controls match.
 */
export const mustMatch =
    (controlName?: string, complementaryControlName?: string): ValidatorFn =>
    (formControl: AbstractControl): ValidationErrors => {
        const control = formControl.get(controlName || '');
        const complementaryControl = formControl.get(complementaryControlName || '');

        if (control?.value !== complementaryControl?.value) {
            complementaryControl?.setErrors({matchValueError: true});
        } else {
            complementaryControl?.setErrors(null);
        }
        return {};
    };
