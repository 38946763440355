import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {BottomMenuComponent} from './bottom-menu/bottom-menu.component';
import {ImageViewerComponent} from './image-viewer/image-viewer.component';
import {SimpleTopBarComponent} from './simple-top-bar/simple-top-bar.component';
import {LocationSearchComponent} from './location-search/location-search.component';
import {TopMenuComponent} from './top-menu/top-menu.component';
import {ComboBoxComponent, ComboBoxComponentV2} from './combo-box';
import {FileUploaderComponent} from './file-uploader/file-uploader.component';
import {PageHeaderComponent} from './page-header';
import {AgGridBtn, AgGridBtnV2} from './ag-grid-btn';
import {TableWithFiltersComponent, TableWithFilterTableDirective} from './table-with-filters';
import {FormFieldComponent} from './form-field/form-field.component';
import {LocationSearchV2Component} from './location-search-v2';
import {ImageUploadComponent} from './image-upload';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DirectivesModule} from '../utils/directives';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {SearchBarComponent} from './search-bar';
import {PaginatorComponent} from './paginator/';
import {MatChipsModule} from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';

const components = [
    BottomMenuComponent,
    ImageViewerComponent,
    SimpleTopBarComponent,
    TopMenuComponent,
    LocationSearchComponent,
    ComboBoxComponent,
    FileUploaderComponent,
    AgGridBtn,
    AgGridBtnV2,
    TableWithFiltersComponent,
    TableWithFilterTableDirective,
    PageHeaderComponent,
    FormFieldComponent,
    LocationSearchV2Component,
    ImageUploadComponent,
    ComboBoxComponentV2,
    SearchBarComponent,
    PaginatorComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        DirectivesModule,
        MatProgressBarModule,
        MatChipsModule,
        MatTooltipModule,
    ],
    declarations: [...components],
    exports: [...components],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
