import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {CountdownModalComponent} from './countdown-modal.component';
import {TranslateModule} from '@ngx-translate/core';
@NgModule({
    declarations: [CountdownModalComponent],
    imports: [CommonModule, IonicModule, TranslateModule],
})
export class CountdownModalModule {}
