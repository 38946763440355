import { Component, Output, EventEmitter, ViewChild, NgZone, ElementRef, OnInit, Input } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';

declare var google;
@Component({
	selector: 'location-search',
	templateUrl: './location-search.component.html',
	styleUrls: ['./location-search.component.scss'],
})
export class LocationSearchComponent implements OnInit {

	public autoCompleteService;
	public placeService;
	public autocompleteItems = [];
	@Input() searchbarValue = '';
	@Input() type = 'ion-item';

	constructor(private zone: NgZone) {
	}

	// tslint:disable-next-line:no-output-on-prefix
	@Output() onPlaceChanged: EventEmitter<any> = new EventEmitter();
	// tslint:disable-next-line:no-output-on-prefix
	@Output() onTextChange: EventEmitter<any> = new EventEmitter();
	@Input() placeHolder = 'Search (Google Address)';
	@Input() searchIcon = 'search';
	@ViewChild('searchbar') searchBar: IonSearchbar;
	@ViewChild('locationSearchMap') mapElement: ElementRef;

	ngOnInit(): void {
		// throw new Error('Method not implemented.');
	}
	// tslint:disable-next-line:use-life-cycle-interface
	ngAfterViewInit() {
		this.autoCompleteService = new google.maps.places.AutocompleteService();
		const map = new google.maps.Map(this.mapElement.nativeElement);
		this.placeService = new google.maps.places.PlacesService(map);
	}
	onBlurSearch() {
		if (this.searchbarValue === '' || this.searchbarValue === null)
			this.autocompleteItems = [];
	}
	onChange() {
		this.onTextChange.emit(this.searchbarValue);
		if (this.searchbarValue === '') {
			this.autocompleteItems = [];
			return;
		} else {
			const me = this;
			// tslint:disable-next-line:only-arrow-functions
			this.autoCompleteService.getPlacePredictions({ input: this.searchbarValue }, function(predictions, status) {
				me.autocompleteItems = [];
				// tslint:disable-next-line:only-arrow-functions
				me.zone.run(function() {
					// tslint:disable-next-line:only-arrow-functions
					predictions.forEach(function(prediction) {
						me.autocompleteItems.push(prediction);
					});
				});
			});


		}
	}
	chooseItem(item: any) {
		this.searchbarValue = item.description;
		this.autocompleteItems = [];
		const me = this;
		// tslint:disable-next-line:only-arrow-functions
		this.placeService.getDetails({ placeId: item.place_id }, function(place, status) {
			me.onPlaceChanged.emit(place);
		});
	}

}
