import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, EventEmitter, Input, Output} from '@angular/core';

import {DocumentName, DocumentType} from '../../../account-information.modal';

@Component({
    selector: 'app-ai-document-uploader',
    templateUrl: './uploader.component.html',
    styleUrls: ['./uploader.component.scss'],
})
export class DocumentUploaderComponent {
    /** document details form group */
    form = this.initForm();

    /** download image url of the document */
    image = '';

    /** snake case title of the document for translation purpose */
    snakeCaseTitle = '';

    /** type of the document */
    @Input()
    set documentType(val: DocumentType) {
        this.form.controls.documentType.setValue(val);
    }

    /** name of the document */
    @Input()
    set documentName(val: DocumentName) {
        if (!val) {
            return;
        }
        this.form.controls.documentName.setValue(val);
        this.getSnakeName(val);
    }

    /** name of the country that issued the document */
    @Input()
    set issuedCountry(val: string) {
        this.form.controls.issuedCountry.setValue(val);
    }

    /** document uploader icon to use */
    @Input()
    icon = 'globe-asia-australia';

    /** emits the form group once initialized */
    @Output()
    onFormGroupSet = new EventEmitter<FormGroup>();

    /** constructor */
    constructor(private readonly formBuilder: FormBuilder) {}

    /**
     * emits the form group to parent component
     */
    ngOnInit() {
        this.onFormGroupSet.emit(this.form);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * patches the file name once the document uploading is complete
     */
    onFileUpload(fileName: string) {
        this.form.controls.fileName.setValue(fileName);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initializes the document details form
     *
     * @returns document details form group
     */
    private initForm() {
        return this.formBuilder.group({
            documentType: this.formBuilder.control<DocumentType>(DocumentType.ARTICLES_OF_INCORPORATION),
            documentName: this.formBuilder.control<DocumentName>(DocumentName.ARTICLES_OF_INCORPORATION),
            issuedCountry: this.formBuilder.control(''),
            fileName: this.formBuilder.control('', Validators.required),
            filePath: this.formBuilder.control('', Validators.required),
        });
    }

    /**
     * updates the snakeCaseTitle of the document name for translation purpose
     */
    private getSnakeName(documentName: DocumentName) {
        this.snakeCaseTitle = documentName.split('-').join('_');
    }
}
