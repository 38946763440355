import {NgModule} from '@angular/core';
import {MomentPipe} from './moment.pipe';
import {ObjectKeysPipe} from './objectKeys.pipe';
import {SafeHtmlPipe, SafeUrlPipe} from './safe.pipe';
import {ObjectStringFilterPipe} from './objectStringFilter.pipe';
import {StringFilterPipe} from './stringFilter.pipe';
import {ParamsTranslatePipe} from './paramsTranslate.pipe';
import {MultipleStringFilterPipe} from './multipleStringFilter.pipe';
import {OrderByPipe} from './orderBy.pipe';
import { ToPascalCasePipe } from './pascalCase.pipe';
import { DateFormatPipe } from './DateFormat.pipe';

@NgModule({
    declarations: [
        MomentPipe,
        ObjectKeysPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        ObjectStringFilterPipe,
        StringFilterPipe,
        ParamsTranslatePipe,
        MultipleStringFilterPipe,
        OrderByPipe,ToPascalCasePipe,DateFormatPipe
    ],
    imports: [],
    exports: [
        MomentPipe,
        ObjectKeysPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        ObjectStringFilterPipe,
        StringFilterPipe,
        ParamsTranslatePipe,
        MultipleStringFilterPipe,
        OrderByPipe,ToPascalCasePipe,DateFormatPipe
    ],
})
export class PipesModule {}
