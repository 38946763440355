import {Component} from '@angular/core';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';

import {ProfileService} from '../profile.service';
import {getAddressFormGroup} from '../../../utils/helpers';
import {AddressFormGroup} from 'src/app/components/location-search-v2';

@Component({
    selector: 'app-profile-details-form',
    templateUrl: './details-form.component.html',
})
export class DetailsFormComponent {
    /** profile details form group */
    form = this.initForm();

    /** download image url of the profile image */
    profileImg: string | null;

    /** constructor */
    constructor(private readonly formBuilder: FormBuilder, private readonly profileService: ProfileService) {
        this.patchProfileData();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * updates the profile details at the BE
     */
    updateProfileDetails() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.form.controls.address.updateValueAndValidity();
            return;
        }
        const updatedFormValue = {
            ...this.form.value,
            linkedinUrl: this.form.value.linkedinUrl === '' ? null : this.form.value.linkedinUrl,
        };
        this.profileService.updateProfileDetails(updatedFormValue);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * initializes the profile details form
     *
     * @returns profile details form group
     */
    private initForm() {
        const linkedinUrlPatternValidator = (control: AbstractControl): {[key: string]: any} | null => {
            const value = control.value;
            if (value && !/^(https:\/\/)?(www\.)?linkedin\.com\/.*$/.test(value)) {
                return {linkedinUrlPattern: true};
            }
            return null;
        };
        return this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            profilePicturePath: this.formBuilder.control<string | null>(null),
            email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
            notificationEmail: [
                '',
                [
                    Validators.email,
                    Validators.required,
                    Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/),
                ],
            ],
            phoneNumberExtension: [null],
            phoneNumber: ['', Validators.required],
            title: [null],
            linkedinUrl: [null, linkedinUrlPatternValidator],
            address: this.formBuilder.group<AddressFormGroup>({
                ...getAddressFormGroup().controls,
                countryCode: this.formBuilder.control(''),
            }),
        });
    }

    /**
     * patch the latest profile details data
     */
    private patchProfileData() {
        this.profileService.profileDetails$.subscribe((profileData) => {
            this.profileImg = profileData?.profilePictureUrl;
            const phoneNumberWithoutSymbols = profileData?.phoneNumber?.replace(/\D/g, '');
            const phoneNumber = phoneNumberWithoutSymbols?.slice(0, 10) || '';
            const linkedinUrl = profileData?.linkedinUrl || null;
            this.form.patchValue({...profileData, phoneNumber, linkedinUrl: linkedinUrl as null});
        });
    }
}
