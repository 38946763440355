import {Subscription} from 'rxjs';
import {NgModel} from '@angular/forms';
import {debounceTime, distinctUntilChanged, skip} from 'rxjs/operators';
import {Directive, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';

/**
 * A custom Angular directive for debouncing and emitting changes from an NgModel.
 *
 * This directive is used to debounce and emit changes from an Angular NgModel input field.
 * It allows you to capture and emit input changes with a specified debounce time and distinct values.
 * This directive is particularly useful when migrating from Ionic `ion-input` to Angular Material `mat-input`,
 * as the debounce property of the Ionic component is not available in the Material form field.
 *
 * @usageNotes
 * To use this directive, apply it to an input element along with the `ngModel` directive.
 * The `ngModelDebounceChange` event will be emitted with the debounced and distinct input values.
 *
 * example
 * ```html
 * <input [(ngModel)]="searchQuery" (ngModelDebounceChange)="onSearchQueryChange($event)" />
 * ```
 */
@Directive({
    selector: '[ngModelDebounceChange]',
})
export class NgModelDebounceChangeDirective implements OnDestroy, OnInit {
    /** Emit event when model has changed. */
    @Output() ngModelDebounceChange = new EventEmitter<any>();

    /**
     * Debounce time
     *
     * @default 1s
     */
    @Input()
    debounceTime = 1000;

    /** Subscriptions for cleanup. */
    private subscription: Subscription;

    /** ng model injected down in the code */
    private ngModel: NgModel;

    /** constructor */
    constructor(private readonly injector: Injector) {
        this.ngModelInjection();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Life Cycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Attaches a value change listener to the NgModel's control, debouncing and emitting changes.
     */
    ngOnInit(): void {
        this.attachValueChangeListener();
    }

    /**
     * Unsubscribes from the value change subscription to prevent memory leaks.
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Attaches a value change listener to the NgModel's control, debouncing and emitting changes.
     *
     * @remarks
     * This method sets up a subscription to the NgModel's control value changes, applying debounce
     * and distinct operators before emitting changes.
     */
    private attachValueChangeListener() {
        this.subscription = this.ngModel.control.valueChanges // form control value changes subscription
            .pipe(skip(1), debounceTime(this.debounceTime), distinctUntilChanged()) // debounce the output by 1s
            .subscribe((value) => this.ngModelDebounceChange.emit(value));
    }

    /**
     * injects the ng model instance from the injector.
     * throws an error if not found in the injector.
     */
    private ngModelInjection() {
        try {
            this.ngModel = this.injector.get(NgModel);
        } catch (e) {
            throw new Error(`'ngModelDebounceChange' directive must be declared on an control with 'ngModel' declaration.\nMake sure you have declared it correctly.`);
        }
    }
}
