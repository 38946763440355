/* eslint-disable jsdoc/no-types */
import {Injectable} from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import {environment} from 'src/environments/environment';
const projectId = environment.mixpanelProjectId;
@Injectable({
    providedIn: 'root',
})
export class MixpanelService {
    /**
     * Initialize mixpanel.
     *
     *
     * @memberof MixpanelService
     */
    init(): void {
        mixpanel.init(projectId, {track_pageview: true});
    }

    /**
     * Push new action to mixpanel.
     *
     * @param {string} id Name of the action to track.
     * @param {*} [action={}] Actions object with custom properties.
     * @memberof MixpanelService
     */
    track(id: string, action: any = {}): void {
        mixpanel.track(id, action);
    }
}
