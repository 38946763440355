<ion-header>
    <!-- <warning-top *ngIf="global.subscriptionDetails"></warning-top> -->
    <ion-toolbar [ngClass]="{center: center}">
        <ion-button fill="clear" slot="start" *ngIf="backBtn" (click)="backBtnClick()">
            <ion-icon name="chevron-back-outline"></ion-icon>
        </ion-button>
        <!-- <ion-buttons slot="start" class="side-menu-icon">
            <ion-button (click)="global.openSideMenu()">
                <ion-icon name="menu"></ion-icon>
            </ion-button>
        </ion-buttons> -->
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ title | translate }}</ion-title>

        <ion-icon
            *ngIf="btn && !btn.title"
            class="only-icon"
            slot="end"
            [name]="btn.icon"
            (click)="btnClick()"></ion-icon>
        <!-- <ion-buttons slot="end" *ngIf="btn && btn.title">
           
        </ion-buttons> -->
        <ion-button [disabled]="disabled" slot="end" *ngIf="btn && btn.title" (click)="btnClick()">
            <ion-icon slot="start" *ngIf="btn.icon" [name]="btn.icon"></ion-icon>
            <span *ngIf="btn.title">{{ btn.title | translate }}</span>
        </ion-button>
    </ion-toolbar>
</ion-header>
