import {Component, Directive, ElementRef, EmbeddedViewRef, Input, Renderer2, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';

/**
 * Directive to show a shimmer effect while data is loading, inspired by Angular's ngIf directive.
 * This directive serves as a wrapper around ngIf, providing the same conditional rendering behavior
 * while adding the ability to display a shimmer container during data loading.
 *
 * @template T - The type of condition for the shimmer effect.
 */
@Directive({
    selector: '[appShimmer]',
})
export class ShimmerDirective<T = unknown> {
    private _context: NgIfContext<T> = new NgIfContext<T>();
    private _thenTemplateRef: TemplateRef<NgIfContext<T>> | null = null;
    private _thenViewRef: EmbeddedViewRef<NgIfContext> | null = null;
    /** height of the shimmer container */
    private _containerHeight = '100px';

    /**
     * Set the condition for the shimmer effect.
     *
     * @param condition - The condition for the shimmer effect.
     */
    @Input()
    set appShimmer(condition: T) {
        this._context.$implicit = this._context.shimmer = condition;
        this._updateView();
    }

    /**
     * Set the height of the shimmer container.
     *
     * @default '100px'
     */
    @Input()
    set appShimmerContainerHeight(height: string) {
        this._containerHeight = height;
        this._updateView();
    }

    /** constructor */
    constructor(private _viewContainer: ViewContainerRef, templateRef: TemplateRef<NgIfContext<T>>) {
        this._thenTemplateRef = templateRef;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the view based on the condition.
     * If the condition is true, it creates the view with the provided template.
     * If the condition is false, it clears the view and creates a ShimmerComponent.
     *
     * @private
     */
    private _updateView() {
        if (this._context.$implicit) {
            if (!this._thenViewRef) {
                this._viewContainer.clear();
                if (this._thenTemplateRef) {
                    this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
                }
            }
        } else {
            this._viewContainer.clear();
            if (this._thenTemplateRef) {
                this._thenViewRef = null;
                const ref = this._viewContainer.createComponent(ShimmerComponent);
                ref.instance.height = this._containerHeight;
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Static methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Static method to guard the ng-template context for type checking purposes.
     *
     * @param dir - The ShimmerDirective instance.
     * @param ctx - The context to be checked.
     * @returns - Type guard result.
     */
    static ngTemplateContextGuard<T>(dir: ShimmerDirective<T>, ctx: any): ctx is NgIfContext<Exclude<T, false | 0 | '' | null | undefined>> {
        return true;
    }
}

/**
 ********************************************************************************
      ngIf context used with the shimmer directive for type checking purpose
 ********************************************************************************
 */

/**
 * NgIf context used with the shimmer directive for type checking purposes.
 *
 * @template T - The type of NgIf context.
 */
export class NgIfContext<T = unknown> {
    /** The implicit value of the NgIf context. */
    public $implicit: T = null;

    /** The shimmer value of the NgIf context. */
    public shimmer: T = null;
}

/**
 ********************************************************************************
   shimmer component used to render shimmer template while the data is loading
 ********************************************************************************
 */

/**
 * Shimmer component used to render a shimmer template while the data is loading.
 *
 * @template T - The type of data for the shimmer component.
 */
@Component({
    template: `<div #shimmerEl class="shimmer min-w-[200px] w-full h-full"></div>`,
})
export class ShimmerComponent {
    /** Input property to set the height of the shimmer container. */
    @Input()
    height?: string;

    /** View child to reference the shimmer element. */
    @ViewChild('shimmerEl', {static: true}) shimmerEl: ElementRef<HTMLDivElement>;

    /** constructor */
    constructor(private readonly renderer: Renderer2) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Life Cycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sets the minimum height of the shimmer element based on the provided height.
     */
    ngAfterViewInit() {
        const el = this.shimmerEl.nativeElement;
        this.renderer.setStyle(el, 'min-height', this.height);
    }
}
