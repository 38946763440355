import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({providedIn: 'root'})
export class FeedbackService {
    /** baba tools access token */
    private readonly accessToken = 'pA3I6Ld5hHZgWkHQ3Mwr/A0k3v6aNr3hnX0NkztSre54gSJ7H8M5sw';
    /** angular renderer2 instance */
    private readonly renderer: Renderer2;

    /** constructor */
    constructor(
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT)
        private readonly document: Document
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * setup baba tools configurations and add it to portal
     */
    setupFeedbackTool() {
        const XFeedback = {
            XFeedback: {
                access_token: this.accessToken,
            },
        };

        const url = 'https://static.baba.tools/libs/widget-js/widget-js.js';
        this.setUpScript(url, XFeedback);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * embed feedback tool script
     */
    private setUpScript(srcUrl: string, toolSpecs: Record<string, any>) {
        const script = this.renderer.createElement('script');
        script.src = srcUrl;
        script.type = 'module';
        script.async = true;
        Object.assign(window, toolSpecs);
        this.renderer.appendChild(this.document.body, script);
    }
}
