import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * A custom paginator component for rendering pagination controls.
 * A custom paginator component for rendering pagination controls,
 * allowing easy navigation through large datasets.
 *
 * @usageNotes
 * To use this component, provide the total number of items, items per page, and the current page.
 * Additionally, you can handle the page change event by listening to the `pageChange` output.
 *
 * ```html
 * <app-paginator
 *   [page]="currentPage"
 *   [limit]="itemsPerPage"
 *   [totalCount]="totalItems"
 *   (pageChange)="onPageChange($event)"
 * ></app-paginator>
 * ```
 *
 * Additionally, you can achieve two-way data binding for the 'page' property using the [(page)] syntax.
 * This allows seamless synchronization between the component and the parent's 'currentPage' property.
 * ```html
 * <app-paginator [(page)]="currentPage">
 * </app-paginator>
 * ```
 */
@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
    /** current page number */
    @Input()
    page = 1;

    /** number of items to display per page */
    @Input()
    limit = 10;

    /** total number of items in the dataset that the pagination component is paginating */
    @Input()
    totalCount = 87;

    /** emits the new page number when page is changed */
    @Output()
    pageChange = new EventEmitter<number>();

    /** number of sibling page buttons to display on each side of the current page in the pagination component */
    private readonly SIBLING_COUNT = 1;

    /** number of boundary page buttons to display on each end of the pagination component */
    private readonly BOUNDARY_COUNT = 1;

    // -----------------------------------------------------------------------------------------------------
    // @ Getter methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * computes the total number of pages based on the total count and items per page
     */
    get pageCount() {
        return Math.ceil(this.totalCount / this.limit);
    }

    /**
     * computes the array of starting page numbers to display
     */
    get startPages(): number[] {
        // calculate the range end point as the minimum of the boundary count and total page count
        const rangeEnd = Math.min(this.BOUNDARY_COUNT, this.pageCount);

        // generate a range of sequential numbers from 1 to the calculated range end point
        return this.getRange(1, rangeEnd);
    }

    /**
     * computes the array of ending page numbers to display
     */
    get endPages(): number[] {
        // represents the maximum possible starting point that
        // still leaves enough space for the boundary count on the left side.
        const naturalStart = this.pageCount - this.BOUNDARY_COUNT + 1;

        // represents an additional space on the left side to allow for potential ellipsis
        // or additional pages before reaching the left boundary.
        const naturalEnd = this.BOUNDARY_COUNT + 1;

        // calculate the starting page of the ending pages
        const rangeStart = Math.max(naturalStart, naturalEnd);

        // determine the ending pages by generating a range from the
        // calculated start point to the total page count.
        return this.getRange(rangeStart, this.pageCount);
    }

    /**
     * computes the starting point for the range of sibling page buttons to display before the current page
     */
    get siblingsStart(): number {
        // calculate the starting point considering the current page and sibling count.
        const naturalStart = this.page - this.SIBLING_COUNT;

        // calculate the lower boundary when the current page is high.
        // - `this.SIBLING_COUNT * 2`: ensures space for both left and right siblings.
        // - `- 1`: provides extra space for potential ellipsis or additional pages on the left.
        const lowerBoundary = this.pageCount - this.BOUNDARY_COUNT - this.SIBLING_COUNT * 2 - 1;

        // determine the starting point by taking the maximum of the natural start and the lower boundary.
        const adjustedStart = Math.min(naturalStart, lowerBoundary);

        // ensure that the starting point is greater than the boundary count + 2
        // - `boundary count + 2`: there are at least boundary count + 2 pages before the current selected page).
        return Math.max(adjustedStart, this.BOUNDARY_COUNT + 2);
    }

    /**
     * computes the ending point for the range of sibling page buttons to display after the current page
     */
    get siblingsEnd(): number {
        // calculate the ending point considering the current page and sibling count.
        const naturalEnd = this.page + this.SIBLING_COUNT;

        // calculate the upper boundary when the current page is low.
        // - `this.SIBLING_COUNT * 2`: Ensures space for both left and right siblings.
        // - `+ 2`: Provides extra space for potential ellipsis or additional pages on the right.
        const upperBoundary = this.BOUNDARY_COUNT + this.SIBLING_COUNT * 2 + 2;

        // calculate the maximum ending point by considering the endPages range or the total number of pages.
        // If there are endPages, set maxEndingPoint to the first endPage minus 2 to ensure space before the ellipsis.
        // Otherwise, set maxEndingPoint to pageCount - 1, ensuring space for the last page.
        const maxEndingPoint = this.endPages.length > 0 ? this.endPages[0] - 2 : this.pageCount - 1;

        // determine the ending point by taking the minimum of the natural end and the upper boundary.
        const adjustedEnd = Math.max(naturalEnd, upperBoundary);

        // ensure that the ending point is less than the calculated maximum ending point.
        return Math.min(adjustedEnd, maxEndingPoint);

        // return Math.min(
        //     Math.max(this.page + this.SIBLING_COUNT, this.BOUNDARY_COUNT + this.SIBLING_COUNT * 2 + 2),
        //     this.endPages.length > 0 ? this.endPages[0] - 2 : this.pageCount - 1
        // );
    }

    /**
     * computes the list of page numbers and ellipsis to render in the pagination component
     *
     * generates a list of page numbers and ellipsis based on the current page, boundary count,
     * and total page count. It ensures that the list is structured with ellipsis where needed
     * to represent a condensed view of the pagination component
     */
    get pageList(): (number | string)[] {
        // determine whether to include an ellipsis or an additional page
        // before the starting pages based on the current page and boundary count
        const startEllipsisOrAdditionalPage =
            // compare the siblingsStart with the BOUNDARY_COUNT + buffer space for ellipsis
            this.siblingsStart > this.BOUNDARY_COUNT + 2
                ? // true scenario means there is enough space before the starting pages for an ellipsis
                  ['ellipsis']
                : // check if BOUNDARY_COUNT is less the difference of pageCount and BOUNDARY_COUNT
                this.BOUNDARY_COUNT + 1 < this.pageCount - this.BOUNDARY_COUNT
                ? // true scenario means means there is enough space before the starting pages for an additional page.
                  [this.BOUNDARY_COUNT + 1]
                : // there is not enough space before the starting pages for either an ellipsis or an additional page.
                  [];

        // determine whether to include an ellipsis or an additional page
        // after the sibling pages based on the current page and boundary count
        const endEllipsisOrAdditionalPage =
            // check if siblingEnd is less than the difference of pageCount and BOUNDARY_COUNT
            this.siblingsEnd < this.pageCount - this.BOUNDARY_COUNT - 1
                ? // true scenario means there is enough space after the sibling pages for an ellipsis.
                  ['ellipsis']
                : // check if the difference of pageCount and BOUNDARY_COUNT is greater than BOUNDARY_COUNT.
                this.pageCount - this.BOUNDARY_COUNT > this.BOUNDARY_COUNT
                ? // true scenario means there is enough space after the sibling pages for an additional page.
                  [this.pageCount - this.BOUNDARY_COUNT]
                : // there is not enough space after the sibling pages for either an ellipsis or an additional page.
                  [];

        return [
            // the range of starting pages
            ...this.startPages,

            // ellipsis or additional pages
            ...startEllipsisOrAdditionalPage,

            // range of sibling pages between siblingsStart and siblingsEnd
            ...this.getRange(this.siblingsStart, this.siblingsEnd),

            // ellipsis or additional pages
            ...endEllipsisOrAdditionalPage,

            // the range of ending pages
            ...this.endPages,
        ];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * handles a click event on a page number, emitting the updated page number
     *
     * @param pageNo - the clicked page number
     */
    handlePageClick(pageNo: number | string) {
        if (typeof pageNo === 'string') {
            return;
        }

        this.page = pageNo;
        this.pageChange.emit(this.page);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * generates an array of sequential numbers within the specified range
     *
     * @param start - the starting number of the range
     * @param end - the ending number of the range
     * @returns an array containing sequential numbers within the specified range.
     */
    private getRange(start: number, end: number): number[] {
        return Array.from({length: end - start + 1}, (_, i) => start + i);
    }
}
