<form [formGroup]="form" class="form-v2">
    <!-- profile image -->
    <app-image-upload class="mx-auto mb-7" [image]="profileImg" [imageCtrl]="form.controls.profilePicturePath">
    </app-image-upload>

    <!-- first name and last name -->
    <ion-grid class="info-grid-v2">
        <ion-row>
            <!-- First Name -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.first_name">
                    <mat-form-field>
                        <input matInput placeholder="John" formControlName="firstName" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>

            <!-- Last Name -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.last_name">
                    <mat-form-field>
                        <input matInput placeholder="Smith" formControlName="lastName" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
        </ion-row>
    </ion-grid>

    <!-- Address -->
    <location-search-v2
        [addressGroup]="form.controls.address"
        [retrieveContainingStreetAddresses]="true"
        [containAllFields]="true"
        required>
    </location-search-v2>

    <!-- email, phone number, title and linkedIn url -->
    <ion-grid class="info-grid-v2">
        <ion-row>
            <!-- Email -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="Email/Username">
                    <mat-form-field>
                        <input matInput placeholder="john-smith@outlook.com" formControlName="email" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>

            <!-- Notification Email -->
            <ion-col size="12" size-sm="6">
                <app-form-field label="label.notification_email">
                    <mat-form-field>
                        <input matInput placeholder="john-smith@outlook.com" formControlName="notificationEmail" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
        </ion-row>

        <ion-row class="mt-4">
            <!-- Phone Number -->
            <ion-col size="10" size-sm="5">
                <app-form-field label="label.phone_number">
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="(000)000-0000"
                            formControlName="phoneNumber"
                            mask="(000) 000-0000" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
            <ion-col size="4" size-sm="2">
                <app-form-field label="label.phone_number_extension">
                    <mat-form-field>
                        <input matInput formControlName="phoneNumberExtension" maxlength="5" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>

            <!-- title -->
            <ion-col size="10" size-sm="5">
                <app-form-field label="label.title">
                    <mat-form-field>
                        <input matInput placeholder="CEO" formControlName="title" />
                        <mat-error appFieldErrors></mat-error>
                    </mat-form-field>
                </app-form-field>
            </ion-col>
        </ion-row>
    </ion-grid>

    <!-- Linked URL -->
    <app-form-field label="label.linkedIn_URL">
        <mat-form-field>
            <input matInput placeholder="https://www.linkedin.com/in/john-smith-404" formControlName="linkedinUrl" />
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </app-form-field>
</form>
