import {Component, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-privacy-policies-modal',
    templateUrl: './privacy-policies.modal.html',
    styleUrls: ['../../forms-shared.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PrivacyPoliciesModalComponent {
    privacyPolices = [
        {
            header: 'Effective Date: 31-07-2024',
            text: `
      <p>TenantPay ("we," "our," or "us") is committed to safeguarding your privacy. Our platform is designed to provide a secure and convenient platform for rent collection, payment tracking and reporting services. This Privacy Policy outlines how we gather, utilise, and share information about individuals and businesses using our platform, as well as other visitors interacting with our services (collectively referred to as "Users").
</p>
      <p>This Policy Details:</p>
      <ul>
      <li>The types of information we collect from you directly or through third parties.</li>
      <li>The reasons for collecting and processing this information.</li>
        <li>The legal grounds for processing your data when you engage with our platform available at <a href="https://www.tenantpay.com">www.tenantpay.com</a> ("Website") and the services offered through it.</li>
      </ul>
</br>
      <p>By accessing or using our Services, you agree to the collection, use, and disclosure of your information in accordance with this Privacy Policy.</p>
      <p>We encourage you to carefully review this Policy to understand how TenantPay and any associated entities handle your personal information. By using our Website and Services, you consent to the practices described in this Policy, as well as any applicable Terms and Conditions.</p>
    `,
        },
        {
            header: 'Information We Collect',
            text: `
      <p>TenantPay gathers various types of information to deliver and improve our Services. This information is categorized as follows:</p>
      <h3>Personal Information</h3>
      <p>
      <ul>
      <li><strong>Individuals:</strong> Includes details such as name, address, email, phone number, date of birth, employment sector, and government-issued identification.</li>
      <li><strong>Non-Individuals</strong> For entities such as corporations, trusts, or cooperatives, we collect:
      <ol>
      <li>Legal Name</li>
      <li>Address</li>
      <li>Nature of Business</li>
      <li>Phone Number</li>
      <li>Shareholding Documents (if applicable): Documents that outline the ownership structure, including shareholding distribution.</li>
      <li>Proof of Existence: Documents such as Certificates of Incorporation, Annual Filings, or other official records confirming the entity’s status.</li>
      <li>Accounting Software Usage: Information on whether the entity uses accounting software and its type.</li>
      <li>Types of Properties Managed (if applicable).</li>
      </ol></li>
      <li><strong>Cookies and Tracking Technologies:</strong> Information collected through cookies, web beacons, and other tracking technologies.</li>
      </ul>
      </p>
      <h3>Co-operatives:</h3>
      <p>
      <ul>
      <li>
      <strong>Proof of Existence:</strong> Documents such as Partnership Agreements, Articles of Association, or other official records confirming the cooperative's existence and affiliation.
      </li>
      </ul>
      </p>
      <h3>Trusts</h3>
      <p>
      <ul><li><strong>Proof of Existence:</strong> Documents including Trust Deeds, Partnership Agreements, or Articles of Association.</li></ul>
      </p>
      <h3>Ownership and Control Information</h3>
      <p>
      <ul>
      <li><strong>Corporate Entities:</strong> Names and addresses of directors, significant shareholders (those owning 25% or more), and documents such as minute books, securities registers, or shareholder agreements.</li>
      <li><strong>Trusts:</strong>Information about settlers, trustees, and beneficiaries</li>
      <li><strong>Non-Individual Third Parties:</strong> Information on persons or entities controlling 25% or more of the entity.</li>
      </ul>
      </p>
      <h3>Financial Information</h3>
      <p>
      <ul><li>Bank account details, payment methods, and transaction history.</li></ul>
      </p>
      <h3>Property Information</h3>
      <p>
      <ul><li>Details regarding rental properties and tenant communications.</li></ul>
      </p>
      <h3>Usage Data</h3>
      <p>
      <ul>
       <li>Information on how you interact with the TenantPay platform, including IP addresses, device information, and log data such as sign-ups and KYC submissions.</li>
      </ul>     
      </p>
      <h3>Third-Party Information</h3>
      <p>
      <ul>
      <li>
      <strong>Third-Party Verification: </strong>Information used for identity verification, such as personal details and government-issued identification, submitted to verification services like Certn.
      </li>
      </ul>
      </p>
      <h3>Data Verification</h3>
      <p>
      <ul><li><strong>Government-Issued Identification: </strong>To verify identity, we use documents that are authentic, valid, and issued by recognized authorities.</li></ul>
      </p>
      <p>
      This information helps us provide and enhance our Services and ensures compliance with our regulatory obligations. For further details on how we collect and handle this information, please refer to the respective section of our Privacy Policy.
      </p>

    `,
        },
        {
            header: 'Data Collection Methods',
            text: `
    <h3>Information You Provide</h3>
    <p>
    <ul>
    <li><strong>Personal Information:</strong> We collect data you provide directly, such as your name, contact details, date of birth, and government-issued IDs when you sign up or use our Services.</li>
    <li><strong>Entity Details:</strong> For business entities like corporations, trusts, and cooperatives, we gather legal names, addresses, business details, and relevant documentation to verify their existence and ownership structure.</li>
    </ul>
    </p>
    <h3>Automatic Data Collection</h3>
    <p>
    <ul>
    <li><strong>Activity Data:</strong> Our system collects information about how you interact with our Services, including IP addresses, device information, and browsing behaviour. This helps us understand your usage patterns and improve our Services.</li>
    <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tools to enhance your experience on our platform. These technologies help us recognize you on subsequent visits, store your preferences, and track how you use our Services. You can control cookie settings or delete them through your browser.</li>
    </ul>
    </p>
    <h3>Third-Party Data Collection</h3>
    <p>
    <ul>
    <li><strong>Verification Services:</strong> We may share your information with third-party verification partners, like Certn, to confirm your identity as part of our security measures.</li>
    <li><strong>Data Analysis Partners:</strong> We collaborate with external services to collect and analyse data on user interactions. These partners may use their own tracking technologies to gather information on our behalf.</li>
    </ul>
    </p>
    <h3>Document and Manual Collection</h3>
    <ul>
    <li><strong>Verification Documents:</strong> We collect and verify various documents to confirm the legitimacy of entities, such as incorporation certificates, trust deeds, and shareholder agreements, either directly from you or through our verification processes.</li>
    </ul>
    `,
        },
        {
            header: 'Timing of Data Collection and Its Utilisation',
            text: `
    <h3>Collection Points</h3>
    <p>
    <ul>
    <li><strong>Account Setup:</strong> We collect personal data, including name, address, contact information, and government-issued ID, when you create an account with TenantPay. This initial information is critical for setting up and managing your account.</li>
    <li><strong>Know Your Customer (KYC) Process:</strong> During the KYC process, we gather additional information to verify the identity of users and ensure compliance with relevant regulations. This process includes collecting detailed business or individual information required for identity verification.</li>
    <li><strong>Ongoing Interaction:</strong> We continuously collect data related to rental properties, financial transactions, and property management activities as you use our services. This information is vital for providing, maintaining, and improving our offerings.</li>
    </ul>
    </p>
    <h3>Utilisation of Collected Data</h3>
    <p>
    <ul>
    <li><strong>Service Provision and Management:</strong> The data collected is used to deliver and manage our services effectively. This includes processing transactions, managing rental properties, and fulfilling contractual obligations.</li>
    <li><strong>Verification and Compliance:</strong> Information gathered through the KYC process helps us verify identities and ensure adherence to legal and regulatory requirements. We may also collaborate with third-party verification services for this purpose.</li>
    <li><strong>Operational Enhancement:</strong> We analyse collected data to enhance operational efficiency, generate reports, and improve our services. This helps in refining property management practices and optimising service delivery.</li>
    <li><strong>Communication and Support:</strong> We use your contact information to provide updates, respond to inquiries, and offer support. This ensures a seamless experience and timely resolution of any issues.</li>
    <li><strong>Interactions with Third Parties:</strong> We may share data with third-party service providers involved in payment processing and other necessary services. This sharing is done to facilitate transactions and enhance service functionality.</li>
    </ul>
    </p>
    <h3>Security and Third-Party Practices</h3>
    <ul>
    <li><strong>Data Protection:</strong> We employ robust security measures to protect your data from unauthorised access and misuse. Our practices include secure storage and encryption to safeguard your information.</li>
    <li><strong>Third-Party Privacy:</strong> While working with third parties, we encourage you to review their privacy policies to understand how they handle your information. Their practices may differ from ours.</li>
    </ul>
    `,
        },
        {
            header: 'Disclosure of Your Information',
            text: `
    <h3>Sharing for Verification and Integration</h3>
    <p>
    <ul>
    <li><strong>Identity Verification:</strong> We share your personal data with third-party verification services to complete the identity verification process. This ensures that we meet regulatory requirements and verify the authenticity of the information provided.</li>
    <li><strong>Property Management Integration:</strong> To streamline property management, we may integrate with third-party property management software. This integration allows for the synchronisation of information between our platform and the property management systems to enhance service delivery.</li>
    </ul>
    </p>
    <h3>Types of Third Parties We Share Information With</h3>
    <ul>
    <li><strong>Service Providers:</strong> We collaborate with various third-party service providers who assist us in delivering, managing, and improving our services. These providers may access your information to support specific functions related to our platform.</li>
    <li><strong>Affiliates:</strong> We may share information with our corporate affiliates to provide comprehensive services as described in this Privacy Policy. This includes coordination with other entities within our corporate group for operational efficiency.</li>
    <li><strong>Analytics and Research:</strong> We may use your data, including de-identified or aggregated data, for internal research and development purposes. This helps us enhance our services and create new features. We might also share non-personal data with third parties involved in research and development.</li>
    <li><strong>Third-Party Plugins and Integrations:</strong> Our platform may include third-party plugins, widgets, or integrations. These may collect information such as your IP address and usage details. Each third-party component is governed by its own privacy policy, not ours.</li>
    <li><strong>Legal and Regulatory Requirements:</strong> We may disclose your information to legal authorities if required by law or in response to legal requests. This includes situations where disclosure is necessary to enforce our rights, protect users, or comply with legal obligations.</li>
    <li><strong>Business Transactions:</strong> In the event of a merger, acquisition, or similar business transaction, your information may be transferred as part of the transaction. This includes sales of assets or other changes in business structure.</li>
    <li><strong>Marketing and Communication Preferences:</strong> If you receive marketing communications from us and prefer not to, you can opt out by contacting us directly or using the unsubscribe link provided in marketing emails. We do not sell your personal data to third parties for marketing purposes.</li>
    </ul>
    `,
        },
        {
            header: 'Data Storage and Protection',
            text: `
    <h3>Data Storage</h3>
    <p>All the data you provide to TenantPay is stored securely using the servers of our cloud service partners. We ensure that these partners adhere to our rigorous data security standards. If you have been issued or have created a password for accessing specific features on our platform, it is essential to keep this password private and not disclose it to others.</p>
    <p>Our cloud service providers operate primarily in Canada and the United States. Some of our providers may be based in other countries. While we implement robust measures to protect your data, be aware that legal standards and access rights may vary in different jurisdictions. This means that authorities in those regions may access your information in accordance with their local laws.</p>
    <h3>Security Measures</h3>
    <p>At TenantPay, we prioritise the protection of your personal data. We have established comprehensive administrative, technical, and physical safeguards designed to prevent unauthorised access, use, alteration, and disclosure of your information.</p>
    <p>Despite our best efforts to secure your data, please be aware that no method of data transmission over the internet is completely immune to risk. While we strive to protect your information using advanced security measures, we cannot guarantee the absolute security of data transmitted to our platform. Once your information is in our possession, we employ strict procedures and security features to mitigate unauthorised access and ensure its protection.</p>
    `,
        },
        {
            header: 'Your Privacy Rights and How to Exercise Them',
            text: `
    <h3>Overview of Your Rights</h3>
    <p>Under applicable data protection laws, you are entitled to certain rights regarding your personal data. These rights may include:</p>
    <p>
    <ul>
    <li><strong>Right to Information:</strong> You have the right to be informed about how your personal data is collected and used.</li>
    <li><strong>Right to Access:</strong> You can request access to your personal data to understand what we hold about you.</li>
    <li><strong>Right to Correction:</strong> You can request corrections or updates to any inaccuracies in your personal data.</li>
    <li><strong>Right to Erasure:</strong> You can request the deletion of your personal data, also known as the right to be forgotten.</li>
    <li><strong>Right to Restrict Processing:</strong> You may request that we limit how we process your personal data.</li>
    <li><strong>Right to Data Portability:</strong> You can obtain a copy of your personal data to reuse it or transfer it to another entity.</li>
    <li><strong>Right to Object:</strong> You can object to the processing of your personal data in certain circumstances.</li>
    <li><strong>Right to Avoid Automated Decisions:</strong> You have the right not to be subject to decisions based solely on automated processing, including profiling.</li>
    <li><strong>Right to Withdraw Consent:</strong> If you have given consent for the processing of your data, you can withdraw it at any time.</li>
    <li><strong>Right to Complain:</strong> You have the right to lodge a complaint if you believe your rights have been violated.</li>
    </ul>
    </p>
    <h3>Exercising Your Rights</h3>
    <p>You can manage your personal data by logging into your account and updating your profile as needed. For requests to access, correct, update, or delete your personal data, please contact us using the details provided below. To ensure the security of your data, we may ask for verification to confirm your identity before processing such requests.</p>
    <h3>Complaints and Third-Party Providers</h3>
    <p>If you have concerns or wish to make a complaint, please reach out to us using the contact details provided. We aim to address any issues promptly, typically within 10 business days.</p>
    <p>Please note, we are not responsible for the privacy practices of our third-party service providers. We encourage you to review their privacy policies. If your request involves data held by third-party providers, we may coordinate with them as necessary, in accordance with the law, to fulfil your request and keep you informed about its progress.</p>
    `,
        },
        {
            header: 'External Links and Integration with Third-Party Technologies',
            text: `
    <p>Our Website may include links to external sites that are not owned or controlled by TenantPay, such as those belonging to our partners or other third parties. These links are provided solely for your convenience and do not imply endorsement or affiliation. We do not share your personal information with these external sites without your explicit consent. Please review the privacy policies and terms of use of any third-party sites you visit, as they may have different practices regarding the collection, use, and disclosure of personal data.</p>
    <p>Additionally, you may interact with our content through third-party social media platforms and applications. When you do so, you may grant us access to certain information from your social media profiles (e.g., name, email address, profile picture). This information is used to enhance your experience and provide you with relevant content and services.</p>
    <h3>Third-Party Analytics and Monitoring</h3>
    <p>TenantPay utilises web analytics services such as Google Analytics and Sentry to monitor and analyse the usage of our Website. These tools use cookies and other tracking technologies to collect standard internet log information and track visitor behaviour. For example, Google Analytics collects data about your interactions with our Website, including IP addresses, which is then used to generate reports and improve our services.</p>
    <p>If you prefer not to have your data collected by Google Analytics, you can opt out by installing the Google Analytics opt-out browser extension available at <a href="https://tools.google.com/dlpage/gaoptout?hl=en">this link</a>. For more information on how Google Analytics and Sentry handle data, please refer to their respective privacy policies.</p>
    `,
        },
        {
            header: 'Data Retention',
            text: `
    <p>TenantPay retains user information for a minimum of five years following the termination of your account. This retention period is necessary to comply with legal obligations and to maintain internal records.</p>
    <p>During this time, your data may be kept for:</p>
    <p>
    <ul>
    <li><strong>Legal Compliance:</strong> To meet statutory and regulatory requirements.</li>
    <li><strong>Record-Keeping:</strong> For internal auditing and historical record purposes.</li>
    <li><strong>Fraud Prevention:</strong> To detect and prevent any potential misuse or abuse.</li>
    </ul>
    </p>
    <p>After the retention period, we will take appropriate measures to securely delete or anonymize your data, considering technological and legal constraints. If you request the removal of your personal data, please be aware that complete deletion may not always be feasible, but we will ensure that any remaining data is handled with the utmost care and security in accordance with this Policy.</p>
    `,
        },
        {
            header: 'Protection of Childrens Privacy',
            text: `
    <p>TenantPay is committed to safeguarding the privacy of minors and does not intentionally collect personal information from individuals under the age of 16. Our Services are not designed for or directed at children in this age group. If you are under 16, please do not use our Services. We encourage parents and guardians to actively supervise their children’s online interactions to enforce this age limit.</p>
    <p>If you believe that we may have unintentionally collected information from a child under 16, please contact us immediately. We will take prompt and appropriate action to remove any such information from our records.</p>
    `,
        },
        {
            header: 'Governing Law',
            text: `
    <p>TenantPay is committed to safeguarding your data in compliance with applicable privacy laws, including the Personal Information Protection and Electronic Documents Act (PIPEDA). We utilise a range of security measures, including cybersecurity services and firewalls, to protect your personal information. Our third-party service providers also adhere to robust security standards.</p>
<p>Your personal information may be accessed by authorised staff or services located in different jurisdictions where TenantPay or its partners operate. We ensure that these measures meet the necessary legal and regulatory requirements to protect your data.</p>
<p>Any disputes or questions related to the application, interpretation, or enforcement of this Privacy Policy will be governed by the laws of the Province of Ontario and the federal laws of Canada applicable therein.</p>
<p>For inquiries or to exercise your rights under applicable law, please contact us at <a href="mailto:support@tenantpay.com">support@tenantpay.com</a></p>
`,
        },
        {
            header: 'Policy Updates and Notifications',
            text: `
<p>Our Privacy Policy may be updated periodically. Any changes will be posted on our Website, and where appropriate, we will notify you via email. We encourage you to review our Privacy Policy regularly to stay informed of any updates. We will not materially diminish your rights under this Privacy Policy without notifying you.</p>
`,
        },
        {
            header: 'Contact Information',
            text: `
<p>If you have any questions or concerns regarding these Terms, please contact us at <a href="mailto:support@tenantpay.com">support@tenantpay.com</a></p>
`,
        },
    ];

    constructor(private modalController: ModalController, public readonly global: GlobalService) {}

    acceptPolicy() {
        // Handle the acceptance of the privacy policy
        this.modalController.dismiss({accepted: true});
    }

    closeModal() {
        this.modalController.dismiss({accepted: false});
    }
}
