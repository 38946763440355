import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RepushByDateModal} from './repush-by-date.modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {PipesModule} from 'src/app/pipes/pipe.module';
import {ComponentsModule} from 'src/app/components/components.module';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';

import {DirectivesModule} from 'src/app/utils/directives';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {TranslateModule} from '@ngx-translate/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
@NgModule({
    declarations: [RepushByDateModal],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        DirectivesModule,
        MatChipsModule,
        MatExpansionModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
    ],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'YYYY-MM-DD', // this is how your date will be parsed from Input
                },
                display: {
                    dateInput: 'YYYY-MM-DD', // this is how your date will get displayed on the Input
                    monthYearLabel: 'MMMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
})
export class RepushByDateModule {}
