<!-- modal title -->
<ion-header>
    <ion-toolbar>
        <ion-title>{{ title | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<!-- upload document prerequisite form -->
<form *ngIf="!showUpload" [formGroup]="docNameForm" class="ion-padding py-6">
    <ion-label>
        <h1>{{ documentFormTitle | translate }}</h1>
    </ion-label>

    <!-- document issuing country -->
    <app-form-field *ngIf="showCountryField" label="label.issuing_country_region" class="my-7" required>
        <ion-item class="form-v2__item form-v2__item--input">
            <ion-select class="w-full" formControlName="country">
                <ion-select-option value="Canada">Canada</ion-select-option>
            </ion-select>
        </ion-item>
    </app-form-field>

    <!-- document names -->
    <ion-radio-group class="form-v2__field-container mt-2" formControlName="documentName">
        <ion-item class="form-v2__item form-v2__item--radio-checkbox" *ngFor="let docName of docNames">
            <ion-radio [value]="docName"></ion-radio>
            <ion-label>{{ 'document_name.' + getSnakeCase(docName) | translate }}</ion-label>
        </ion-item>
    </ion-radio-group>
</form>

<!-- document upload section -->
<div *ngIf="showUpload" class="ion-padding py-6">
    <ion-label>
        <h1 class="!font-medium">{{ getTitle() + '_heading' | translate }}</h1>
        <p>{{ getTitle() + '_subheading' | translate }}</p>
    </ion-label>

    <!-- document uploader -->
    <div class="flex mt-5 mb-1 gap-2">
        <app-ai-document-uploader
            [documentName]="documentName === DocumentName.DRIVERS_LICENSE ? DocumentName.DRIVERS_LICENSE_FRONT : documentName"
            [icon]="icon"
            [documentType]="documentType"
            [issuedCountry]="issuedCountry"
            (onFormGroupSet)="addFormGroups($event,'first')">
        </app-ai-document-uploader>

        <!-- for now second uploader will only appear in driver's license scenario -->
        <app-ai-document-uploader
            *ngIf="documentName === DocumentName.DRIVERS_LICENSE"
            icon="credit-card"
            [documentName]="DocumentName.DRIVERS_LICENSE_BACK"
            [documentType]="documentType"
            [issuedCountry]="issuedCountry"
            (onFormGroupSet)="addFormGroups($event,'second')">
        </app-ai-document-uploader>
    </div>
</div>

<!-- action buttons -->
<ion-footer class="flex justify-center p-2">
    <ion-grid>
        <ion-row>
            <!-- back button -->
            <ion-col size="4">
                <ion-button class="btn__primary btn__primary-outlined w-full" (click)="onBackClick()"
                    >{{ 'label.back' | translate }}</ion-button
                >
            </ion-col>

            <!-- upload button -->
            <ion-col size="8">
                <ng-container *ngIf="showUpload; else continueToUpload">
                    <ion-button class="btn__primary w-full" (click)="uploadDocument()" [disabled]="formGroups.invalid"
                        >{{ 'label.upload' | translate }}</ion-button
                    >
                </ng-container>

                <ng-template #continueToUpload>
                    <ion-button class="btn__primary w-full" (click)="moveToUpload()" [disabled]="docNameForm.invalid"
                        >{{ 'label.continue' | translate }}</ion-button
                    >
                </ng-template>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>
