<div class="light-background overflow-auto" cdkScrollable>
    <!-- page heading -->
    <app-page-header pageHeading="label.profile"> </app-page-header>

    <!-- profile details card -->
    <ion-card class="mx-8 my-0 rounded card-shadow-v2 px-5 py-8">
        <app-profile-details-form #profileDetailsForm></app-profile-details-form>

        <!-- save action button -->
        <ion-footer class="mt-5">
            <ion-button
                class="btn__primary mt-5"
                style="float: right"
                (click)="profileDetailsForm.updateProfileDetails()"
                >{{'label.save' | translate}}</ion-button
            >
        </ion-footer>
    </ion-card>

    <!-- <app-page-header pageHeading="label.reset_password">
    </app-page-header>-->

    <!-- reset password card -->
    <ion-card class="mx-8 my-8 rounded card-shadow-v2 px-5 py-8">
        <ion-card-title class="mb-8">{{'label.reset_password' | translate}}</ion-card-title>

        <app-profile-reset-password #resetPasswordForm></app-profile-reset-password>

        <!-- save action button -->
        <ion-footer class="mt-5">
            <ion-button class="btn__primary mt-5" style="float: right" (click)="resetPasswordForm.resetPassword()"
                >{{'label.save' | translate}}</ion-button
            >
        </ion-footer>
    </ion-card>
</div>
