import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentsModule} from 'src/app/components/components.module';
import {PipesModule} from 'src/app/pipes/pipe.module';
import {LetterModal} from './letter.modal';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {DirectivesModule} from 'src/app/utils/directives';
import {NgxMaskModule} from 'ngx-mask';

@NgModule({
    declarations: [LetterModal],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ComponentsModule,
        PipesModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        DirectivesModule,
        NgxMaskModule,
    ],
})
export class LetterModalModule {}
