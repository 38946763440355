<app-form-field label="label.{{ label }}" [required]="required">
    <mat-form-field>
        <!-- 
            the input field which acts as: 
                - search bar
                - contain the final output
         -->
        <input
            [required]="required"
            matInput
            [readonly]="isReadOnly"
            location-search-input
            [(ngModel)]="completeAddress"
            [placeholder]="placeHolder"
            [formControl]="searchCtrl"
            [matAutocomplete]="auto"
            [ngStyle]="{ 'color': isReadOnly ? '#c7c7c7' : 'inherit' }"
           />

        <!-- 
            select drop down containing the search results output
         -->
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (closed)="onSelectClose()">
            <ng-container *ngIf="!noResultsFound; else resultNotFound">
                <mat-option *ngFor="let item of searchedItems" (onSelectionChange)="selectLocation(item)">
                    {{ item.description }}
                </mat-option>
            </ng-container>

            <ng-template #resultNotFound>
                <mat-option class="!text-red-500 !text-xs">{{ 'errors.address_not_found' | translate }}</mat-option>
            </ng-template>
        </mat-autocomplete>

        <mat-error appFieldErrors [customPropertyName]="label"></mat-error>
    </mat-form-field>
</app-form-field>

<!-- 
    location search map which is used internally for initializing
    the google maps place service
 -->
<div #locationSearchMap id="locationSearchMap"></div>

<!-- 
    additional field
      - rendered only if any one of them is missing from the google places API
 -->
<form *ngIf="showAdditionalFields && addressGroup" class="grid grid-cols-2 gap-x-4" [formGroup]="addressGroup">
    <!-- 
        street name input field
     -->
    <app-form-field label="label.street_name">
        <mat-form-field>
            <input matInput formControlName="streetName" required />
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </app-form-field>

    <!-- 
        city input field
     -->
    <app-form-field label="label.city">
        <mat-form-field>
            <input matInput formControlName="city" required />
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </app-form-field>

    <!-- 
        province input field
     -->
    <app-form-field label="label.province">
        <mat-form-field>
            <input matInput formControlName="province" required />
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </app-form-field>

    <!-- 
        country input field
     -->
    <app-form-field label="label.country">
        <mat-form-field>
            <input matInput formControlName="country" required />
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </app-form-field>

    <!-- 
        postal code input field
     -->
    <app-form-field class="col-span-full" label="label.postal_code">
        <mat-form-field>
            <input matInput formControlName="postalCode" required />
            <mat-error appFieldErrors></mat-error>
        </mat-form-field>
    </app-form-field>
</form>
