<ion-header class="bg-blue-600">
    <div class="bg-blue-600 flex justify-between">
        <ion-title class="ion-text-capitalize toolbar-title text-white bg-blue-600">
            <span>Privacy Policies</span>
        </ion-title>
        <button fill="none" (click)="global.closeModal()" class="bg-blue-600 text-white font-bold">&#10005;</button>
    </div>
</ion-header>

<div class="overflow-auto">
    <div class="m-0 mt-5 responsive-section rounded no-top-borders no-bottom-borders no-borders">
        <div class="mb-4 p-2" *ngFor="let item of privacyPolices">
            <h2 class="font-bold mb-2 text-blue-600">{{ item.header }}</h2>
            <div [innerHTML]="item.text"></div>
        </div>
    </div>
</div>

<ion-footer>
    <ion-toolbar>
        <ion-row class="flex justify-end">
            <ion-col size="3">
                <ion-button class="btn__primary" expand="full" (click)="closeModal()">Understood</ion-button>
            </ion-col>
        </ion-row>
    </ion-toolbar>
</ion-footer>
