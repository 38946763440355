import {tap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {
    CompanyInformation,
    CompanyMetaData,
    IndividualInformation,
    Kyc,
    KycDocuments,
    KycType,
} from './account-information.modal';
import {API_URL} from 'src/app/pathConfig';

@Injectable({providedIn: 'root'})
export class AccountInformationService {
    /** subject containing latest value of the Account Type */
    accountType$ = new BehaviorSubject<KycType>(undefined);

    /** subject containing latest kyc documents */
    documents$ = new BehaviorSubject<KycDocuments>(undefined);

    /** base URL which will be same in every API call */
    private readonly baseUrl = `${API_URL}/kyc`;

    /** constructor */
    constructor(private readonly http: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    ///////////////////////////////////////
    //// KYC Details

    /**
     * gets kyc details from BE
     */
    getKycType() {
        return this.http.get<Kyc>(this.baseUrl).pipe(
            tap((kyc) => {
                if (kyc.kycType) {
                    this.accountType$.next(kyc.kycType.name.toLowerCase() as KycType);
                }
            })
        );
    }

    /**
     * updates the kycType
     *
     * @param kycType the type of kyc account
     */
    updateKycType(kycType: KycType) {
        return this.http.put(this.baseUrl, {kycType: kycType}).subscribe(() => this.accountType$.next(kycType));
    }

    //// End of KYC Details
    ///////////////////////////////////////

    ///////////////////////////////////////
    //// Company Meta Data

    /**
     * get company meta data details from BE
     */
    getCompanyMetaData() {
        return this.http.get<CompanyMetaData>(`${this.baseUrl}/companyMetaData`);
    }

    /**
     * updates the company meta data details
     *
     * @param payload Company Meta Data
     */
    updateCompanyMetaData(payload: object) {
        return this.http.put(`${this.baseUrl}/companyMetaData`, payload).subscribe();
    }

    //// End of Company Meta Data
    ///////////////////////////////////////

    ///////////////////////////////////////
    //// Company Information

    /**
     * get company information details from BE
     */
    getCompanyInformation() {
        return this.http.get<CompanyInformation>(`${this.baseUrl}/companyInformation`);
    }

    /**
     * updates the company information details
     *
     * @param payload Company Information
     */
    updateCompanyInformation(payload: object) {
        return this.http.put(`${this.baseUrl}/companyInformation`, payload).subscribe();
    }

    //// End of Company Information
    ///////////////////////////////////////

    ///////////////////////////////////////
    //// Individual Information

    /**
     * get individual's information details from BE
     */
    getIndividualInformation() {
        return this.http.get<IndividualInformation>(`${this.baseUrl}/individualInformation`);
    }

    /**
     * updates the individual's information details
     *
     * @param payload Company Information
     */
    updateIndividualInformation(payload: object) {
        return this.http.put(`${this.baseUrl}/individualInformation`, payload).subscribe();
    }

    //// End of Individual Information
    ///////////////////////////////////////

    ///////////////////////////////////////
    //// Individual Information

    /**
     * get documents details from BE
     */
    getDocuments() {
        return this.http
            .get<KycDocuments>(`${this.baseUrl}/document`)
            .pipe(tap((documents) => this.documents$.next(documents)));
    }

    /**
     * updates the document
     *
     * @param payload document payload
     */
    updateDocument(payload: object) {
        return this.http.put(`${this.baseUrl}/document`, payload).pipe(
            tap(() => {
                this.getDocuments().subscribe();
            })
        );
    }

    //// End of document details
    ///////////////////////////////////////
}
