<div class="flex justify-center">
<div class="qr-code-container pt-0 m-auto" *ngIf="companyType === '1' && !idvCheckCompleted " >
    <h2 class="title">To complete the identification process</h2>
    <p class="subtitle"> You need to proceed with the identification check</p>
    <ion-button class="btn__primary mr-5" (click)="openTrusmatic()">
     Proceed 
    </ion-button>
</div>
<div class="qr-code-container" *ngIf="companyType !== '1' || (companyType === '1' && idvCheckCompleted)">
    <ion-icon   src="assets/icon/green-check.svg" size="large" style="zoom:2.0;" class="bg-green-600 text-white rounded-full"></ion-icon>
    <h2 class="text-black">We have received your application</h2>
    <p>We strive to review applications within 24-48 hours. Once approved, you will receive a notification email.</p>
</div>
</div>
