<!-- 
    a single navigation item
 -->
<ion-menu-toggle [ngClass]="paneClass" auto-hide="false">
    <ion-item
        (click)="routeToPage(page)"
        lines="none"
        detail="false"
        class="sidebar__item"
        [ngClass]="{selected: getActiveRouteClass(page), 'sidebar__item--sub': page.isSubItem}">
        <svg *ngIf="displayIcon" class="sidebar__item-icon" slot="start">
            <use attr.xlink:href="/assets/icon/sprite.svg#{{ page.icon }}"></use>
        </svg>
        <ion-label class="sidebar__item-label">{{ page['title'] }}</ion-label>
    </ion-item>
</ion-menu-toggle>
