import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { AccountTransactionsModal } from './account-transactions.modal';

@NgModule({
    declarations: [
        AccountTransactionsModal
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ComponentsModule,
        PipesModule,
        ReactiveFormsModule
    ]
})
export class AccountTransactionsModalModule { }
