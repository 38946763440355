import {NgxMaskModule} from 'ngx-mask';
import {AppInitService} from './services/app-init.service';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy, Router} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {GlobalService} from './services/global.service';
import {JwtHelperService, JWT_OPTIONS} from '@auth0/angular-jwt';
import {SaveBankAccountModalModule} from './pages/bank-accounts/save-bank-account/save-bank-account.module';
import {SaveBuildingModalModule} from './pages/buildings/save-building/save-building.module';
import {GeneralPopoverModule} from './components/general-popover/general-popover.module';
import {SaveResidentModalModule} from './pages/contacts/save-resident/save-resident.module';
import {DocumentDetailModalModule} from './pages/kyc/document-detail/document-detail.module';
import {TransactionFilterModalModule} from './pages/transactions/transaction-filter/transaction-filter.module';
import {SaveUnitModalModule} from './pages/units/save-unit/save-unit.module';
import {AccountTransactionsModalModule} from './pages/dashboard/account-transactions/account-transactions.module';
import {LetterModalModule} from './pages/contacts/letter/letter.module';
import {DocumentViewerModalModule} from './components/document-viewer/document-viewer.module';
import {InvoiceDetailsModalModule} from './pages/billing/invoice-details/invoice-details.module';
import {SendFeedbackModalModule} from './pages/dashboard/send-feedback/send-feedback.module';
import {StatHelpModal} from './pages/dashboard/stat-help/stat-help.modal';
import {HelpModalModule} from './pages/help/help.module';
import {ImportContactModalModule} from './pages/contacts/import-contact/import-contact.module';
import {AddWorkOrderUpdateModalModule} from './pages/work-orders/add-work-order-update/add-work-order-update.module';
import {NotificationModalModule} from './pages/dashboard/notification-modal/notification.module';
import {ResetPasswordPageModule} from './pages/reset-password/resetPassword.module';
import {EmailTransactionReceiptModalModule} from './pages/transactions/email-transaction-receipt/email-transaction-receipt.module';
import {PaymentsPageModule} from './pages/payments/payments.module';
import {SidebarModule} from './components/sidebar';
import {TokenInterceptor, LoaderInterceptor} from './utils/interceptors';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {TopbarModule} from './components/topbar';
import {StatHelpModalModule} from './pages/dashboard/stat-help/stat-help.module';
import {ShepherdService} from 'angular-shepherd';
import {CalculationDetailModalModule} from './pages/dashboard/calculation-detail/calculation-detail.module';
import {provideUserIdleConfig} from 'angular-user-idle';
import {CountdownModalModule} from './components/countdown-modal/countdown-modal.module';
import {LogStatusModalModule} from './pages/integrations/yardi/yardi-buildings/log-status/log-status.module';
import * as Sentry from '@sentry/angular-ivy';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {RepushByDateModule} from './pages/integrations/yardi/yardi-transactions/repush-by-date/repush-by-date.module';
import {StatusInformationModal} from './pages/integrations/yardi/yardi-buildings/status-information/status-information.modal';
import {StatusInformationModule} from './pages/integrations/yardi/yardi-buildings/status-information/status-information.module';
import {StatusDescModal} from './pages/integrations/yardi/yardi-transactions/status-desc/status-desc.modal';
import {StatusDescModalModule} from './pages/integrations/yardi/yardi-transactions/status-desc/status-desc.module';
import {MoveResidentModule} from './pages/contacts/move-resident/move-resident.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {KYCPortalPageModule} from './pages/kyc/kyc-portal/kyc-portal.module';
import {NotFoundModule} from './pages/not-found/not-found.module';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        IonicModule.forRoot({mode: 'md'}),
        AppRoutingModule,
        SaveBankAccountModalModule,
        FontAwesomeModule,
        SaveBuildingModalModule,
        SaveUnitModalModule,
        GeneralPopoverModule,
        SaveResidentModalModule,
        KYCPortalPageModule,
        NotFoundModule,
        TransactionFilterModalModule,
        DocumentDetailModalModule,
        AccountTransactionsModalModule,
        LetterModalModule,
        DocumentViewerModalModule,
        InvoiceDetailsModalModule,
        SendFeedbackModalModule,
        HelpModalModule,
        ImportContactModalModule,
        AddWorkOrderUpdateModalModule,
        NotificationModalModule,
        ResetPasswordPageModule,
        EmailTransactionReceiptModalModule,
        PaymentsPageModule,
        SidebarModule,
        TopbarModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule,
        MatNativeDateModule,
        StatHelpModalModule,
        CalculationDetailModalModule,
        CountdownModalModule,
        LogStatusModalModule,
        MatDatepickerModule,
        RepushByDateModule,
        StatusInformationModule,
        StatusDescModalModule,
        MoveResidentModule,
        MatTooltipModule,
    ],
    providers: [
        GlobalService,
        JwtHelperService,
        {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        ShepherdService,
        // 44 minutes and 28 seconds is equivalent to 2668 seconds,   the remaining 32 seconds are for timout [30 Timer + 2 for opening modal]
        provideUserIdleConfig({idle: 2668, timeout: 32, ping: 0}),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    // a little bit hack to initialize the appInitService to load the core data after user is logged in
    constructor(library: FaIconLibrary, public appInitService: AppInitService) {
        library.addIconPacks(fas, fab, far);
    }
}
