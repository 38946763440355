import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {StatusInformationModal} from './status-information.modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from 'src/app/pipes/pipe.module';
import {ComponentsModule} from 'src/app/components/components.module';

@NgModule({
    declarations: [StatusInformationModal],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
    ],
})
export class StatusInformationModule {}
