import {FormBuilder, Validators} from '@angular/forms';

/**
 * get the base address form group
 */
export const getAddressFormGroup = () => {
    const formBuilder = new FormBuilder();
    return formBuilder.group({
        fullAddress: ['', Validators.required],
        streetName: [''],
        city: [''],
        province: [''],
        country: [''],
        postalCode: [''],
    });
};
