<ion-header class=" bg-blue-600">
  <div class=" bg-blue-600 flex justify-between">
    <ion-title class="ion-text-capitalize toolbar-title text-white bg-blue-600">
      <span>Dignitary Info</span>
    </ion-title>
    <button fill="none" (click)="global.closeModal()" class="bg-blue-600 text-white font-bold ">
      &#10005;
    </button>
  </div>
</ion-header>
<div class="overflow-auto">
  <div class="m-0 mt-5 responsive-section rounded no-top-borders no-bottom-borders no-borders">
    <div class="mb-4 p-2">
      <h3 class="font-bold mb-2 text-blue-600">Directors:</h3>
      <p>Directors are individuals elected or appointed to oversee the management and affairs of the corporation, acting on behalf of its shareholders. They are responsible for making strategic decisions, ensuring compliance with laws and regulations, and representing the interests of the company.</p>
    </div>
    <div class="mb-4 p-2">
      <h3 class="font-bold mb-2 text-blue-600">Trustees:</h3>
      <p>Trustees are individuals appointed to manage and administer assets held in trust for the benefit of others, known as beneficiaries. They have a fiduciary duty to act in the best interests of the beneficiaries and to manage the trust assets prudently.</p>
    </div>
    <div class="mb-4 p-2">
      <h3 class="font-bold mb-2 text-blue-600">Signatories:</h3>
      <p>Signatories are individuals authorized to sign legal documents or execute transactions on behalf of an organization or entity. They may have specific powers granted to them by the organization, such as signing contracts, agreements, or financial instruments.</p>
    </div>
    <div class="mb-4 p-2">
      <h3 class="font-bold mb-2 text-blue-600">UBO(s):</h3>
      <p>The UBO refers to the natural person(s) who ultimately own or control a legal entity or arrangement, such as a company, trust, or partnership. The UBO is the individual(s) who ultimately benefit from or have significant influence over the entity, often holding a substantial ownership interest or exercising control through other means.</p>
    </div>
    <div class="mb-4 p-2">
      <h3 class="font-bold mb-2 text-blue-600">Beneficiaries:</h3>
      <p>Beneficiaries are individuals or entities for whose benefit the trust assets are held and managed by the trustee(s). Beneficiaries may be entitled to receive income, principal, or other benefits from the trust according to the terms set forth in the trust document.</p>
    </div>
    <div class="mb-4 p-2">
      <h3 class="font-bold mb-2 text-blue-600">Settlors:</h3>
      <p>The settlor is the person who creates a trust by transferring assets to the trust and establishing its terms and conditions. The settlor initiates the trust and may specify how the trust assets should be managed, who the beneficiaries are, and any conditions or instructions for distribution.</p>
    </div>
  </div>
</div>