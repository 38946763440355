<ion-list class="p-0" *ngIf="accountType$ | async as accountType">
    <!-- articles of incorporation document -->
    <app-ai-document-item
        *ngIf="accountType === KycType.COMPANY"
        [title]="DocumentType.ARTICLES_OF_INCORPORATION"
        [document]="documents[DocumentType.ARTICLES_OF_INCORPORATION]">
    </app-ai-document-item>

    <!-- government ID document -->
    <app-ai-document-item
        [title]="DocumentType.GOVERNMENT_ID"
        [document]="documents[DocumentType.GOVERNMENT_ID]"
        useSubTitle></app-ai-document-item>

    <!-- bank statement document -->
    <app-ai-document-item
        *ngIf="accountType === KycType.COMPANY"
        [title]="DocumentType.BANK_STATEMENT"
        [document]="documents[DocumentType.BANK_STATEMENT]">
    </app-ai-document-item>

    <!-- bill document -->
    <app-ai-document-item
        *ngIf="accountType === KycType.INDIVIDUAL"
        [title]="DocumentType.BILL"
        [document]="documents[DocumentType.BILL]">
    </app-ai-document-item>
</ion-list>
