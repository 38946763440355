<app-form-field [label]="label">
    <mat-form-field class="search-bar-field">
        <svg matPrefix class="w-6 h-6">
            <use xlink:href="/assets/icon/sprite.svg#icon-search"></use>
        </svg>
        <input
            matInput
            type="text"
            [placeholder]="placeholder | translate"
            [(ngModel)]="appModel"
            (ngModelChange)="appModelChange.emit(appModel)"
            (ngModelDebounceChange)="onChange.emit()" />
    </mat-form-field>
</app-form-field>
