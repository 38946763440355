import {Component} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

import {AuthService} from '../../auth.service';
import {GlobalService} from 'src/app/services/global.service';

@Component({
    selector: 'app-verify-otp',
    templateUrl: './verify-otp.component.html',
    styleUrls: ['../forms-shared.scss'],
})
export class VerifyOtpComponent {
    userData = this.authService.userData;
    changeEmailForm: FormGroup;
    verificationCode: string;
    changeEmail = false;

    hide = true;
    /** constructor */
    constructor(
        public readonly global: GlobalService,
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService
    ) {
        this.changeEmailForm = this.initForm();
        if (!this.userData) {
            this.userData = {email: ''};
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    sendOtp() {
        if (!this.verificationCode) {
            this.global.basicAlert('', 'Enter the OTP');
            return;
        }
        this.authService.verifyOtp(this.userData.email, this.verificationCode);
    }
    private initForm(): FormGroup {
        return this.formBuilder.group(
            {
                email: ['', [Validators.required, Validators.email]],
                newEmail: ['', [Validators.required, Validators.email]],
                password: ['', [Validators.required]],
            },
            {validator: this.newEmailNotSameAsOld()}
        );
    }
    private newEmailNotSameAsOld(): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors | null => {
            const email = formGroup.get('email').value;
            const newEmail = formGroup.get('newEmail').value;

            if (email === newEmail) {
                formGroup.get('newEmail').setErrors({sameEmail: true});
                return {sameEmail: true};
            } else {
                return null;
            }
        };
    }

    changeEmailSection() {
        this.changeEmail = true;
    }
    isEmailSame(): boolean {
        const email = this.changeEmailForm.get('email')?.value;
        const newEmail = this.changeEmailForm.get('newEmail')?.value;
        return email && newEmail && email.trim() === newEmail.trim();
    }

    changeEmailRequest() {
        this.global
            .sendAjaxRequest('user/updateEmail', {
                email: this.changeEmailForm.get('email')?.value,
                password: this.changeEmailForm.get('password')?.value,
                newEmail: this.changeEmailForm.get('newEmail')?.value,
            })
            .subscribe((response) => {
                if (response.message === 'updated') {
                    this.global.basicAlert('', 'Email Updated Successfully.');
                    this.userData['email'] = response?.data?.email;
                    this.changeEmail = false;
                } else if (response.message === 'email_exists') {
                    this.global.basicAlert('', 'Email already exists');
                } else if (response.message === 'invalid_credentials') {
                    this.global.basicAlert('', 'Invalid Credentials');
                }
            });
    }
}
