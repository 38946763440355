<!-- sign up form -->
<form [formGroup]="form" class="form mobile-responsive">
    <!-- manage login heading -->
    <div class="form__heading-container">
        <ion-text class="form__heading">
            {{ 'label.sign_up' | translate }}
        </ion-text>
    </div>

    <ion-row class="w-[100%]">
        <ion-col size="6" size-sm="12" size-xs="12" size-lg="6" size-xs="12" size-md="6" class="pr-7">
            <app-form-field label="{{ 'label.first_name' | translate }}" required>
                <mat-form-field>
                    <input matInput formControlName="firstName" required />
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
        <ion-col size="6" size-sm="12" size-xs="12" size-lg="6" size-xs="12" size-md="6" class="pl-7">
            <app-form-field label="{{ 'label.last_name' | translate }}" required>
                <mat-form-field>
                    <input matInput formControlName="lastName" required />
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
    </ion-row>

    <ion-row class="w-[100%]">
        <ion-col size="6" size-sm="12" size-xs="12" size-lg="6" size-xs="12" size-md="6" class="pr-7">
            <app-form-field label="{{ 'label.phone_number' | translate }}" required>
                <mat-form-field>
                    <input matInput formControlName="phoneNumber" required mask="(000) 000-0000" />
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
        <ion-col size="6" size-sm="12" size-xs="12" size-lg="6" size-xs="12" size-md="6" class="pl-7">
            <app-form-field label="{{ 'label.email' | translate }}" required>
                <mat-form-field>
                    <input matInput formControlName="email" type="email" required />
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
    </ion-row>

    <ion-row class="w-[100%]">
        <ion-col size="6" size-sm="12" size-xs="12" size-lg="6" size-xs="12" size-md="6" class="pr-7">
            <app-form-field label="{{ 'label.password' | translate }}" required>
                <mat-form-field appearance="outline">
                    <input
                        matInput
                        formControlName="password"
                        [type]="hide ? 'password' : 'text'"
                        (input)="checkPasswordValidation()"
                        (focus)="showValidationMessages = true"
                        required />
                    <ion-icon
                        matSuffix
                        (click)="hide = !hide"
                        name="{{ hide ? 'eye-off' : 'eye' }}"
                        style="font-size: 16px; cursor: pointer"></ion-icon>
                    <mat-error *ngIf="form.get('password').hasError('required')">Password is required</mat-error>
                </mat-form-field>
                <div *ngIf="showValidationMessages" class="validation-list">
                    <div
                        [ngClass]="{
                            valid: global.isPasswordValid.minLength,
                            invalid: !global.isPasswordValid.minLength
                        }">
                        <span>{{ global.isPasswordValid.minLength ? '✔️' : '❌' }}</span> At least 8 characters long
                    </div>
                    <div
                        [ngClass]="{
                            valid: global.isPasswordValid.lowerCase,
                            invalid: !global.isPasswordValid.lowerCase
                        }">
                        <span>{{ global.isPasswordValid.lowerCase ? '✔️' : '❌' }}</span> At least one lowercase letter
                    </div>
                    <div
                        [ngClass]="{
                            valid: global.isPasswordValid.upperCase,
                            invalid: !global.isPasswordValid.upperCase
                        }">
                        <span>{{ global.isPasswordValid.upperCase ? '✔️' : '❌' }}</span> At least one uppercase letter
                    </div>
                    <div [ngClass]="{valid: global.isPasswordValid.number, invalid: !global.isPasswordValid.number}">
                        <span>{{ global.isPasswordValid.number ? '✔️' : '❌' }}</span> Contains at least one number
                    </div>
                    <div [ngClass]="{valid: global.isPasswordValid.symbol, invalid: !global.isPasswordValid.symbol}">
                        <span>{{ global.isPasswordValid.symbol ? '✔️' : '❌' }}</span> Contains at least one symbol
                        (!@#$%^&*)
                    </div>
                </div>
            </app-form-field>
        </ion-col>
        <ion-col size="6" size-sm="12" size-xs="12" size-lg="6" size-xs="12" size-md="6" class="pl-7">
            <app-form-field label="{{ 'label.confirm_password' | translate }}" required>
                <mat-form-field>
                    <input
                        matInput
                        formControlName="confirmPassword"
                        type="password"
                        required
                        [type]="confirmPasswordHide ? 'password' : 'text'" />
                    <ion-icon
                        matSuffix
                        (click)="confirmPasswordHide = !confirmPasswordHide"
                        name="{{ confirmPasswordHide ? 'eye-off' : 'eye' }}"
                        style="font-size: 16px; cursor: pointer"></ion-icon>
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
    </ion-row>

    <hr />

    <ion-row class="w-[100%]">
        <ion-col size="6" size-sm="12" size-xs="12" size-lg="6" size-xs="12" size-md="6" class="pr-7">
            <app-form-field label="{{ 'label.number_of_buildings' | translate }}">
                <mat-form-field>
                    <input matInput formControlName="numberOfBuildings" type="number" [min]="1" [max]="10000"/>
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
        <ion-col size="6" size-sm="12" size-xs="12" size-lg="6" size-xs="12" size-md="6" class="pl-7">
            <app-form-field label="{{ 'label.number_of_units' | translate }}">
                <mat-form-field>
                    <mat-select formControlName="numberOfUnits">
                        <mat-option *ngFor="let unit of noOfUnits" [value]="unit">{{ unit }}</mat-option>
                    </mat-select>
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </ion-col>
    </ion-row>

    <ion-row class="w-[100%]">
        <ion-col size="12" class=" ">
            <mat-checkbox [checked]="checkTermsAndConditionsAreAccepted" (change)="valueChange($event)" [color]="'primary'">
                I agree to the
                <span style="color: blue; cursor: pointer;" (click)="triggerTermsAndCondition($event)">Terms of Service</span>
                and <span style="color: blue; cursor: pointer;" (click)="triggerPrivacyPolicy($event)">Privacy Policy</span>
            </mat-checkbox>
            <ion-col size="12" *ngIf="triggerTermsAndConditionError">
                <mat-error>Please accept the terms and conditions.</mat-error>
            </ion-col>
        </ion-col>
    </ion-row>

    <!-- sign up button -->
    <ion-button class="w-full btn__primary form__btn" tappable id="loginBtn" expand="block" (click)="signUp()">{{
        'label.sign_up' | translate
    }}</ion-button>
</form>
