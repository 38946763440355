import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';

import {GlobalService} from './global.service';
import {GettingStartedService} from '../pages/getting-started';
import {KycStatus} from '../pages/getting-started/getting-started.model';

/**
 * Route guard for checking KYC (Know Your Customer) completion status.
 * If KYC is completed and approved, the user is allowed to route to the path;
 * otherwise, they are redirected to the `getting-started`k page.
 */
@Injectable({
    providedIn: 'root',
})
export class KycProgressGuard implements CanActivate {
    /**
     * A subject used to signal that KYC status have been fetched from the backend.
     * This subject emits an event when KYC status is found and is used with 'takeUntil'
     * to stop observing the KYC status observable.
     */
    kycStatusFound = new Subject<void>();

    /** constructor */
    constructor(public global: GlobalService, private readonly gettingStartedService: GettingStartedService) {}

    /**
     * Determines if a user can activate a route based on KYC (Know Your Customer) completion status.
     * If KYC is completed and approved, the user is allowed to proceed to the route;
     * otherwise, they are redirected to the 'getting-started' page.
     *
     * @returns A Promise that resolves with 'true' for access granted or 'false' for access denied.
     */
    public async canActivate(): Promise<boolean> {
        this.gettingStartedService.showFixedLoader$.next(true);
        const promise = new Promise<boolean>((resolve) => {
            this.gettingStartedService.kycStatus$.pipe(takeUntil(this.kycStatusFound)).subscribe((kyc) => {
                if (!kyc) {
                    this.gettingStartedService.showFixedLoader$.next(false);
                    resolve(true);
                    return;
                }

                // Notify that KYC status have been found
                this.kycStatusFound.next();
                // check temporary changed to NOT(!)
                if (kyc.status !== KycStatus.APPROVED) {
                    this.gettingStartedService.showFixedLoader$.next(false);
                    resolve(true);
                    return;
                }

                resolve(false);
                this.global.pushPage('getting-started');
            });
        });

        return promise;
    }
}
