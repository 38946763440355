import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidationErrors} from '@angular/forms';

@Directive({
    selector: '[appNoWhitespace]',
    providers: [{provide: NG_VALIDATORS, useExisting: NoWhitespaceValidatorDirective, multi: true}],
})
export class NoWhitespaceValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        const value = control.value;

        if (typeof value === 'string' && value.trim().length === 0 && value.length > 0) {
            return {whitespace: true};
        }

        return null;
    }
}
