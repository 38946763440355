import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * SearchBarComponent is a reusable Angular component for creating search bars.
 *
 * This component is used to create a search input field with an optional placeholder text.
 * It can be utilized within forms to capture user input for searching.
 *
 * usage:
 * ```html
 * <app-search-bar
 *    label="Search by name"
 *    placeholder="Search by field"
 *    [(appModel)]="searchModel"
 *    (onChange)="search()" >
 * </app-search-bar>
 * ```
 */
@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styles: [
        `
            :host {
                min-width: 17.5rem;

                @media (max-width: 600px) {
                    min-width: unset;
                }
            }
        `,
    ],
})
export class SearchBarComponent {
    /**
     * The label text to display over the search input field.
     *
     * @default 'Search by field'
     */
    @Input()
    set label(val: string) {
        this._label = val;
        this.placeholder = val;
    }

    /**
     * The label text to display over the search input field.
     *
     * @default 'Search by field'
     */
    get label() {
        return this._label;
    }

    /**
     * The placeholder text to display in the search input field.
     *
     * @default 'Search by field'
     */
    @Input()
    placeholder = this.label;

    /**
     * The search model to bind to the input field's value.
     *
     * @remarks
     * This property is two-way data-bound using the [(appModel)] syntax.
     */
    @Input()
    appModel: any;

    /**
     * An event emitter that emits when the `appModel` property changes.
     */
    @Output()
    appModelChange = new EventEmitter();

    /**
     * An event emitter that emits when the input field's value changes.
     */
    @Output()
    onChange = new EventEmitter();

    /**
     * Label of the form field to be used with setter/getter function.
     */
    private _label = 'Search by field';
}
