/** KYC progress stats */
export interface KycProgress {
    kycProgress: number;
    managerProgress: number;
    profileProgress: number;
}

/** KYC status api response */
export interface KycStatusResp {
    comment: string | null;
    status: KycStatus;
}

/** KYC status */
export enum KycStatus {
    APPROVED = 'approved',
    PENDING = 'pending',
    INCOMPLETE = 'incomplete',
}
