<ion-header>
    <ion-toolbar>
        <ion-title class="ion-text-capitalize"> {{'label.create_ticket' | translate}} </ion-title>
        <ion-button slot="end" fill="none" (click)="global.closeModal(false)">
            <ion-icon name="close"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>
<div class="overflow-auto">
    <form #submitFeedbackForm="ngForm" novalidate>
        <div class="m-0 mt-5 responsive-section rounded no-top-borders no-bottom-borders">
            <app-form-field label="label.email">
                <mat-form-field>
                    <input
                        matInput
                        name="email"
                        [(ngModel)]="feedbackData['email']"
                        #email="ngModel"
                        ngControl="feedbackData['email']"
                        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                        required />
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
            <app-form-field label="label.title">
                <mat-form-field>
                    <input
                        matInput
                        name="title"
                        [(ngModel)]="feedbackData['title']"
                        #title="ngModel"
                        ngControl="feedbackData['title']"
                        required />
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
            <app-form-field label="label.description">
                <mat-form-field>
                    <textarea
                        matInput
                        style="min-height: 50px"
                        auto-grow="true"
                        name="description"
                        [(ngModel)]="feedbackData['description']"
                        #description="ngModel"
                        ngControl="feedbackData['description']"
                        required></textarea>
                    <mat-error appFieldErrors></mat-error>
                </mat-form-field>
            </app-form-field>
        </div>
    </form>

    <ion-list *ngIf="feedbacks.length > 0">
        <h2 class="title-header" style="margin-left: 15px !important">{{'label.submitted_tickets' | translate}}</h2>
        <div *ngFor="let entry of feedbacks" class="ml-2 ion-padding">
            <ion-label>
                <h2>{{entry['title']}}</h2>
                <p class="text-area-div">{{entry['description']}}</p>
                <p>{{toLocal(entry['timestampAdded'] )}}</p>

                <p class="text-area-div" *ngIf="entry['resolvedNote']">{{entry['resolvedNote']}}</p>
            </ion-label>
            <ion-badge color="success" *ngIf="entry['resolvedNote']">{{'label.resolved' | translate}}</ion-badge>
        </div>
    </ion-list>
</div>
<ion-footer>
    <ion-button class="btn__primary btn__primary-outlined" (click)="global.closeModal(false)"
        >{{'label.cancel' | translate}}</ion-button
    >

    <ion-button class="btn__primary" (click)="submitFeedback(submitFeedbackForm)"
        >{{'label.submit' | translate}}</ion-button
    >
</ion-footer>
