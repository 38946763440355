import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from '../../components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { PaymentsPage } from './payments.page';

const routes: Routes = [
	{
		path: '',
		component: PaymentsPage,
	}
];


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		PipesModule,
		ComponentsModule,
		TranslateModule,
		RouterModule.forChild(routes)
	],
	declarations: [PaymentsPage]
})
export class PaymentsPageModule {}
