import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from '../../components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipe.module';
import { ResetPasswordPage } from './resetPassword.page';

const routes: Routes = [
    {
        path: '',
        component: ResetPasswordPage,
    },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        ReactiveFormsModule,
        PipesModule,
        ComponentsModule,
        RouterModule.forChild(routes),
    ],
    declarations: [ResetPasswordPage],
})
export class ResetPasswordPageModule {}
